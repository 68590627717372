import styled from 'styled-components'

export const ProjectOrModuleAttributeListWrapper = styled.div`
  border-right: 1px solid rgba(229, 231, 241, 1);
  width: 400px;
  padding: 0 8px;
`

type ListItemProps = {
  nestedPadding?: number
}

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 61, 1);
  padding: 4px 0;
  ${({ nestedPadding }: ListItemProps) =>
    nestedPadding ? `padding-left: ${nestedPadding}px;` : ''}
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
`

export const Value = styled.div`
  border-radius: 4px;
  background: rgba(235, 235, 239, 1);
  padding: 3.5px 4px;
  font-size: 12px;
  font-weight: 400;
  margin: 2px 0;
`
