import styled, { css } from 'styled-components'

export const PopupContent = styled.div`
  width: 100%;
  padding: 0 24px;
`

export const SelectProjectWrapper = styled.div`
  margin-bottom: 14px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;
`

export const Label = styled.div`
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 3, 53, 1);
  margin-bottom: 8px;
`

export const ProjectName = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(53, 59, 96, 1);
`
export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18.5px 24px;
`
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  width: 100%;
`

type ButtonProps = {
  disabled?: boolean
}

export const Button = styled.div`
  padding: 8px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(53, 59, 96, 1);
  background: rgba(53, 59, 96, 0.05);

  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.07);
  }
  &:active {
    background: rgba(53, 59, 96, 0.09);
  }
  ${({ disabled }: ButtonProps) =>
    disabled
      ? css`
          pointer-events: none;
          background: rgba(53, 59, 96, 0.03);
          color: rgba(53, 59, 96, 0.09);
        `
      : ''}
`
export const StyledButton = styled(Button)`
  color: #fff;
  background: rgba(76, 94, 207, 1);
  &:hover {
    background: rgba(63, 82, 198, 1);
  }
  &:active {
    background: rgba(50, 70, 190, 1);
  }
  ${({ disabled }: ButtonProps) =>
    disabled
      ? css`
          pointer-events: none;
          background: rgba(53, 59, 96, 0.03);
          color: rgba(53, 59, 96, 0.09);
        `
      : ''}
`
