import { useState, Dispatch, SetStateAction, useEffect, useMemo } from 'react'

import { Checkbox } from '@gmini/ui-kit'

import { ProjectModuleAttributeValue } from '../../store/attributes/types'

import { ArrowExpandMore } from '../icons/ArrowExpandMore'

import { projectModuleSplitter } from '../../store/attributes/attributes-slice'

import { AttributeModuleItem } from './AttributeModuleItem'

import { StyledProjectIcon } from './AttributeProjectItem.styled'
import {
  ExpandButton,
  GroupName,
  Item,
  ItemSide,
} from './AttributeValueGroupItem.styled'

type AttributeProjectItemProps = {
  dataTestIdPrefix: string
  item: ProjectModuleAttributeValue
  checkedMap: { [x: string]: boolean }
  setCheckedMap: Dispatch<SetStateAction<{ [x: string]: boolean }>>
  isBaseAttributeValuesChecked: boolean
}

export const AttributeProjectItem = ({
  item,
  dataTestIdPrefix,
  checkedMap,
  setCheckedMap,
  isBaseAttributeValuesChecked,
}: AttributeProjectItemProps) => {
  const [expanded, setExpanded] = useState(false)
  const { name, modules, urn } = item

  const urnKey = `${urn}${projectModuleSplitter}`
  const keysRelatableToProject = useMemo(
    () => Object.keys(checkedMap).filter(key => key.includes(urnKey)),
    [checkedMap, urnKey],
  )
  const isChecked = keysRelatableToProject.every(key => checkedMap[key])
  const isCheckedIncomplete = keysRelatableToProject.some(
    key => checkedMap[key],
  )

  useEffect(() => {
    const keysWithoutProjectKey = keysRelatableToProject.filter(
      key => key !== urnKey,
    )
    if (
      checkedMap[urnKey] &&
      keysWithoutProjectKey.every(key => !checkedMap[key])
    ) {
      setCheckedMap(prev => ({ ...prev, [urnKey]: false }))
    }
    if (
      !checkedMap[urnKey] &&
      keysWithoutProjectKey.every(key => checkedMap[key])
    ) {
      setCheckedMap(prev => ({ ...prev, [urnKey]: true }))
    }
  }, [keysRelatableToProject, urnKey, setCheckedMap, checkedMap])

  return (
    <>
      <Item>
        <ItemSide>
          <ExpandButton
            data-test-id={`${dataTestIdPrefix}_ExpandBtn`}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <ArrowExpandMore color='rgb(128, 129, 154)' />
          </ExpandButton>
          <Checkbox
            data-test-id={`${dataTestIdPrefix}_Checkbox`}
            indeterminate={isCheckedIncomplete && !isChecked}
            checked={isChecked}
            onChange={() =>
              setCheckedMap(prev => ({
                ...prev,
                ...keysRelatableToProject.reduce(
                  (acc: { [x: string]: boolean }, key) => ({
                    ...acc,
                    [key]: isChecked || isCheckedIncomplete ? false : true,
                  }),
                  {},
                ),
              }))
            }
          />
          <StyledProjectIcon color='rgb(128, 129, 154)' />
          <GroupName>{name}</GroupName>
        </ItemSide>
      </Item>
      {expanded &&
        modules.map((m, idx) => (
          <AttributeModuleItem
            projectUrn={urn}
            dataTestIdPrefix={`${dataTestIdPrefix}_Module_${idx}`}
            key={m.id}
            item={m}
            checkedMap={checkedMap}
            setCheckedMap={setCheckedMap}
            nestingLevel={1}
            isBaseAttributeValuesChecked={isBaseAttributeValuesChecked}
          />
        ))}
    </>
  )
}
