import { ModuleWithResourceMap } from './types'

export const formatPermissionsMapForUpdateRequest = (map: {
  [x: string]: ModuleWithResourceMap
}): {
  [x: string]: {
    [x: string]: string[]
  }
} => ({
  ...Object.entries(map || {}).reduce(
    (acc, [key, m]) => ({
      ...acc,
      [key]: {
        ...Object.entries(m.resources).reduce(
          (acc, [rkey, r]) => ({
            ...acc,
            [rkey]: r?.scopes.map(s => s.scopeId),
          }),
          {},
        ),
      },
    }),
    {},
  ),
})
