import { useAppSelector } from '../../store/store'

type CompanyCellProps = {
  id: number | null
}

export const CompanyCell = ({ id }: CompanyCellProps) => {
  const { setById } = useAppSelector(state => state.companies)
  return <div>{id ? setById[id]?.companyName : ''}</div>
}
