import React, {
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { IconButton, PlusCircle } from '@gmini/ui-kit'

import { CtxMenuBaseProps } from '@gmini/common'

import { ArrowExpandMore } from '../icons/ArrowExpandMore'

import {
  AttributeGroupWithChildren,
  AttributeInstances,
  AttributeValue,
} from '../../store/attributes/types'

import { useAppDispatch, useAppSelector } from '../../store/store'

import {
  addAttributeValue,
  updateAttributeGroup,
} from '../../store/attributes/actions'

import { FolderBigIcon } from '../icons/FolderBigIcon'

import { projectModuleValueSplitter } from '../../store/attributes/attributes-slice'

import {
  ExpandButton,
  GroupName,
  Item,
  ItemSide,
  StyledAttributeInput,
  StyledButton,
  StyledVerticalThreeDots,
} from './AttributeValueGroupItem.styled'
import { AttributeValueItem } from './AttributeValueItem'
import { checkNodeAccordance } from './utils'

type AttributeValueGroupItemProps = {
  group: AttributeGroupWithChildren
  dataTestIdPrefix: string
  onActionButtonClick?: (
    e: MouseEvent,
    item: AttributeValue | AttributeGroupWithChildren,
  ) => void
  ctxMenu?: CtxMenuBaseProps<{
    item: AttributeValue | AttributeGroupWithChildren
    event: MouseEvent
  }>
  valueToEdit?: AttributeValue | AttributeGroupWithChildren | null
  stopEditing?: () => void
  renderCheckbox: ReactNode
  renderChildCheckbox: (id: number, innerIdx: number) => ReactNode
  nestingLevel?: number
  noAddValue?: boolean
  onCreateValueInGroup?: (payload: {
    action: () => Promise<unknown>
    groupId: number
    actionType: 'create' | 'move'
  }) => void
}

export const AttributeValueGroupItem = ({
  group,
  onActionButtonClick,
  ctxMenu,
  dataTestIdPrefix,
  valueToEdit = null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stopEditing = () => {},
  renderCheckbox,
  renderChildCheckbox,
  nestingLevel = 0,
  noAddValue = false,
  onCreateValueInGroup,
}: AttributeValueGroupItemProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const groupInputRef = useRef<HTMLInputElement | null>(null)
  const dispatch = useAppDispatch()

  const { childrenFlatMap, projectModuleValuesMap } = useAppSelector(
    state => state.currentAttribute,
  )
  const { name, id, attributeId, children } = group
  const [expanded, setExpanded] = useState(false)
  const [isAttributeValueInputOpen, setIsAttributeValueInputOpen] =
    useState(false)

  useEffect(() => {
    if (isAttributeValueInputOpen) {
      inputRef.current?.focus()
    }
  }, [isAttributeValueInputOpen])

  useEffect(() => {
    if (checkNodeAccordance(AttributeInstances.GROUP, id, valueToEdit)) {
      groupInputRef.current?.focus()
    }
  }, [valueToEdit, id])

  const handleAddAttributeValue = useCallback(
    (name: string) => {
      const projectModulesWithGroupLength = Object.keys(
        projectModuleValuesMap,
      ).filter(
        key =>
          key.includes(projectModuleValueSplitter) &&
          childrenFlatMap[Number(key.split(projectModuleValueSplitter)[1])]
            .parentGroupId === id,
      ).length

      projectModulesWithGroupLength
        ? onCreateValueInGroup?.({
            action: () =>
              dispatch(
                addAttributeValue({
                  attributeId,
                  name,
                  parentGroupId: id,
                  noNotification: true,
                }),
              ),
            groupId: id,
            actionType: 'create',
          })
        : dispatch(
            addAttributeValue({
              attributeId,
              name,
              parentGroupId: id,
            }),
          )
    },
    [
      dispatch,
      attributeId,
      id,
      onCreateValueInGroup,
      childrenFlatMap,
      projectModuleValuesMap,
    ],
  )

  const handleEditAttributeGroup = useCallback(
    (name: string) => {
      dispatch(updateAttributeGroup({ attributeId, name, groupId: id }))
    },
    [dispatch, attributeId, id],
  )

  return (
    <>
      {checkNodeAccordance(AttributeInstances.GROUP, id, valueToEdit) ? (
        <StyledAttributeInput
          dataTestIdPrefix={`${dataTestIdPrefix}`}
          inputRef={groupInputRef}
          onClose={stopEditing}
          onSubmit={handleEditAttributeGroup}
          initValue={name}
        />
      ) : (
        <Item
          nestedPadding={nestingLevel * 26.5}
          data-test-id={`${dataTestIdPrefix}`}
        >
          <ItemSide>
            <ExpandButton
              data-test-id={`${dataTestIdPrefix}_ExpandBtn`}
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
            >
              <ArrowExpandMore color='rgb(128, 129, 154)' />
            </ExpandButton>
            {renderCheckbox}
            <FolderBigIcon color='rgb(128, 129, 154)' />
            <GroupName>{name}</GroupName>
          </ItemSide>
          <ItemSide>
            {onActionButtonClick && ctxMenu && (
              <IconButton
                data-test-id={`${dataTestIdPrefix}_ActionsBtn`}
                onClick={e => onActionButtonClick(e, group)}
                type='circle'
                size='xs'
                active={checkNodeAccordance(
                  AttributeInstances.GROUP,
                  id,
                  ctxMenu.item?.item,
                )}
              >
                <StyledVerticalThreeDots
                  active={checkNodeAccordance(
                    AttributeInstances.GROUP,
                    id,
                    ctxMenu.item?.item,
                  )}
                />
              </IconButton>
            )}
          </ItemSide>
        </Item>
      )}
      {expanded ? (
        <>
          {children
            ? children.map((node, i) => (
                <AttributeValueItem
                  dataTestIdPrefix={`${dataTestIdPrefix}_${i}`}
                  active={
                    ctxMenu &&
                    checkNodeAccordance(
                      AttributeInstances.VALUE,
                      node.id,
                      ctxMenu.item?.item,
                    )
                  }
                  onActionButtonClick={onActionButtonClick}
                  key={node.id}
                  value={node}
                  groupId={id}
                  isEditing={checkNodeAccordance(
                    AttributeInstances.VALUE,
                    node.id,
                    valueToEdit,
                  )}
                  stopEditing={stopEditing}
                  renderCheckbox={renderChildCheckbox(node.id, i)}
                  nestingLevel={nestingLevel + 1}
                />
              ))
            : null}
          {noAddValue ? null : (
            <>
              {!isAttributeValueInputOpen ? (
                <StyledButton
                  data-test-id={`${dataTestIdPrefix}_AddNewValueBtn`}
                  onClick={() => setIsAttributeValueInputOpen(true)}
                  color='tertiary'
                  leftIcon={<PlusCircle />}
                >
                  Добавить значение
                </StyledButton>
              ) : (
                <StyledAttributeInput
                  dataTestIdPrefix={`${dataTestIdPrefix}_AddNewValue`}
                  isAttributeValue
                  groupId={id}
                  inputRef={inputRef}
                  onClose={() => setIsAttributeValueInputOpen(false)}
                  onSubmit={handleAddAttributeValue}
                />
              )}
            </>
          )}
        </>
      ) : null}
    </>
  )
}
