import { Button } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

type ConfirmChangesOverlayWrapperProps = {
  visible: boolean
}

export const ConfirmChangesOverlayWrapper = styled.div`
  position: relative;
  z-index: 0;
  left: -4px;
  width: calc(100% - 10px);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all ease-in-out 0.3s;
  ${({ visible }: ConfirmChangesOverlayWrapperProps) =>
    visible
      ? css`
          bottom: 0;
        `
      : css`
          bottom: -100px;
          display: none;
        `};
`

export const ChangesInfoSection = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.132923px;
  color: #353b60;
`

export const ButtonSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  button {
    svg {
      width: 24px;
      height: 24px;
    }
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`

export const StyledButton = styled(Button)`
  background: #4c5ecf;
  color: #fff;
  svg {
    fill: #fff;
  }
  &:hover {
    background: #3649c9;
  }
  &:active {
    background: #3042b5;
  }
`
