import { IconButton } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

export const AttributeTabTabWrapper = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: flex-start;
  min-height: 0;
  & > :first-child {
    border-left: none;
  }
`

export const AttributeTabContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  overflow: hidden;
  height: 100%;
  flex-shrink: 0;
  border-right: 1px solid rgba(229, 231, 241, 1);
  border-left: 1px solid rgba(229, 231, 241, 1);
`

export const AttributeTabListHeader = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  color: rgba(0, 0, 61, 1);
  padding: 0 8px;
  padding-bottom: 11px;
  border-bottom: 1px solid rgba(229, 231, 241, 1);
`

export const ListHeaderHeading = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
`

export const ListHeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

type AddAttributeValueButtonProps = {
  hide?: boolean
  disabled?: boolean
}

export const AddAttributeValueButton = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  background: rgba(53, 59, 96, 0.05);
  padding: 6px 8px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(53, 59, 96, 1);
  cursor: pointer;
  &:hover {
    background: rgba(53, 59, 96, 0.08);
  }
  &:active {
    background: rgba(53, 59, 96, 0.1);
  }

  svg {
    max-height: 24px;
  }
  ${({ disabled }: AddAttributeValueButtonProps) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.3;
        `
      : ''}
  ${({ hide }: AddAttributeValueButtonProps) =>
    hide
      ? css`
          pointer-events: none;
          opacity: 0;
        `
      : ''}
`

export const AttributeTabList = styled.div`
  padding-left: 8px;
  margin-right: 4px;
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }
`
export const DeletePopupTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 61, 1);
  margin: 6px 2px;
`
export const TabRightSideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 49px;
  width: 100%;
`

export const StyledIconButton = styled(IconButton)`
  margin: 0 24px 10px auto;
  svg {
    padding: 5px;
    flex-shrink: 0;
  }
`

export const Badge = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background: rgba(76, 94, 207, 1);
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;

  svg {
    max-width: 14px;
    max-height: 14px;
    cursor: pointer;
    path {
      stroke: rgba(255, 255, 255, 0.5);
    }
    &:hover path {
      stroke: #fff;
    }
  }
`
