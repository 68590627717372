import { RoundedCross, Tabs } from '@gmini/ui-kit'
import { useState, Dispatch, SetStateAction } from 'react'

import { UserDetailsDrawerTab } from '../UserDetailsDrawerTab/UserDetailsDrawerTab'
import { UserRolesDrawerTab } from '../UserRolesDrawerTab/UserRolesDrawerTab'

import { User } from '../../store/users/types'

import { Drawer } from '../Drawer/Drawer'

import { Name, TextBlock } from '../AddUserPopup/AddUserPopup.styled'

import { useAppSelector } from '../../store/store'

import { UserAccessDrawerTab } from '../UserAccessDrawerTab/UserAccessDrawerTab'

import {
  BtnContainer,
  EditUserPageWrapper,
  Email,
  StyledUserBadge,
  StyledUserInfoWrapper,
  TabTitle,
  Title,
  TitleButton,
  TitleContainer,
} from './EditUserPage.styled'

type EditUserDrawerProps = {
  userState: User | null
  setUserState: Dispatch<SetStateAction<User | null>>
  noRolesEdit?: boolean
  inProject?: boolean
  unsavedUserChanges: boolean
  setUnsavedUserChanges: Dispatch<SetStateAction<boolean>>
  isUnsavedChangesPopupOpen: boolean
  setIsUnsavedChangesPopupOpen: Dispatch<SetStateAction<boolean>>
}

export const EditUserDrawer = ({
  userState,
  setUserState,
  noRolesEdit = false,
  inProject = false,
  isUnsavedChangesPopupOpen,
  setIsUnsavedChangesPopupOpen,
  setUnsavedUserChanges,
  unsavedUserChanges,
}: EditUserDrawerProps) => {
  const [tabIndex, setTabIndex] = useState(noRolesEdit ? 0 : 1)
  const { support } = useAppSelector(state => state.currentUser)

  if (!userState) {
    return null
  }

  const { name, email } = userState

  const tabs = [
    {
      title: <TabTitle data-test-id='drawerDetailsTabBtn'>Детали</TabTitle>,
      content: (
        <UserDetailsDrawerTab
          isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
          setUnsavedUserChanges={setUnsavedUserChanges}
          setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
          user={userState}
          setUserState={setUserState}
        />
      ),
    },
  ]

  !noRolesEdit &&
    tabs.push({
      title: (
        <TabTitle data-test-id='drawerRolesTabBtn'>
          {inProject ? 'Роли проекта' : 'Проекты и Роли'}
        </TabTitle>
      ),
      content: (
        <UserRolesDrawerTab
          inProject={inProject}
          user={userState}
          setUserState={setUserState}
        />
      ),
    })

  support &&
    tabs.push({
      title: <TabTitle data-test-id='drawerAccessTabBtn'>Доступ</TabTitle>,
      content: (
        <UserAccessDrawerTab
          isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
          setUnsavedUserChanges={setUnsavedUserChanges}
          setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
          user={userState}
          setUserState={setUserState}
        />
      ),
    })

  return (
    <Drawer>
      <EditUserPageWrapper>
        <TitleContainer>
          <Title data-test-id='drawerHeading'>Профиль пользователя</Title>
          <BtnContainer>
            <TitleButton
              data-test-id='closeDrawerBtn'
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                if (unsavedUserChanges) {
                  setIsUnsavedChangesPopupOpen(true)
                  return
                }
                setUserState(null)
              }}
              size='small'
            >
              <RoundedCross />
            </TitleButton>
          </BtnContainer>
        </TitleContainer>
        <StyledUserInfoWrapper>
          <StyledUserBadge bg='rgba(76, 94, 207, 0.15)'>
            {`${name[0]}${name[name.indexOf(' ') + 1]}`}
          </StyledUserBadge>
          <TextBlock>
            <Name>{name}</Name>
            <Email>{email}</Email>
          </TextBlock>
        </StyledUserInfoWrapper>
        <Tabs
          tabs={tabs}
          activeTabIndex={tabIndex}
          onChangeTab={idx => {
            if (unsavedUserChanges) {
              setIsUnsavedChangesPopupOpen(true)
              return
            }
            setTabIndex(idx)
          }}
        />
      </EditUserPageWrapper>
    </Drawer>
  )
}
