import { AllFolders, Pointer, Tabs } from '@gmini/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getProjectUsers } from '../../store/users/actions'
import {
  GetUsersPaginatedListParams,
  User,
  verifyStatusMap,
} from '../../store/users/types'
import { AddUserPopup } from '../AddUserPopup/AddUserPopup'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { AddUserIcon } from '../icons/AddUserIcon'
import { UserList } from '../UserList/UserList'

import { getProjectAttributes, getProjects } from '../../store/projects/actions'

import { ProjectOrModuleAttributeList } from '../ProjectOrModuleAttributeList/ProjectOrModuleAttributeList'

import {
  AddUserButton,
  BackButton,
  ButtonContainer,
  ListHeading,
  StyledTabTitle,
  Title,
  UserListWrapper,
} from './ProjectPage.styled'

const size = 100

export const ProjectPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const [activeTab, setActiveTab] = useState(0)

  const { setByUrn, total: totalProjects } = useAppSelector(
    state => state.projects,
  )
  const [isUnsavedChangesPopupOpen, setIsUnsavedChangesPopupOpen] =
    useState(false)
  const [unsavedUserChanges, setUnsavedUserChanges] = useState(false)
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [addUserPopupOpen, setAddUserPopupOpen] = useState(false)
  const { projectUrn, hubId, tabId } = useParams<{
    projectUrn: string
    hubId: string
    tabId: string
  }>()
  const {
    list: users,
    totalBySearch,
    pending,
  } = useAppSelector(state => state.projectUsers)

  const { list: projectAttributesList } = useAppSelector(
    state => state.currentProjectAttributes,
  )

  useEffect(() => {
    if (!totalProjects) {
      dispatch(getProjects())
    }
  }, [dispatch, totalProjects])

  useEffect(() => {
    if (!userDetails || userDetails?.userRoles.length === 1) {
      return
    }
    const projectRoles = userDetails?.userRoles.find(
      roles => roles.projectUrn === projectUrn,
    )
    if (projectRoles) {
      setUserDetails({
        ...userDetails,
        userRoles: [
          { ...projectRoles, projectName: setByUrn[projectUrn]?.name },
        ],
      })
    }
  }, [userDetails, projectUrn, setByUrn])

  const projectName = setByUrn[projectUrn]?.name

  const fetchUserList = useCallback(
    (params: GetUsersPaginatedListParams) =>
      dispatch(
        getProjectUsers({
          projectUrn,
          verifyStatus: verifyStatusMap[activeTab],
          ...params,
        }),
      ),
    [dispatch, projectUrn, activeTab],
  )

  const fetchProjectAttributesList = useCallback(() => {
    dispatch(getProjectAttributes({ projectUrn }))
  }, [dispatch, projectUrn])

  useEffect(() => {
    const unblock = history.block(() => {
      if (unsavedUserChanges) {
        setIsUnsavedChangesPopupOpen(true)
        return false
      }
    })
    return unblock
  }, [history, unsavedUserChanges])

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <BackButton
            data-test-id='backToHubBtn'
            onClick={() => {
              if (unsavedUserChanges) {
                setIsUnsavedChangesPopupOpen(true)
                return
              }
              history.push(`/hub/${hubId}`)
            }}
          >
            <Pointer />
          </BackButton>
          <AllFolders />
          <span data-test-id='projectPageHeading'>{projectName}</span>
        </Title>
      </ListHeading>
      <Tabs
        activeTabIndex={Number(tabId)}
        onChangeTab={tabIdx =>
          history.push(`/hub/${hubId}/project/${projectUrn}/${tabIdx}`)
        }
        headerStyles={{
          padding: '0 24px',
          boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.1)',
          clipPath: 'polygon(0 0, 100% 0, 100% 300%, 0 300%)',
          borderBottom: 'none',
          flexShrink: 0,
        }}
        contentStyles={{
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          paddingTop: '17px',
        }}
        tabs={[
          {
            title: <StyledTabTitle>Пользователи</StyledTabTitle>,
            dataTestId: 'projectUsersTab',
            content: (
              <>
                <ButtonContainer>
                  <AddUserButton
                    data-test-id='addUserBtn'
                    onClick={() => setAddUserPopupOpen(true)}
                    size='regular'
                    color='primary'
                    leftIcon={<AddUserIcon width='16px' height='16px' />}
                  >
                    <span>Добавить пользователя</span>
                  </AddUserButton>
                </ButtonContainer>
                <AddUserPopup
                  open={addUserPopupOpen}
                  onClose={() => setAddUserPopupOpen(false)}
                />
                <EditUserDrawer
                  inProject
                  userState={userDetails}
                  setUserState={setUserDetails}
                  unsavedUserChanges={unsavedUserChanges}
                  setUnsavedUserChanges={setUnsavedUserChanges}
                  isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
                  setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
                />
                <UserList
                  localStoragePrefix='project'
                  users={users}
                  pending={pending}
                  size={size}
                  total={totalBySearch}
                  fetchUserList={fetchUserList}
                  setUserDetails={setUserDetails}
                  unsavedUserChanges={unsavedUserChanges}
                  setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
                  inProject
                  activeUsersTab={activeTab}
                  setActiveUsersTab={setActiveTab}
                />
              </>
            ),
          },
          {
            title: <StyledTabTitle>Атрибуты</StyledTabTitle>,
            dataTestId: 'projectAttributesTab',
            content: (
              <ProjectOrModuleAttributeList
                fetchList={fetchProjectAttributesList}
                list={projectAttributesList}
              />
            ),
          },
        ]}
      />
    </UserListWrapper>
  )
}
