import { TableColumn, TableDataItem } from '@gmini/ui-kit'

import { MIN_COLUMN_WIDTH } from '../../constants'

type ColumnSettings = {
  field: string
  visible: boolean
  width: number
}

export function getColumnsFromStorage<T extends TableDataItem>(
  storageKey: string,
  columns: TableColumn<T>[],
): (TableColumn<T> & { width?: number })[] {
  const data = localStorage.getItem(storageKey)
  if (typeof data === 'string') {
    try {
      const parsedData = JSON.parse(data) as ColumnSettings[]
      const parsedDataMap = parsedData.reduce(
        (acc: { [x: string]: ColumnSettings }, val) => ({
          ...acc,
          [val.field]: val,
        }),
        {},
      )
      return columns
        .slice()
        .sort((a, b) => {
          const aIdx = parsedData.findIndex(s => s.field === a.field)
          const bIdx = parsedData.findIndex(s => s.field === b.field)

          // В случае если в localStorage не было настройки колонки (например: в коде добавили новую)
          if (aIdx < 0 || bIdx < 0) {
            return 0
          }

          return aIdx - bIdx
        })
        .map(col => ({
          ...col,
          visible:
            typeof col.field === 'string'
              ? parsedDataMap[col.field]?.visible
              : true,
          width:
            typeof col.field === 'string'
              ? parsedDataMap[col.field]?.width
              : MIN_COLUMN_WIDTH,
          cellStyle: {
            ...col.cellStyle,
            ...(typeof col.field === 'string' && {
              width: `${parsedDataMap[col.field]?.width}px`,
            }),
          },
        }))
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
  return columns
}

export function setColumnsToStorage<T extends TableDataItem>(
  storageKey: string,
  next: (TableColumn<T> & { width?: number })[],
) {
  localStorage.setItem(
    storageKey,
    JSON.stringify(
      next.map(({ field, visible, width }) => ({ field, visible, width })),
    ),
  )
}
