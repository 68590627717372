import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`
export const SimpleScrollbar = styled.div`
  overflow: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
`
