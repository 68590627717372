import { SvgComponentType } from '@gmini/ui-kit'

export const SettingsListIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM5 8C5 8.55228 5.44772 9 6 9C6.55228 9 7 8.55228 7 8C7 7.44772 6.55228 7 6 7C5.44772 7 5 7.44772 5 8ZM18 7H9C8.44772 7 8 7.44772 8 8C8 8.55228 8.44771 9 9 9H18C18.5523 9 19 8.55228 19 8C19 7.44772 18.5523 7 18 7ZM12 11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11ZM9 15H10C10.5523 15 11 15.4477 11 16C11 16.5523 10.5523 17 10 17H9C8.44772 17 8 16.5523 8 16C8 15.4477 8.44772 15 9 15ZM6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11C6.55228 11 7 11.4477 7 12C7 12.5523 6.55228 13 6 13ZM5 16C5 16.5523 5.44772 17 6 17C6.55228 17 7 16.5523 7 16C7 15.4477 6.55228 15 6 15C5.44772 15 5 15.4477 5 16Z'
      fill={color}
    />
    <circle cx='18.5' cy='18.5' r='1.5' fill='white' />
    <path
      d='M24.5721 16.2571L24.5722 16.2571L24.569 16.2518L23.5125 14.4774C23.1769 13.9068 22.4699 13.685 21.8717 13.9355L21.0148 14.2882C21.0016 14.2805 20.9882 14.273 20.9749 14.2655L20.8621 13.3921C20.862 13.3913 20.8619 13.3904 20.8618 13.3896C20.7784 12.7241 20.2022 12.25 19.5578 12.25H17.4479C16.8025 12.25 16.2222 12.7196 16.1382 13.3897L16.0251 14.2655C16.0118 14.273 15.9984 14.2805 15.9852 14.2882L15.1283 13.9355C14.53 13.6849 13.8227 13.9069 13.4872 14.4779L12.4305 16.2582L12.4304 16.2582L12.4279 16.2626C12.0913 16.8385 12.2462 17.5594 12.7711 17.955L12.7711 17.955L12.7775 17.9597L13.5076 18.4979C13.5076 18.4986 13.5076 18.4993 13.5076 18.5C13.5076 18.5025 13.5076 18.5051 13.5076 18.5076L12.7787 19.0449C12.2385 19.4403 12.0955 20.1741 12.4279 20.7429L12.4278 20.7429L12.431 20.7482L13.4875 22.5226C13.8231 23.0932 14.5301 23.315 15.1283 23.0645L15.9852 22.7118C15.9984 22.7195 16.0118 22.727 16.0251 22.7345L16.1379 23.6079C16.138 23.6087 16.1381 23.6096 16.1382 23.6104C16.2216 24.2759 16.7978 24.75 17.4422 24.75H19.5521C20.1965 24.75 20.7727 24.2759 20.8561 23.6104C20.8562 23.6096 20.8564 23.6087 20.8565 23.6079L20.9692 22.7345C20.9826 22.727 20.9959 22.7195 21.0092 22.7118L21.8661 23.0645C22.4642 23.315 23.1713 23.0932 23.5068 22.5226L24.5634 20.7482L24.5634 20.7482L24.5665 20.7429C24.903 20.167 24.7482 19.4461 24.2232 19.0505L24.2233 19.0505L24.2169 19.0458L23.4924 18.5118C23.4924 18.5078 23.4924 18.5039 23.4924 18.5C23.4924 18.4975 23.4924 18.4949 23.4924 18.4924L24.2194 17.9565C24.2201 17.956 24.2207 17.9556 24.2214 17.9551C24.7615 17.5597 24.9045 16.8259 24.5721 16.2571ZM18.5226 19.675C17.8252 19.675 17.2928 19.1276 17.2928 18.5C17.2928 17.8724 17.8252 17.325 18.5226 17.325C19.2201 17.325 19.7524 17.8724 19.7524 18.5C19.7524 19.1276 19.2201 19.675 18.5226 19.675Z'
      fill={color}
      stroke='white'
      fillRule='evenodd'
      strokeWidth='1.5'
    />
    <defs>
      <clipPath id='clip0_5728_4123'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
