import { SvgComponentType } from '@gmini/ui-kit'

export const UnlinkIcon = ({
  color = '#00003D',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.7494 4.31169C14.7509 2.31017 17.9961 2.31017 19.9976 4.31169C21.9992 6.31321 21.9992 9.55838 19.9976 11.5599L17.8106 13.7469C17.4201 14.1374 16.7869 14.1374 16.3964 13.7469C16.0059 13.3564 16.0059 12.7232 16.3964 12.3327L18.5834 10.1457C19.8039 8.92521 19.8039 6.94638 18.5834 5.7259C17.3629 4.50543 15.3841 4.50543 14.1636 5.7259L11.9766 7.9129C11.5861 8.30343 10.9529 8.30343 10.5624 7.9129C10.1719 7.52238 10.1719 6.88921 10.5624 6.49869L12.7494 4.31169Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.8321 13.4789C11.2226 13.8695 11.2226 14.5026 10.8321 14.8931L8.95711 16.7681C8.56658 17.1587 7.93342 17.1587 7.54289 16.7681C7.15237 16.3776 7.15237 15.7445 7.54289 15.3539L9.41789 13.4789C9.80842 13.0884 10.4416 13.0884 10.8321 13.4789Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.4571 7.85393C16.8476 8.24445 16.8476 8.87762 16.4571 9.26814L14.5821 11.1431C14.1916 11.5337 13.5584 11.5337 13.1679 11.1431C12.7774 10.7526 12.7774 10.1195 13.1679 9.72893L15.0429 7.85393C15.4334 7.4634 16.0666 7.4634 16.4571 7.85393Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.26164 4.57268C4.65217 4.18215 5.28533 4.18215 5.67586 4.57268L19.7379 18.6347C20.1284 19.0252 20.1284 19.6584 19.7379 20.0489C19.3473 20.4394 18.7142 20.4394 18.3236 20.0489L4.26164 5.98689C3.87112 5.59637 3.87112 4.9632 4.26164 4.57268Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.60236 10.8749C7.99288 11.2654 7.99288 11.8986 7.60236 12.2891L5.41536 14.4761C4.19488 15.6966 4.19488 17.6754 5.41536 18.8959C6.63583 20.1164 8.61467 20.1164 9.83514 18.8959L12.0221 16.7089C12.4127 16.3184 13.0458 16.3184 13.4364 16.7089C13.8269 17.0994 13.8269 17.7326 13.4364 18.1231L11.2494 20.3101C9.24783 22.3117 6.00267 22.3117 4.00114 20.3101C1.99962 18.3086 1.99962 15.0634 4.00114 13.0619L6.18814 10.8749C6.57867 10.4844 7.21183 10.4844 7.60236 10.8749Z'
      fill={color}
    />
  </svg>
)
