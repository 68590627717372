import { HTMLAttributes } from 'react'

import { getColor, getRoleBGColor } from '../../constants'
import { UserType } from '../../store/users/types'

import { RoleBadgeText, RoleBadgeWrapper } from './RoleBadge.styled'

type RoleBadgeProps = HTMLAttributes<HTMLDivElement> & {
  roleId?: number | UserType
  roleTitle?: string
  noMargin?: boolean
}

export const RoleBadge = ({
  roleId = UserType.NEW,
  roleTitle,
  noMargin = false,
  ...other
}: RoleBadgeProps) => (
  <RoleBadgeWrapper noMargin={noMargin} bg={getRoleBGColor(roleId)} {...other}>
    <RoleBadgeText color={getColor(roleId)}>
      {roleTitle || roleId}
    </RoleBadgeText>
  </RoleBadgeWrapper>
)
