import { IconButton } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

import { Accordion } from '../Accordion/Accordion'
import { SimpleScrollbar } from '../ProjectList/ProjectList.styled'

type RoleProfileWrapperProps = {
  visible: boolean
}

export const RoleProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 24px 0;
  width: 50%;
  visibility: ${({ visible }: RoleProfileWrapperProps) =>
    visible ? 'visible' : 'hidden'};
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`
export const TitleButton = styled(IconButton)`
  padding: 0;
  margin-left: 'auto';
  margin-right: '6px';

  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: #353b60;
    }
  }
`

export const Description = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #353b60;
`

export const Hint = styled.div`
  margin-top: 32px;
  margin-bottom: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #353b60;
`

export const PermissionsList = styled.div`
  max-width: 650px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const StyledScrollbar = styled(SimpleScrollbar)`
  padding-right: 12px;
`
export const Heading = styled.div`
  padding: 11px 132px 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #edeff5;
  border-top: 1px solid #e5e7f1;
  border-bottom: 1px solid #e5e7f1;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #a2a3b7;
`

type PermissionSettingsBlockProps = {
  grayscaled: boolean
}

export const PermissionSettingsBlock = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: ${({ grayscaled }: PermissionSettingsBlockProps) =>
    grayscaled ? '#fff' : 'rgba(244, 244, 248, 0.6)'};
`

type StyledAccordionProps = {
  grayscaled?: boolean
}

export const StyledAccordion = styled(Accordion)`
  ${({ grayscaled }: StyledAccordionProps) =>
    grayscaled
      ? css`
          & > :first-child {
            background: #fff;
            color: rgba(53, 59, 96, 0.5);
          }
        `
      : css`
          & > :first-child {
            background: rgba(244, 244, 248, 0.6);
            color: rgba(53, 59, 96, 1);
          }
        `}
`

export const ChangesPopupContent = styled.div`
  padding: 13.5px 24px 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #00003d;

  span {
    font-weight: 600;
  }
`
