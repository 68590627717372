import { IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

import { UserBadge, UserInfoWrapper } from '../AddUserPopup/AddUserPopup.styled'

export const EditUserPageWrapper = styled.div`
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  height: inherit;
  display: flex;
  flex-direction: column;

  & > :last-child {
    flex: 1;
    padding-bottom: 0;
    min-height: 0;
  }
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000335;
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`

export const TabTitle = styled.div`
  margin: 6px 10px;
`

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 28px;
`

export const TitleButton = styled(IconButton)`
  padding: 0;
  margin-left: 'auto';
  margin-right: '6px';

  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: #353b60;
    }
  }
`

export const StyledUserInfoWrapper = styled(UserInfoWrapper)`
  margin-bottom: 9px;
`

export const StyledUserBadge = styled(UserBadge)`
  width: 44px;
  min-width: 44px;
  height: 44px;
  font-size: 16px;
`

export const Email = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #353b60;
  opacity: 0.7;
  margin-top: 4px;
`
