import { SvgComponentType } from '@gmini/ui-kit'

export const LinkToProjectIcon = ({
  color = '#00003D',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g clipPath='url(#clip0_2419_451835)'>
      <path
        d='M11 3.91421C11 3.02331 12.0771 2.57714 12.7071 3.20711L20.4142 10.9142C20.7893 11.2893 21 11.798 21 12.3284V20C21 20.5523 20.5523 21 20 21H12C11.4477 21 11 20.5523 11 20V3.91421Z'
        fill='#00003D'
      />
      <rect x='3' y='3' width='2' height='10' rx='1' fill={color} />
      <rect x='7' y='3' width='2' height='10' rx='1' fill={color} />
      <path
        d='M8.84203 17.1129C9.05266 17.3244 9.05266 17.6756 8.84203 17.8871L6.88989 19.8483C6.56197 20.1776 6.01527 19.9355 6.01527 19.4611V18.3067H2.77437C2.34671 18.3067 2 17.9455 2 17.5C2 17.0545 2.34671 16.6933 2.77437 16.6933H6.01527V15.5389C6.01527 15.0645 6.56197 14.8224 6.88989 15.1517L8.84203 17.1129ZM8.84203 17.1129C9.05266 17.3244 9.05266 17.6756 8.84203 17.8871L6.88989 19.8483C6.56197 20.1776 6.01527 19.9355 6.01527 19.4611V18.3067H2.77437C2.34671 18.3067 2 17.9455 2 17.5C2 17.0545 2.34671 16.6933 2.77437 16.6933H6.01527V15.5389C6.01527 15.0645 6.56197 14.8224 6.88989 15.1517L8.84203 17.1129Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_2419_451835'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
