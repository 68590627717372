import { ActionPopupWrapper } from '@gmini/ui-kit'

import { useForm, Controller } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from '../../store/store'

import { addAttributeGroup } from '../../store/attributes/actions'

import {
  EmptyContainer,
  FieldContainer,
  FieldError,
  FieldLabel,
  Form,
  TextField,
} from './AddAttributeValueGroupPopup.styled'

type AddAttributeValueGroupPopupProps = {
  open: boolean
  onClose: () => void
}
const requiredErrorMessage = 'Это поле является обязательным'

const defaultValues = {
  name: '',
}

const popupButtonsDataTestIds = {
  acceptButton: 'addAttributeValueGroupPopupConfirmBtn',
  declineButton: 'addAttributeValueGroupPopupCancelBtn',
}

export const AddAttributeValueGroupPopup = ({
  open,
  onClose,
}: AddAttributeValueGroupPopupProps) => {
  const { id: attributeId } = useAppSelector(state => state.currentAttribute)
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })
  const dispatch = useAppDispatch()

  const onSubmit = async (data: typeof defaultValues) => {
    const { name: nameInit } = data
    await dispatch(addAttributeGroup({ name: nameInit.trim(), attributeId }))
    reset()
    onClose()
  }

  return (
    <ActionPopupWrapper
      title={
        <span data-test-id='addAttributeValueGroupPopupHeading'>
          Создать группировку
        </span>
      }
      submitButtonTitle='Создать'
      pending={!watch().name.trim()}
      open={open}
      width='600px'
      onClose={() => {
        reset()
        onClose()
      }}
      onSubmit={handleSubmit(onSubmit)}
      dataTestIdFor={popupButtonsDataTestIds}
    >
      {({ existScroll }) => (
        <Form>
          <FieldContainer>
            <FieldLabel required>Наименование группировки</FieldLabel>
            <Controller
              name='name'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
              }}
              render={({ field }) => (
                <TextField
                  data-test-id='attributeValueGroupNameInput'
                  {...field}
                  error={Boolean(errors.name)}
                  clearable
                />
              )}
            />
            <FieldError hidden={!errors.name}>
              {errors.name && 'message' in errors.name
                ? errors.name.message
                : null}
            </FieldError>
          </FieldContainer>
          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}
