import qs from 'query-string'
import { useHistory } from 'react-router-dom'

export const editUrlSearchParams = (
  params: Record<string, unknown>,
  history: ReturnType<typeof useHistory>,
) => {
  const prev = qs.parse(window.location.search)
  const search = qs.stringify({ ...prev, ...params }, { skipEmptyString: true })
  history.replace({ search })
}
