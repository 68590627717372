import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'

import {
  currentUserReducer,
  projectUsersReducer,
  allUsersReducer,
  moduleUsersReducer,
  companyUsersReducer,
  allUsersPaginatedReducer,
} from './users/users-slice'
import { error403 } from './403/403.slice'
import { maintenance } from './maintenance/maintenance.slice'
import {
  currentModuleAttributesReducer,
  modulesReducer,
} from './modules/modules-slice'
import {
  currentProjectAttributesReducer,
  projectsReducer,
} from './projects/projects-slice'
import {
  resourcesReducer,
  rolesReducer,
  hubPermissionsReducer,
} from './roles/roles-slice'
import { companiesReducer } from './companies/companies-slice'
import {
  attributesReducer,
  currentAttributeReducer,
} from './attributes/attributes-slice'
import { hubsReducer } from './hubs/hubs-slice'

export const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  projectUsers: projectUsersReducer,
  companyUsers: companyUsersReducer,
  moduleUsers: moduleUsersReducer,
  allUsersPaginated: allUsersPaginatedReducer,
  allUsers: allUsersReducer,
  projects: projectsReducer,
  currentProjectAttributes: currentProjectAttributesReducer,
  companies: companiesReducer,
  attributes: attributesReducer,
  currentAttribute: currentAttributeReducer,
  toastr: toastrReducer,
  roles: rolesReducer,
  resources: resourcesReducer,
  hubs: hubsReducer,
  hubPermissions: hubPermissionsReducer,
  modules: modulesReducer,
  currentModuleAttributes: currentModuleAttributesReducer,
  maintenance,
  error403,
})
