import { Cancel, CheckMark, IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

import { ArrowExpandMore } from '../icons/ArrowExpandMore'

export const StyledInput = styled.input`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #000335;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  &::placeholder {
    color: rgba(0, 3, 53, 0.3);
  }
`
export const InputWrapper = styled.div`
  margin-left: 4px;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    height: 3px;
    background: transparent;
    position: absolute;
    left: -2px;
    bottom: -3px;
    width: 100%;
    border: 2px solid #4c5ecf;
    border-top: none;
    border-radius: 1px;
  }
`

export const CancelStyled = styled(Cancel)`
  & path {
    fill: #d9575e;
  }
`

export const CheckMarkStyled = styled(CheckMark)`
  & path {
    fill: #4c5ecf;
  }
`
export const ButtonsContainer = styled.div`
  margin-left: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const SideWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const IconButtonStyled = styled(IconButton)`
  padding: 2px;
  & svg {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
  }
`
export const StyledArrowExpandMore = styled(ArrowExpandMore)`
  flex-shrink: 0;
  box-sizing: content-box;
`
export const AttributeTabListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  color: rgba(0, 0, 61, 0.6);
  border-radius: 4px;
  padding: 4px 8px 4px 5px;
  &:hover,
  &:focus-within {
    background: rgba(241, 243, 255, 1);
  }
`
