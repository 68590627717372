import React, { useState } from 'react'

import { Cross, HorizontalThreeDots, OpenCtxButton } from '@gmini/ui-kit'

import { ClickAwayListener } from '@material-ui/core'

import { useHistory, useParams } from 'react-router-dom'

import { AttributeValue } from '../../store/attributes/types'

import {
  AttributeValuesContainer,
  AttributeValuesPopup,
  AttributeValuesPopupContainer,
  AttributeValuesPopupHeader,
  AttributeValuesPopupLink,
  AttributeValuesTableCellWrapper,
  StyledAttributeValue,
} from './AttributeValuesTableCell.styled'

type AttributeValuesTableCellProps = {
  link: (hubId: string) => string
  attributeValues: AttributeValue[]
  dataTestIdPrefix?: string
}

export const AttributeValuesTableCell = ({
  link,
  attributeValues,
  dataTestIdPrefix,
}: AttributeValuesTableCellProps) => {
  const { hubId } = useParams<{ hubId: string }>()
  const history = useHistory()
  const [isAttributeValuesPopupOpen, setIsAttributeValuesPopupOpen] =
    useState(false)
  return (
    <AttributeValuesTableCellWrapper>
      <AttributeValuesContainer>
        {attributeValues.map(v => (
          <StyledAttributeValue key={v.id}>{v.name}</StyledAttributeValue>
        ))}
      </AttributeValuesContainer>
      <OpenCtxButton
        onClick={e => {
          e.stopPropagation()
          setIsAttributeValuesPopupOpen(true)
        }}
        active={isAttributeValuesPopupOpen}
        data-test-id={`${dataTestIdPrefix}_AttributesCell_OpenBtn`}
      >
        <HorizontalThreeDots />
      </OpenCtxButton>
      {isAttributeValuesPopupOpen && (
        <ClickAwayListener
          onClickAway={() => setIsAttributeValuesPopupOpen(false)}
        >
          <AttributeValuesPopup onClick={e => e.stopPropagation()}>
            <AttributeValuesPopupHeader>
              <span>Привязанные значения атрибутов</span>
              <Cross
                data-test-id={`${dataTestIdPrefix}_AttributesCell_CloseBtn`}
                width={16}
                height={16}
                onClick={() => setIsAttributeValuesPopupOpen(false)}
              />
            </AttributeValuesPopupHeader>
            <AttributeValuesPopupContainer>
              {attributeValues.map(v => (
                <StyledAttributeValue key={v.id}>{v.name}</StyledAttributeValue>
              ))}
            </AttributeValuesPopupContainer>
            <AttributeValuesPopupLink
              data-test-id={`${dataTestIdPrefix}_AttributesCell_TabLink`}
              onClick={() => history.push(link(hubId))}
            >
              Перейти в раздел
            </AttributeValuesPopupLink>
          </AttributeValuesPopup>
        </ClickAwayListener>
      )}
    </AttributeValuesTableCellWrapper>
  )
}
