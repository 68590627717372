import { useMemo, useState } from 'react'
import {
  IconButton,
  Popup,
  RoundedCross,
  Button as UiKitButton,
  WarningFilled,
} from '@gmini/ui-kit'

import { detachAttributeValueToProject } from '../../store/attributes/actions'

import { useAppDispatch, useAppSelector } from '../../store/store'

import {
  projectModuleSplitter,
  projectModuleValueSplitter,
} from '../../store/attributes/attributes-slice'

import {
  AttributeValuesContainer,
  Button,
  Container,
  Footer,
  Header,
  Hint,
  PopupContent,
  StyledCircularProgress,
  StyledValueName,
  Text,
  Title,
} from './UnlinkAttributeValuesToProjectModal.styled'

type UnlinkAttributeValuesToProjectModalProps = {
  isOpen: boolean
  onClose: (reset?: boolean) => void
  checkedProjectModuleValuesMap: {
    [x: string]: boolean
  }
}

export const UnlinkAttributeValuesToProjectModal = ({
  isOpen,
  onClose,
  checkedProjectModuleValuesMap,
}: UnlinkAttributeValuesToProjectModalProps) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const {
    childrenFlatMap,
    name,
    id: attributeId,
  } = useAppSelector(state => state.currentAttribute)
  const checkedProjectModulesValuesList = useMemo(
    () =>
      Object.keys(checkedProjectModuleValuesMap).filter(
        key =>
          checkedProjectModuleValuesMap[key] &&
          key.includes(projectModuleValueSplitter),
      ),
    [checkedProjectModuleValuesMap],
  )

  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    setIsSubmitLoading(true)
    await dispatch(
      detachAttributeValueToProject({
        attributeId,
        itemValues: checkedProjectModulesValuesList.map(key => {
          const [projectUrn, moduleId, , valueId] = key.split(
            projectModuleSplitter,
          )
          return {
            projectUrn,
            moduleId,
            valueId: Number(valueId),
          }
        }),
      }),
    )
    setIsSubmitLoading(false)
    onClose(true)
  }

  return (
    <Popup width={'640px'} open={isOpen} onClose={() => onClose()}>
      <Container>
        <Header>
          <Title data-test-id='unlinkAttributeValuesToProjectModalHeading'>
            Отвязать значения атрибута от проектов
          </Title>
          <IconButton type='square'>
            <RoundedCross
              data-test-id='closeUnlinkAttributeValuesToProjectModal'
              onClick={() => onClose()}
            />
          </IconButton>
        </Header>
        <Text>
          Вы уверены что хотите отвязать следующие значения атрибута “{name}”?
        </Text>
        <AttributeValuesContainer>
          {Array(...new Set(checkedProjectModulesValuesList)).map(id => {
            const [, valueId] = id.split(projectModuleValueSplitter)

            return (
              <StyledValueName key={valueId}>
                {childrenFlatMap[Number(valueId)]?.name}
              </StyledValueName>
            )
          })}
        </AttributeValuesContainer>
        <PopupContent>
          <Hint>
            <WarningFilled
              width={22}
              height={22}
              color='rgba(34, 53, 170, 1)'
            />{' '}
            При отвязывании атрибута от проекта, его назначения к объектам
            проекта сохранятся
          </Hint>
        </PopupContent>
        <Footer>
          <Button
            data-test-id='unlinkAttributeValuesToProjectModalCancelBtn'
            onClick={() => onClose()}
          >
            Отменить
          </Button>

          <UiKitButton
            data-test-id='unlinkAttributeValuesToProjectModalConfirmBtn'
            onClick={handleSubmit}
            disabled={isSubmitLoading}
            size='regular'
            color='warning'
          >
            {isSubmitLoading ? (
              <StyledCircularProgress color='inherit' size={20} />
            ) : (
              'Отвязать'
            )}
          </UiKitButton>
        </Footer>
      </Container>
    </Popup>
  )
}
