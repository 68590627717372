import { SvgComponentType } from '@gmini/ui-kit'

export const KeyIcon = ({ color = '#353B60', ...other }: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      d='M2.67929 16.3207C3.86492 17.5063 5.30711 18.1 7 18.1C8.31647 18.1 9.43336 17.7833 10.3449 17.1444C11.2347 16.5207 11.9004 15.6968 12.3411 14.675H15.6V16C15.6 17.1598 16.5402 18.1 17.7 18.1H18.35C19.5098 18.1 20.45 17.1598 20.45 16V14.675H21C22.1598 14.675 23.1 13.7348 23.1 12.575V11.425C23.1 10.2652 22.1598 9.325 21 9.325H12.3411C11.9004 8.30321 11.2347 7.47934 10.3449 6.85561C9.43336 6.21669 8.31647 5.9 7 5.9C5.30711 5.9 3.86492 6.49366 2.67929 7.67929C1.49366 8.86492 0.9 10.3071 0.9 12C0.9 13.6929 1.49366 15.1351 2.67929 16.3207ZM9.84065 15.2554C9.10287 16.0176 8.15881 16.4 7 16.4C5.77622 16.4 4.73924 15.9728 3.88321 15.1168C3.02718 14.2608 2.6 13.2238 2.6 12C2.6 10.7762 3.02718 9.73924 3.88321 8.88321C4.73924 8.02718 5.77622 7.6 7 7.6C8.15881 7.6 9.10287 7.98245 9.84065 8.74455C10.3214 9.2412 10.6719 9.7209 10.8953 10.1833C11.1152 10.6383 11.5481 11.025 12.0811 11.025H20.5C20.9971 11.025 21.4 11.4279 21.4 11.925V12.075C21.4 12.5721 20.9971 12.975 20.5 12.975H19.85C19.2425 12.975 18.75 13.4675 18.75 14.075V15.725C18.75 16.0978 18.4478 16.4 18.075 16.4C17.7022 16.4 17.4 16.0978 17.4 15.725V14.075C17.4 13.4675 16.9075 12.975 16.3 12.975H12.0811C11.5481 12.975 11.1152 13.3617 10.8953 13.8167C10.6719 14.2791 10.3214 14.7588 9.84065 15.2554ZM7 13.8C7.50833 13.8 7.93841 13.628 8.28321 13.2832C8.62801 12.9384 8.8 12.5083 8.8 12C8.8 11.4917 8.62801 11.0616 8.28321 10.7168C7.93841 10.372 7.50833 10.2 7 10.2C6.49167 10.2 6.06159 10.372 5.71679 10.7168C5.37199 11.0616 5.2 11.4917 5.2 12C5.2 12.5083 5.37199 12.9384 5.71679 13.2832C6.06159 13.628 6.49167 13.8 7 13.8Z'
      fill={color}
      stroke={color}
      strokeWidth='0.2'
    />
  </svg>
)
