import styled, { css } from 'styled-components'
import { TextField as TextFieldInit } from '@gmini/ui-kit'

import { RoleBadgeWrapper } from '../RoleBadge/RoleBadge.styled'
import { UserType } from '../../store/users/types'
export const FieldContainer = styled.div`
  width: 100%;
`
export const EmptyContainer = styled.div`
  height: 60px;
`
type FieldErrorProps = {
  hidden: boolean
}

export const FieldError = styled.div`
  opacity: ${(props: FieldErrorProps) => (props.hidden ? 0 : 1)};
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: #d9575e;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  margin-top: 4px;
  min-height: 21px;
  max-height: 140px;
  overflow-y: auto;
`
type FieldLabelProps = {
  required?: boolean
}

export const FieldLabel = styled.div`
  font-family: Roboto, sans-serif;
  color: #000335;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  &:after {
    ${(props: FieldLabelProps) => props.required && 'content: " *";'}
    color: #de4242;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }
`
export const TextField = styled(TextFieldInit)`
  background: #f4f4f8;
`
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  padding: 10px 24px 24px 24px;
`
type OptionProps = {
  hover?: boolean
  disabled?: boolean
  active?: boolean
}

export const Option = css`
  background: transparent;
  ${(props: OptionProps) =>
    props.hover && 'background: rgba(53, 59, 96, 0.05);'}
  ${(props: OptionProps) =>
    props.active && 'background: rgba(92, 130, 229, 0.15);'}
  ${(props: OptionProps) =>
    props.active &&
    props.hover &&
    'background: linear-gradient(rgba(53, 59, 96, 0.05), rgba(53, 59, 96, 0.05)), linear-gradient(rgba(92, 130, 229, 0.15), rgba(92, 130, 229, 0.15));'}
  padding: 16px;
  color: #353b60;
  cursor: ${(props: OptionProps) => (props.disabled ? 'default' : 'pointer')};
  user-select: none;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }
`

type UserOptionProps = {
  disabled?: boolean
}

export const UserOption = styled.div`
  ${Option}

  ${({ disabled }: UserOptionProps) =>
    disabled
      ? css`
          filter: opacity(0.45);
          background: transparent;
          ::after {
            content: 'На текущий момент на этой странице можно добавить только нового пользователя.';
            position: absolute;
            color: red;
            left: 50%;
            top: 30%;
            font-size: 11px;
          }
        `
      : ''}
`

type UserBadgeProps = {
  bg?: string
}

export const UserBadge = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: ${(props: UserBadgeProps) => (props.bg ? props.bg : '#a2a3b7')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 10.38px;
  line-height: 10.38px;
  letter-spacing: 0.05px;
  color: #4c5ecf;
  border-radius: 50%;
  text-transform: uppercase;
`

export const TextBlock = styled.div`
  margin-left: 8px;
  margin-right: auto;
  overflow: hidden;
`

export const Name = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.132923px;
  color: #353b60;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const EmailAndPhone = styled.div`
  display: flex;
  gap: 12px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #353b60;
  opacity: 0.7;
  margin-left: 32px;
  overflow: hidden;
`

type SelectWrapperProps = {
  userType: UserType | null
}

export const SelectWrapper = styled.div`
  position: relative;
  & input {
    ${({ userType }: SelectWrapperProps) => {
      switch (userType) {
        case UserType.ACCOUNT:
          return 'padding-right: 210px;'
        case UserType.PROJECT:
          return 'padding-right: 205px;'
        case UserType.NEW:
          return 'padding-right: 199px;'
        default:
          return ''
      }
    }}
  }

  & > ${RoleBadgeWrapper} {
    position: absolute;
    top: 12px;
    right: 60px;
  }
`

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`
