import { SvgComponentType } from '@gmini/ui-kit'

export const CompaniesIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ flexShrink: 0 }}
    {...other}
  >
    <g clipPath='url(#clip0_520_384059)'>
      <path
        d='M12 7V5.28571C12 4.02335 10.9767 3 9.71429 3H4.28571C3.02335 3 2 4.02335 2 5.28571V18.7143C2 19.9767 3.02335 21 4.28571 21H19.7143C20.9767 21 22 19.9767 22 18.7143V9.28571C22 8.02335 20.9767 7 19.7143 7H12ZM10 19H4V17H10V19ZM10 15H4V13H10V15ZM10 11H4V9H10V11ZM10 7H4V5H10V7ZM20 19H12V9H20V19ZM18 11H14V13H18V11ZM18 15H14V17H18V15Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_520_384059'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
