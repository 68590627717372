import { createSlice } from '@reduxjs/toolkit'

import { getProjectsUsersCount } from '../users/actions'

import { AttributeWithChildren } from '../attributes/types'

import { getProjectAttributes, getProjects } from './actions'
import { Project, mapSourceTypeDescription } from './types'

const initialProjectsState: {
  list: Project[]
  pending: boolean
  setByUrn: { [x: string]: Project | null }
  countSet: { [x: string]: number }
  total: number
} = {
  list: [],
  setByUrn: {},
  total: 0,
  pending: false,
  countSet: {},
}

const projects = createSlice({
  name: 'projects',
  initialState: initialProjectsState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProjects.fulfilled, (state, { payload }) => {
        const setByUrn = payload.reduce(
          (acc: { [x: string]: Project }, { project, attributeValues }) => ({
            ...acc,
            [project.urn]: {
              ...project,
              count: state.countSet[project.urn],
              attributeValues,
            },
          }),
          {},
        )
        const fusedList = payload.map(({ project, attributeValues }) => ({
          ...project,
          attributeValues,
          count: state.countSet[project.urn],
          searchString: `${project.name}${
            mapSourceTypeDescription[project.projectSource]
          }`,
        }))

        return {
          ...state,
          list: fusedList,
          setByUrn,
          total: payload.length || 0,
          pending: false,
        }
      })
      .addCase(getProjects.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getProjects.pending, (state, _) => ({ ...state, pending: true }))
      .addCase(getProjectsUsersCount.fulfilled, (state, { payload }) => {
        const countSet = payload.reduce(
          (acc: { [x: string]: number }, { projectUrn, count }) => ({
            ...acc,
            [projectUrn]: count,
          }),
          {},
        )
        return {
          ...state,
          countSet,
        }
      })
  },
})

export const projectsReducer = projects.reducer

const initialCurrentProjectAttributesState: {
  list: AttributeWithChildren[]
  pending: boolean
} = {
  list: [],
  pending: false,
}

const currentProjectAttributes = createSlice({
  name: 'currentProjectAttributes',
  initialState: initialCurrentProjectAttributesState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getProjectAttributes.fulfilled, (_, { payload }) => ({
        list: payload,
        pending: false,
      }))
      .addCase(getProjectAttributes.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getProjectAttributes.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const currentProjectAttributesReducer = currentProjectAttributes.reducer
