import { AuthProvider } from '@gmini/auth'
import { AuthClientTokens } from '@react-keycloak/core'

import { App } from './App'
import { authClient } from './keycloak'
import { apiClient } from './services'
import { EmptyTokenException } from './services/apiService/exceptions/emptyToken.exception'

const tokenLogger = (tokens: AuthClientTokens): void => {
  if (!tokens.token) {
    throw new EmptyTokenException()
  }
  apiClient.setToken(tokens.token)
}

export const Auth = () => (
  <AuthProvider authClient={authClient} onTokens={tokenLogger}>
    {({ authClient: client, initialized }) => {
      if (!initialized && !client.authenticated) {
        return <></>
      }

      if (initialized && client.authenticated) {
        client.updateToken(5)
        apiClient.setToken(client.token)
      }
      return <App />
    }}
  </AuthProvider>
)
