import { createSlice } from '@reduxjs/toolkit'

import { AttributeValue, AttributeWithChildren } from '../attributes/types'

import { getModuleAttributes, getModules, getModuleUsersCount } from './actions'
import { modulesNames, ModuleUsersCount } from './types'

const modulesInitialState: {
  list: (ModuleUsersCount & { attributeValues: AttributeValue[] })[]
  attributeValuesMap: { [x: string]: AttributeValue[] }
  total: number
  pending: boolean
} = {
  list: [],
  attributeValuesMap: {},
  total: 0,
  pending: false,
}

const modules = createSlice({
  name: 'modules',
  initialState: modulesInitialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getModuleUsersCount.fulfilled, (state, { payload }) => ({
        ...state,
        list: payload.map(m => ({
          ...m,
          name: modulesNames[m.module],
          attributeValues: state.attributeValuesMap[m.module],
        })),
        total: payload.length,
        pending: false,
      }))
      .addCase(getModuleUsersCount.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getModuleUsersCount.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getModules.fulfilled, (state, { payload }) => ({
        ...state,
        attributeValuesMap: payload.reduce(
          (acc: { [x: string]: AttributeValue[] }, m) => ({
            ...acc,
            [m.id]: m.attributeValues,
          }),
          {},
        ),
      }))
  },
})

export const modulesReducer = modules.reducer

const initialCurrentModuleAttributesState: {
  list: AttributeWithChildren[]
  pending: boolean
} = {
  list: [],
  pending: false,
}

const currentModuleAttributes = createSlice({
  name: 'currentModuleAttributes',
  initialState: initialCurrentModuleAttributesState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getModuleAttributes.fulfilled, (_, { payload }) => ({
        list: payload,
        pending: false,
      }))
      .addCase(getModuleAttributes.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getModuleAttributes.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const currentModuleAttributesReducer = currentModuleAttributes.reducer
