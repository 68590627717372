import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '../store'

export const getError403Selector = (state: RootState): RootState['error403'] =>
  state.error403

export const getError403 = createSelector(
  getError403Selector,
  state => state.enabled,
)
