import { ActionPopupWrapper } from '@gmini/ui-kit'

import { useForm, Controller } from 'react-hook-form'

import { Dispatch, SetStateAction, useEffect } from 'react'

import { useAppDispatch } from '../../store/store'

import { getRoles, updateRole } from '../../store/roles/actions'
import { Role } from '../../store/roles/types'

import { formatPermissionsMapForUpdateRequest } from '../../store/roles/utils'

import {
  EmptyContainer,
  FieldContainer,
  FieldError,
  FieldLabel,
  Form,
  StyledTextArea,
  TextField,
} from './AddRolePopup.styled'

type EditRolePopupProps = {
  role: Role
  open: boolean
  onClose: () => void
  setSelectedRole: Dispatch<SetStateAction<Role | null>>
}
const requiredErrorMessage = 'Это поле является обязательным'

const popupButtonsDataTestIds = {
  acceptButton: 'editRolePopupConfirmBtn',
  declineButton: 'editRolePopupCancelBtn',
}

export const EditRolePopup = ({
  role,
  open,
  onClose,
  setSelectedRole,
}: EditRolePopupProps) => {
  const defaultValues = {
    title: role.title,
    description: role.description || '',
  }

  const {
    handleSubmit,
    control,
    formState: { dirtyFields, errors },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })
  const dispatch = useAppDispatch()

  useEffect(
    () => reset({ ...role, description: role.description || '' }),
    [reset, role],
  )

  const onSubmit = async (data: typeof defaultValues) => {
    const { title: titleInit, description: descriptionInit } = data
    const title = titleInit.trim()
    const description = descriptionInit.trim()
    const newRole = await dispatch(
      updateRole({
        id: role.id,
        title,
        description,
        permissions: formatPermissionsMapForUpdateRequest(role.resources || {}),
      }),
    ).unwrap()
    setSelectedRole(newRole)
    dispatch(getRoles({ projectUrn: '' }))
    onClose()
  }

  return (
    <ActionPopupWrapper
      title={
        <span data-test-id='editRolePopupHeading'>Редактировать роль</span>
      }
      submitButtonTitle='Принять'
      pending={!Object.values(dirtyFields).some(f => f)}
      open={open}
      width='600px'
      onClose={() => {
        reset()
        onClose()
      }}
      onSubmit={handleSubmit(onSubmit)}
      dataTestIdFor={popupButtonsDataTestIds}
    >
      {({ existScroll }) => (
        <Form>
          <FieldContainer>
            <FieldLabel required>Наименование</FieldLabel>
            <Controller
              name='title'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
                maxLength: {
                  message:
                    'Наименование роли слишком длинное. Нужно меньше 64 знаков, включая пробелы. Пожалуйста, переформулируйте короче.',
                  value: 64,
                },
              }}
              render={({ field }) => (
                <TextField
                  data-test-id='editRolePopupNameInput'
                  {...field}
                  error={Boolean(errors.title)}
                  clearable
                />
              )}
            />
            <FieldError hidden={!errors.title}>
              {errors.title && 'message' in errors.title
                ? errors.title.message
                : null}
            </FieldError>
          </FieldContainer>
          <FieldContainer>
            <FieldLabel>Описание</FieldLabel>
            <Controller
              name='description'
              control={control}
              rules={{
                maxLength: {
                  message:
                    'Описание роли слишком длинное. Нужно меньше 300 знаков, включая пробелы. Пожалуйста, переформулируйте короче.',
                  value: 300,
                },
              }}
              render={({ field }) => (
                <StyledTextArea
                  data-test-id='editRolePopupDescriptionInput'
                  {...field}
                  error={Boolean(errors.description)}
                  placeholder='Краткое описание роли'
                />
              )}
            />
            <FieldError hidden={!errors.description}>
              {errors.description && 'message' in errors.description
                ? errors.description.message
                : null}
            </FieldError>
          </FieldContainer>
          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}
