import { IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'
export const UserListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
  clip-path: polygon(0 0, 100 0, 100 300, 0 300);
`

export const ListHeading = styled.div`
  padding: 32px 24px 24px;
`

export const BackButton = styled(IconButton)`
  width: 24px;
  height: 24px;
  path {
    fill: #4c5ecf;
  }
`
