import { HTMLAttributes } from 'react'

import { StarInfoIcon } from '../icons/StarInfoIcon'
import { InfoIconRed } from '../icons/InfoIconRed'

import {
  InfoPanel,
  InfoPanelTitle,
  InfoText,
  SpecialInfoText,
} from './AttributesInfo.styled'

export const AttributesInfo = (props: HTMLAttributes<HTMLDivElement>) => (
  <InfoPanel {...props}>
    <InfoPanelTitle>
      <InfoIconRed color='rgba(245, 166, 35, 1)' />
      <span data-test-id='attributeTabHintHeading'>Кастомные атрибуты</span>
    </InfoPanelTitle>
    <InfoText>
      Кастомный атрибут – справочник терминов, объединённых общим смыслом.
      Значения из справочника доступны для добавления на сущность строительного
      проекта (например, на согласование). По значениям кастомного атрибута
      можно удобно производить поиск.
    </InfoText>
    <InfoText>
      Значения кастомных атрибутов можно группировать, добавляя их в созданную в
      справочнике группу. Их так же можно убирать из группировки.
    </InfoText>
    <InfoText>
      Созданные кастомные атрибуты, их значения и группы значений доступны на
      всех проектах окружения.
    </InfoText>
    <InfoText>
      Кастомный атрибут можно привязать или отвязать от выбранных проектов и
      модулей, чтобы использовать только актуальные для проектов и модулей
      атрибуты.
    </InfoText>
    <SpecialInfoText>
      <StarInfoIcon />
      <span>
        Созданные значения атрибутов и группировки располагаются в алфавитном
        порядке (то как вы настроите в админке, то же будет и в модулях).
      </span>
    </SpecialInfoText>
  </InfoPanel>
)
