import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Popup, TextField } from '@gmini/ui-kit'

import { useAppDispatch } from '../../store/store'

import { Attribute } from '../../store/attributes/types'
import { addAttribute, updateAttribute } from '../../store/attributes/actions'

import {
  Button,
  Container,
  Footer,
  Header,
  PopupContent,
  StyledButton,
  Title,
} from './EditOrAddAttributeModal.styled'

type EditOrAddAttributeModalProps = {
  open: boolean
  attribute?: Attribute
  onClose: () => void
  setSelected?: Dispatch<SetStateAction<Attribute | null>>
}

export const EditOrAddAttributeModal = ({
  open,
  attribute,
  onClose,
  setSelected,
}: EditOrAddAttributeModalProps) => {
  const [name, setName] = useState(attribute?.name || '')
  const dispatch = useAppDispatch()
  useEffect(() => {
    setName(attribute?.name || '')
  }, [open, attribute?.name])
  const handleSubmit = async () => {
    const res = await dispatch(
      attribute
        ? updateAttribute({ attributeId: attribute.id, name, description: '' })
        : addAttribute({ name, description: '' }),
    ).unwrap()
    setSelected?.(res)
    onClose()
  }
  return (
    <Popup width={'450px'} open={open} onClose={onClose}>
      <Container>
        <Header>
          <Title data-test-id='editAttributePopupHeading'>
            {attribute ? 'Редактировать атрибут' : 'Добавить атрибут'}
          </Title>
        </Header>
        <PopupContent>
          <TextField
            data-test-id='editAttributePopupInput'
            placeholder='Наименование атрибута'
            value={name}
            onChange={setName}
          />
        </PopupContent>
        <Footer>
          <Button data-test-id='editAttributePopupCancelBtn' onClick={onClose}>
            Отменить
          </Button>

          <StyledButton
            data-test-id='editAttributePopupConfirmBtn'
            disabled={!name.trim()}
            onClick={handleSubmit}
          >
            {attribute ? 'Применить' : 'Добавить'}
          </StyledButton>
        </Footer>
      </Container>
    </Popup>
  )
}
