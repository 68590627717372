import React, { SVGAttributes } from 'react'

type SvgComponentType = SVGAttributes<SVGSVGElement> & {
  color?: string
}

export const FolderBigIcon = ({
  color = '#353B60',
  width = '24',
  height = '24',
  ...other
}: SvgComponentType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ flexShrink: 0 }}
    {...other}
  >
    <path
      d='M19.25 6.75H12.25L10.7929 5.29289C10.6054 5.10536 10.351 5 10.0858 5H5.25C4.27875 5 3.50875 5.77875 3.50875 6.75L3.5 17.25C3.5 18.2212 4.27875 19 5.25 19H19.25C20.2213 19 21 18.2212 21 17.25V8.5C21 7.52875 20.2213 6.75 19.25 6.75Z'
      fill={color}
    />
  </svg>
)
