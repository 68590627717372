import { Checkbox, Cross, CrossCircle } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

export const CheckboxStyled = styled(Checkbox).attrs({
  colors: {
    checked: '#4c5ecf',
    hoverColor: '#4c5ecf',
    indeterminate: '#4c5ecf',
    unChecked: '#4c5ecf',
    checkedDisabled: '#4c5ecf',
    indeterminateDisabled: '#4c5ecf',
    unCheckedDisabled: '#4c5ecf',
  },
})`
  pointer-events: none;
`

export const Option = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
  background: transparent;

  padding: 9.5px 12px;
  color: #353b60;
  cursor: 'pointer';
  user-select: none;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-size: 14px;

  & svg {
    flex-shrink: 0;
  }

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
  }

  &:hover {
    background: rgba(244, 244, 248, 1);
    cursor: pointer;
  }
`
export const Wrapper = styled.div`
  position: relative;
  & ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  & ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: #e5e7f1;
  }
`

type InputProps = {
  active: boolean
  error: boolean
}

export const Input = styled.div`
  min-height: 42px;
  max-height: 180px;
  overflow: auto;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  border-radius: 4px;
  background: rgba(244, 244, 248, 1);
  border: 1px solid transparent;
  transition: all ease-in-out 0.3s;
  ${({ active }: InputProps) =>
    active
      ? css`
          border-color: rgba(76, 94, 207, 1);
          background: #fff;
          box-shadow: 0px 5px 10px 0px rgba(53, 60, 96, 0.1);
        `
      : ''}
  ${({ error }: InputProps) =>
    error
      ? css`
          border-color: #d9575e;
        `
      : ''}
`

export const Menu = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 4px;
  max-height: 280px;
  overflow: auto;
`
export const Name = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.13px;
  color: rgba(53, 59, 96, 1);
  margin-bottom: 4.5px;
  margin-top: 3px;
`
export const Description = styled.div`
  font-family: 'Roboto';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: rgba(53, 59, 96, 0.7);
`

export const OptionTextWrapper = styled.div`
  align-self: center;
`
export const CrossStyled = styled(Cross)`
  width: 14px;
  height: 14px;
  path {
    stroke: rgba(255, 255, 255, 0.5);
  }
  &:hover {
    cursor: pointer;
    path {
      stroke: rgba(255, 255, 255, 1);
    }
  }
`

export const TextInputWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
`

export const TextInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(53, 59, 96, 1);
  flex-grow: 1;
  ::placeholder {
    color: rgba(53, 59, 96, 0.4);
  }
`

export const CrossCircleButton = styled(CrossCircle)`
  cursor: pointer;
  &:hover {
    path {
      transition: ease-in-out 0.2s;
      fill: #353b60;
    }
  }
`
