import { Dispatch, SetStateAction, useState } from 'react'

import { PenOutlined, PlusCircle, Trash } from '@gmini/ui-kit'

import { User } from '../../store/users/types'

import { EditProjectRolesPopup } from '../EditProjectRolesPopup/EditProjectRolesPopup'

import { CompaniesIcon } from '../icons/CompaniesIcon'

import { DeletePopup } from '../DeletePopup/DeletePopup'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { deleteUserRoles, getUserById } from '../../store/users/actions'
import { Text, Title } from '../DeletePopup/DeletePopup.styled'

import {
  ActionsMenu,
  ActionsMenuItem,
  ActionsWrapper,
  Button,
  Divider,
  Heading,
  HeadingWrapper,
  ProjectName,
  ProjectRolesContainer,
  ProjectWrapper,
  RoleBlueBadge,
  RolesWrapper,
  UserRolesWrapper,
  VerticalThreeDotsStyled,
} from './UserRolesDrawerTab.styled'

type UserRolesDrawerTabProps = {
  user: User
  inProject?: boolean
  setUserState: Dispatch<SetStateAction<User | null>>
}

export const UserRolesDrawerTab = ({
  user,
  inProject = false,
  setUserState,
}: UserRolesDrawerTabProps) => {
  const [projectUrn, setProjectUrn] = useState('')
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const { setByUrn } = useAppSelector(state => state.projects)
  const dispatch = useAppDispatch()

  const { userRoles: roles, id } = user

  const handleSubmitDelete = async () => {
    const roleIds =
      roles.find(p => p.projectUrn === projectUrn)?.roles.map(r => r.id) || []
    await dispatch(
      deleteUserRoles({
        id,
        roleIds,
        projectUrn,
        name: setByUrn[projectUrn]?.name || '',
        toastrText: 'Проект удален',
      }),
    )
    const newUser = await dispatch(getUserById({ id, projectUrn })).unwrap()
    if (inProject) {
      setUserState(null)
    } else {
      setUserState(newUser)
    }
    setIsDeleteOpen(s => !s)
  }

  return (
    <>
      {inProject ? null : (
        <HeadingWrapper>
          <Heading data-test-id='userRolesHeading'>Проекты</Heading>
          <Button
            data-test-id='addProjectRolesBtn'
            onClick={() => {
              setProjectUrn('')
              setIsEditOpen(true)
            }}
          >
            <PlusCircle color='rgba(76, 94, 207, 1)' />
            <span>Добавить проект</span>
          </Button>
        </HeadingWrapper>
      )}
      <UserRolesWrapper>
        {roles.map((r, i) => (
          <ProjectRolesContainer key={r.projectUrn}>
            {i > 0 ? <Divider /> : null}
            <ProjectWrapper>
              <CompaniesIcon width={32} height={32} />
              <ProjectName>
                {r.projectName || setByUrn[r.projectUrn]?.name || r.projectUrn}
              </ProjectName>
              <ActionsWrapper>
                <VerticalThreeDotsStyled data-test-id='actionsProjectRolesBtn' />
                <ActionsMenu>
                  <ActionsMenuItem
                    data-test-id='deleteProjectRolesBtn'
                    onClick={() => {
                      setProjectUrn(r.projectUrn)
                      setIsDeleteOpen(true)
                    }}
                  >
                    <Trash color='rgba(53, 59, 96, 1)' />
                    <span>Удалить пользователя из проекта</span>
                  </ActionsMenuItem>
                </ActionsMenu>
              </ActionsWrapper>
            </ProjectWrapper>
            <RolesWrapper>
              {r.roles.map(role => (
                <RoleBlueBadge key={role.id}>{role.title}</RoleBlueBadge>
              ))}
              <Button
                data-test-id='editProjectRolesBtn'
                onClick={() => {
                  setProjectUrn(r.projectUrn)
                  setIsEditOpen(true)
                }}
              >
                <PenOutlined color='rgba(76, 94, 207, 1)' />
                <span>Редактировать роли</span>
              </Button>
            </RolesWrapper>
          </ProjectRolesContainer>
        ))}
        <EditProjectRolesPopup
          open={isEditOpen}
          onClose={() => setIsEditOpen(s => !s)}
          initProjectUrn={projectUrn}
          user={user}
          setUserState={setUserState}
        />
        <DeletePopup
          title={<Title>{'Удалить пользователя из проекта'}</Title>}
          dataTestIdPrefix='ProjectRoles'
          open={isDeleteOpen}
          onClose={() => setIsDeleteOpen(s => !s)}
          handleSubmit={handleSubmitDelete}
        >
          <Text>
            Вы действительно хотите удалить пользователя из проекта{' '}
            <span>“{setByUrn[projectUrn]?.name}”?</span>
          </Text>
        </DeletePopup>
      </UserRolesWrapper>
    </>
  )
}
