import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`
export const ModulesListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
`
export const TitleCount = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(53, 59, 96, 0.5);
`
export const ListHeading = styled.div`
  padding: 33px 24px;
`
