import { Select } from '@gmini/ui-kit'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useParams } from 'react-router'

import { Company } from '../../store/companies/types'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getUserById, updateUser } from '../../store/users/actions'

import { User } from '../../store/users/types'

import {
  FieldError,
  FieldLabel,
  TextField,
} from '../AddProjectPopup/AddProjectPopup.styled'
import { RoleChangesPopup } from '../RoleChangesPopup/RoleChangesPopup'
import { ChangesPopupContent } from '../RoleProfile/RoleProfile.styled'

import {
  Form,
  FieldContainer,
  UserDetailsWrapper,
  ActionButton,
  ActionButtons,
} from './UserDetailsDrawerTab.styled'

const requiredErrorMessage = 'Это поле является обязательным'

type UserDetailsDrawerTabProps = {
  user: User
  isUnsavedChangesPopupOpen: boolean
  setUnsavedUserChanges: Dispatch<SetStateAction<boolean>>
  setIsUnsavedChangesPopupOpen: Dispatch<SetStateAction<boolean>>
  setUserState: Dispatch<SetStateAction<User | null>>
}

export const UserDetailsDrawerTab = ({
  user,
  isUnsavedChangesPopupOpen,
  setUnsavedUserChanges,
  setIsUnsavedChangesPopupOpen,
  setUserState,
}: UserDetailsDrawerTabProps) => {
  const params = useParams<{
    hubId: string
    projectUrn: string
    companyId: string
  }>()
  const projectUrn = params.projectUrn || 'default'
  const { companyId } = params
  const { list: companies } = useAppSelector(state => state.companies)

  const dispatch = useAppDispatch()
  const defaultValues = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email,
    phone: user.phone || '',
    company: companies.find(c => c.id === user.companyId) || null,
  }
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
    getValues,
  } = useForm({ mode: 'onChange', defaultValues })

  useEffect(() => {
    setUnsavedUserChanges(isDirty)
  }, [isDirty, setUnsavedUserChanges])

  const onSubmit = useCallback(
    async (data: typeof defaultValues) => {
      const { email, firstName, lastName, phone, company } = data
      if (!email) {
        return
      }
      await dispatch(
        updateUser({
          id: user.id,
          email,
          firstName,
          lastName,
          phone,
          companyId: company?.id || null,
        }),
      )
      setUnsavedUserChanges(false)
      const newUser = await dispatch(
        getUserById({ id: user.id, projectUrn, companyId }),
      ).unwrap()
      setUserState(newUser)
    },
    [
      user.id,
      dispatch,
      setUnsavedUserChanges,
      projectUrn,
      companyId,
      setUserState,
    ],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(defaultValues), [user])

  if (!user) {
    return null
  }
  return (
    <UserDetailsWrapper>
      <Form>
        <FieldContainer>
          <FieldLabel required>Имя</FieldLabel>
          <Controller
            name='firstName'
            control={control}
            rules={{
              required: { message: requiredErrorMessage, value: true },
            }}
            render={({ field }) => (
              <TextField
                data-test-id='detailsFirstNameInput'
                {...field}
                error={Boolean(errors.firstName)}
                clearable
              />
            )}
          />
          <FieldError hidden={!errors.firstName}>
            {errors.firstName && 'message' in errors.firstName
              ? errors.firstName.message
              : null}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel required>Фамилия</FieldLabel>
          <Controller
            name='lastName'
            control={control}
            rules={{
              required: { message: requiredErrorMessage, value: true },
            }}
            render={({ field }) => (
              <TextField
                data-test-id='detailsLastNameInput'
                {...field}
                error={Boolean(errors.lastName)}
                clearable
              />
            )}
          />
          <FieldError hidden={!errors.lastName}>
            {errors.lastName && 'message' in errors.lastName
              ? errors.lastName.message
              : null}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel required>Email</FieldLabel>
          <Controller
            name='email'
            control={control}
            rules={{
              required: { message: requiredErrorMessage, value: true },
            }}
            render={({ field }) => (
              <TextField
                data-test-id='detailsEmailInput'
                {...field}
                error={Boolean(errors.email)}
                //оставил, чтобы не забыть проп, когда поля можно будет редактировать
                // clearable
                disabled
              />
            )}
          />
          <FieldError hidden={!errors.email}>
            {errors.email && 'message' in errors.email
              ? errors.email.message
              : null}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Телефон</FieldLabel>
          <Controller
            name='phone'
            control={control}
            rules={{
              pattern: {
                value:
                  // eslint-disable-next-line prefer-named-capture-group
                  /(^\+?\d{1,3})[ -]?(\d{3})[ -]?(\d{3})[ -]?(\d{2})[ -]?(\d{2})[ -]?$/mu,
                message: 'Неверный формат',
              },
            }}
            render={({ field }) => (
              <TextField
                data-test-id='detailsPhoneInput'
                {...field}
                error={Boolean(errors.phone)}
                clearable
              />
            )}
          />
          <FieldError hidden={!errors.phone}>
            {errors.phone && 'message' in errors.phone
              ? errors.phone.message
              : null}
          </FieldError>
        </FieldContainer>
        <FieldContainer>
          <FieldLabel>Компания</FieldLabel>
          <Controller
            name='company'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                dataTestIdFor={{ input: 'detailsCompanyInput' }}
                optionDataTestIdPrefix='detailsCompanyOption'
                options={companies}
                getOptionLabel={(option: Company) => option.companyName}
                emptyOptionListMessage='Нет доступных совпадений'
                placeholder='Укажите компанию'
                error={Boolean(errors.company)}
              />
            )}
          />
          <FieldError hidden={!errors.company}>
            {errors.company && 'message' in errors.company
              ? errors.company.message
              : null}
          </FieldError>
        </FieldContainer>
      </Form>
      <ActionButtons>
        <ActionButton
          data-test-id='detailsCancelBtn'
          onClick={() => reset(defaultValues)}
          color='secondary'
        >
          Отменить
        </ActionButton>
        <ActionButton
          data-test-id='detailsConfirmBtn'
          color='primary'
          disabled={!getValues().email.trim()}
          onClick={handleSubmit(onSubmit)}
        >
          Применить
        </ActionButton>
      </ActionButtons>
      <RoleChangesPopup
        title='Изменения не сохранены'
        submitButtonTitle='Сохранить изменения'
        open={isUnsavedChangesPopupOpen}
        width='600px'
        onClose={() => {
          setIsUnsavedChangesPopupOpen(false)
        }}
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => reset(defaultValues)}
      >
        {() => (
          <ChangesPopupContent>
            У вас остались несохраненные изменения в профиле. Выберите требуемое
            действие.
          </ChangesPopupContent>
        )}
      </RoleChangesPopup>
    </UserDetailsWrapper>
  )
}
