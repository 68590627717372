import styled from 'styled-components'

export const AttributeValuesTableCellWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  && button {
    opacity: 1;
  }
`

export const AttributeValuesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  overflow-x: hidden;
`

export const StyledAttributeValue = styled.div`
  white-space: nowrap;
  border-radius: 4px;
  background: rgba(235, 235, 239, 1);
  padding: 3.5px 4px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
`

export const AttributeValuesPopup = styled.div`
  cursor: auto;
  position: absolute;
  top: calc(100% + 3px);
  box-shadow: 0px 4px 40px 0px rgba(53, 59, 96, 0.5);
  border-radius: 4px;
  background: #fff;
  padding: 10px;
  padding-left: 12px;
  z-index: 1;
`

export const AttributeValuesPopupHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  letter-spacing: 0.15px;
  color: rgba(128, 129, 154, 1);
  white-space: nowrap;

  & svg {
    cursor: pointer;
    flex-shrink: 0;
  }
`

export const AttributeValuesPopupContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`
export const AttributeValuesPopupLink = styled.div`
  display: flex;
  cursor: pointer;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  letter-spacing: 0.15px;
  color: rgba(76, 94, 207, 1);
  width: max-content;
`
