import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'
import { apiClient } from '../../services'

const errorMessages: Record<string, string> = {
  'attribute value with current name is already exists':
    'Ошибка: значение атрибута с таким именем уже существует',
  'attribute with current name is already exists':
    'Ошибка: атрибут с таким именем уже существует',
  'attribute group with current name is already exists':
    'Ошибка: группа значений атрибута с таким именем уже существует',
}

import { getErrorFromOKResponse } from '../../services/utils'

import {
  Attribute,
  AttributeGroup,
  AttributeGroupWithChildren,
  AttributeInstances,
  AttributeValue,
  AttributeWithChildren,
  ProjectModuleAttributeValue,
} from './types'

export const getAttributes = createAsyncThunk<
  Attribute[],
  { archived?: boolean }
>('attributes/getAttributes', async ({ archived }, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<Attribute[]>({
    baseUrl: '/api/hub-management/v1',
    path: `/attributes`,
    params: { archived },
  })

  if (err || ('status' in res.data && res.data.status !== 'success')) {
    throw rejectWithValue(
      err || getErrorFromOKResponse(res, 'Ошибка получения атрибутов: '),
    )
  }

  const data: Attribute[] = get(res, 'data.data', [])

  return data
})

type AddOrEditAttributeReqBody = Pick<Attribute, 'name' | 'description'>

export const addAttribute = createAsyncThunk<
  Attribute,
  AddOrEditAttributeReqBody
>(
  'attributes/addAttribute',
  async ({ name, description }, { dispatch, rejectWithValue }) => {
    const [err, res] = await apiClient.post<
      AddOrEditAttributeReqBody,
      Attribute
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes`,
      body: {
        name,
        description,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      if (err) {
        toastr.info('', '', {
          icon: <div />,
          component: (
            <SnackbarWrapper>{`${errorMessages[err.message]}`}</SnackbarWrapper>
          ),
        })
        throw rejectWithValue(
          err || getErrorFromOKResponse(res, 'Ошибка создания атрибута: '),
        )
      }
    }
    dispatch(getAttributes({}))
    const data: Attribute = get(res, 'data.data', {
      id: 0,
      name: '',
      description: '',
      deleted: true,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Атрибут "${name}" создан.`}</SnackbarWrapper>
      ),
    })

    return data
  },
)

export const getAttribute = createAsyncThunk<
  AttributeWithChildren,
  { attributeId: number; archived?: boolean }
>(
  'attributes/getAttribute',
  async ({ archived, attributeId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<AttributeWithChildren>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/populated`,
      params: { archived },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения атрибута: '),
      )
    }

    const data: AttributeWithChildren = get(res, 'data.data', {
      id: 0,
      name: '',
      description: '',
      deleted: true,
      children: [],
    })

    return data
  },
)

export const deleteAttribute = createAsyncThunk<
  void,
  { attributeId: number; name: string }
>(
  'attributes/deleteAttribute',
  async ({ attributeId, name }, { dispatch, rejectWithValue }) => {
    const [err, res] = await apiClient.delete<Record<string, unknown>>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}`,
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка удаления атрибута: '),
      )
    }

    dispatch(getAttributes({}))
    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Атрибут "${name}" удален.`}</SnackbarWrapper>
      ),
    })
  },
)

export const updateAttribute = createAsyncThunk<
  Attribute,
  AddOrEditAttributeReqBody & { attributeId: number }
>(
  'attributes/updateAttribute',
  async ({ name, description, attributeId }, { dispatch, rejectWithValue }) => {
    const [err, res] = await apiClient.put<
      AddOrEditAttributeReqBody,
      Attribute
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}`,
      body: {
        name,
        description,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      if (err) {
        toastr.info('', '', {
          icon: <div />,
          component: (
            <SnackbarWrapper>{`${errorMessages[err.message]}`}</SnackbarWrapper>
          ),
        })
      }
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка редактирования атрибута: '),
      )
    }
    dispatch(getAttributes({}))
    const data: Attribute = get(res, 'data.data', {
      id: 0,
      name: '',
      description: '',
      deleted: true,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Атрибут "${name}" отредактирован.`}</SnackbarWrapper>
      ),
    })

    return data
  },
)

type AddOrEditAttributeGroupReqBody = Pick<AttributeGroup, 'name'>

export const addAttributeGroup = createAsyncThunk<
  AttributeGroup,
  AddOrEditAttributeGroupReqBody & {
    attributeId: number
  }
>(
  'attributes/addAttributeGroup',
  async ({ name, attributeId }, { dispatch, rejectWithValue }) => {
    const [err, res] = await apiClient.post<
      AddOrEditAttributeGroupReqBody,
      AttributeGroup
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/group`,
      body: {
        name,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      if (err) {
        toastr.info('', '', {
          icon: <div />,
          component: (
            <SnackbarWrapper>{`${errorMessages[err.message]}`}</SnackbarWrapper>
          ),
        })
      }
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка создания группировки: '),
      )
    }

    dispatch(getAttribute({ attributeId }))
    const data: AttributeGroup = get(res, 'data.data', {
      id: 0,
      attributeId: 0,
      type: AttributeInstances.GROUP,
      name: '',
      total: 0,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Добавлена новая группировка в атрибут`}</SnackbarWrapper>
      ),
    })

    return data
  },
)

export const getAttributeGroup = createAsyncThunk<
  AttributeGroupWithChildren,
  {
    attributeId: number
    groupId: number
    archived?: boolean
  }
>(
  'attributes/getAttributeGroup',
  async ({ archived, attributeId, groupId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<AttributeGroupWithChildren>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/group/${groupId}`,
      params: { archived },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения группировки: '),
      )
    }

    const data: AttributeGroupWithChildren = get(res, 'data.data', {
      id: 0,
      attributeId: 0,
      type: AttributeInstances.GROUP,
      name: '',
      total: 0,
      children: [],
    })

    return data
  },
)

export const deleteAttributeGroup = createAsyncThunk<
  void,
  { attributeId: number; groupId: number; name: string }
>(
  'attributes/deleteAttributeGroup',
  async ({ attributeId, groupId, name }, { dispatch, rejectWithValue }) => {
    const [err, res] = await apiClient.delete<Record<string, unknown>>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/group/${groupId}`,
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка удаления группировки: '),
      )
    }
    dispatch(getAttribute({ attributeId }))
    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Группировка значений атрибутов “${name}” удалена.`}</SnackbarWrapper>
      ),
    })
  },
)

export const updateAttributeGroup = createAsyncThunk<
  AttributeGroup,
  AddOrEditAttributeGroupReqBody & {
    newAttributeId?: number
    attributeId: number
    groupId: number
  }
>(
  'attributes/updateAttributeGroup',
  async (
    { name, newAttributeId, attributeId, groupId },
    { dispatch, rejectWithValue },
  ) => {
    const [err, res] = await apiClient.put<
      AddOrEditAttributeGroupReqBody & { attributeId: number },
      AttributeGroup
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/group/${groupId}`,
      body: {
        name,
        attributeId: newAttributeId || attributeId,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(res, 'Ошибка редактирования группировки: '),
      )
    }
    dispatch(getAttribute({ attributeId }))
    const data: AttributeGroup = get(res, 'data.data', {
      id: 0,
      attributeId: 0,
      type: AttributeInstances.GROUP,
      name: '',
      total: 0,
    })

    return data
  },
)

type AddOrEditAttributeValueReqBody = Pick<AttributeValue, 'name'> &
  Partial<Pick<AttributeValue, 'parentGroupId'>>

export const addAttributeValue = createAsyncThunk<
  AttributeValue,
  AddOrEditAttributeValueReqBody & {
    attributeId: number
    noNotification?: boolean
  }
>(
  'attributes/addAttributeValue',
  async (
    { name, parentGroupId, attributeId, noNotification },
    { dispatch, rejectWithValue },
  ) => {
    const [err, res] = await apiClient.post<
      AddOrEditAttributeValueReqBody,
      AttributeValue
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/value`,
      body: parentGroupId
        ? {
            name,
            parentGroupId,
          }
        : { name },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      if (err) {
        toastr.info('', '', {
          icon: <div />,
          component: (
            <SnackbarWrapper>{`${errorMessages[err.message]}`}</SnackbarWrapper>
          ),
        })
      }
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(res, 'Ошибка создания значения атрибута: '),
      )
    }

    dispatch(getAttribute({ attributeId }))

    const data: AttributeValue = get(res, 'data.data', {
      id: 0,
      attributeId: 0,
      name: '',
      type: AttributeInstances.VALUE,
      parentGroupId: 0,
      deleted: false,
    })

    !noNotification &&
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>{`Добавлено новое значение атрибута`}</SnackbarWrapper>
        ),
      })

    return data
  },
)

export const getAttributeValue = createAsyncThunk<
  AttributeValue,
  {
    attributeId: number
    valueId: number
  }
>(
  'attributes/getAttributeValue',
  async ({ attributeId, valueId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<AttributeValue>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/value/${valueId}`,
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(res, 'Ошибка получения значения атрибута: '),
      )
    }

    const data: AttributeValue = get(res, 'data.data', {
      id: 0,
      attributeId: 0,
      name: '',
      type: AttributeInstances.VALUE,
      parentGroupId: 0,
      deleted: false,
    })

    return data
  },
)

export const deleteAttributeValue = createAsyncThunk<
  void,
  { attributeId: number; valueId: number; noAttributeFetch?: boolean }
>(
  'attributes/deleteAttributeValue',
  async (
    { attributeId, valueId, noAttributeFetch },
    { dispatch, rejectWithValue },
  ) => {
    const [err, res] = await apiClient.delete<Record<string, unknown>>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/value/${valueId}`,
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(res, 'Ошибка удаления значения атрибута: '),
      )
    }

    !noAttributeFetch && dispatch(getAttribute({ attributeId }))
  },
)

export const updateAttributeValue = createAsyncThunk<
  AttributeValue,
  AddOrEditAttributeValueReqBody & {
    newAttributeId?: number
    attributeId: number
    valueId: number
    notificationText?: string
    noAttributeFetch?: boolean
  }
>(
  'attributes/updateAttributeValue',
  async (
    {
      name,
      newAttributeId,
      attributeId,
      parentGroupId,
      valueId,
      notificationText,
      noAttributeFetch,
    },
    { dispatch, rejectWithValue },
  ) => {
    const [err, res] = await apiClient.put<
      AddOrEditAttributeValueReqBody & { attributeId: number },
      AttributeValue
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/value/${valueId}`,
      body: parentGroupId
        ? {
            name,
            attributeId: newAttributeId || attributeId,
            parentGroupId,
          }
        : {
            name,
            attributeId: newAttributeId || attributeId,
          },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      if (err) {
        toastr.info('', '', {
          icon: <div />,
          component: (
            <SnackbarWrapper>{`${errorMessages[err.message]}`}</SnackbarWrapper>
          ),
        })
      }
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(
            res,
            'Ошибка редактирования значения атрибута: ',
          ),
      )
    }

    !noAttributeFetch && dispatch(getAttribute({ attributeId }))

    const data: AttributeValue = get(res, 'data.data', {
      id: 0,
      attributeId: 0,
      name: '',
      type: AttributeInstances.VALUE,
      parentGroupId: 0,
      deleted: false,
    })

    if (notificationText) {
      toastr.info('', '', {
        icon: <div />,
        component: <SnackbarWrapper>{notificationText}</SnackbarWrapper>,
      })
    }

    return data
  },
)

export const getProjectModuleAttributes = createAsyncThunk<
  ProjectModuleAttributeValue[],
  {
    attributeId: number
  }
>(
  'attributes/getProjectModuleAttributes',
  async ({ attributeId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<ProjectModuleAttributeValue[]>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/project-module`,
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(
            res,
            'Ошибка получения значений атрибутов проекта: ',
          ),
      )
    }

    const data: ProjectModuleAttributeValue[] = get(res, 'data.data', [])

    return data
  },
)

type AttachAttributeValueToProjectReqBody = {
  itemValues: { projectUrn: string; moduleId: string; valueId: number }[]
}

export const attachAttributeValueToProject = createAsyncThunk<
  ProjectModuleAttributeValue[],
  AttachAttributeValueToProjectReqBody & {
    attributeId: number
    notificationText?: string
  }
>(
  'attributes/attachAttributeValueToProject',
  async (
    { itemValues, attributeId, notificationText },
    { rejectWithValue },
  ) => {
    const [err, res] = await apiClient.post<
      AttachAttributeValueToProjectReqBody,
      ProjectModuleAttributeValue[]
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/project-module/attach`,
      body: {
        itemValues,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(res, 'Ошибка привязки значений атрибута: '),
      )
    }

    const data: ProjectModuleAttributeValue[] = get(res, 'data.data', [])

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          {notificationText ? notificationText : `Атрибуты добавлены в проекты`}
        </SnackbarWrapper>
      ),
    })

    return data
  },
)

export const detachAttributeValueToProject = createAsyncThunk<
  ProjectModuleAttributeValue[],
  AttachAttributeValueToProjectReqBody & {
    attributeId: number
  }
>(
  'attributes/detachAttributeValueToProject',
  async ({ itemValues, attributeId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.post<
      AttachAttributeValueToProjectReqBody,
      ProjectModuleAttributeValue[]
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/${attributeId}/project-module/detach`,
      body: {
        itemValues,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(res, 'Ошибка отвязки значений атрибута: '),
      )
    }

    const data: ProjectModuleAttributeValue[] = get(res, 'data.data', [])

    toastr.info('', '', {
      icon: <div />,
      component: <SnackbarWrapper>{`Атрибуты отвязаны`}</SnackbarWrapper>,
    })

    return data
  },
)
