import {
  Button,
  DeleteFilled,
  IconButton,
  PlusCircle,
  Select,
} from '@gmini/ui-kit'
import { Controller, useForm } from 'react-hook-form'
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { useAppSelector } from '../../store/store'
import { Role } from '../../store/roles/types'
import { FieldLabel } from '../AddProjectPopup/AddProjectPopup.styled'

import {
  ButtonContainer,
  FieldContainer,
  RoleDescription,
  RoleField,
  RoleOption,
  RoleTitle,
  UserRolesWrapper,
} from './SelectMultipleRoles.styled'

type SelectMultipleRolesProps = {
  roleIds: (number | null)[]
  setRoleIds: Dispatch<SetStateAction<(number | null)[]>>
  initialRoleIds?: number[]
  unselectableIds?: number[]
  oneInARow?: boolean
}

const emptyArray: number[] = []

export const SelectMultipleRoles = ({
  roleIds,
  setRoleIds,
  initialRoleIds = emptyArray,
  unselectableIds = [],
  oneInARow = false,
}: SelectMultipleRolesProps) => {
  const { list: rolesList, setById: rolesSet } = useAppSelector(
    state => state.roles,
  )

  const unselectableIdsSet = [...roleIds, ...unselectableIds].reduce(
    (acc: { [x: number]: boolean }, id) => (id ? { ...acc, [id]: true } : acc),
    {},
  )

  const filteredRolesList = rolesList.filter(r => !unselectableIdsSet[r.id])

  const defaultValues = useMemo(
    () =>
      initialRoleIds.reduce(
        (
          acc: {
            [x: string]: Role | null
          },
          id,
          idx,
        ) => ({
          ...acc,
          [`role${idx}`]: rolesSet[id],
        }),
        {},
      ),
    [initialRoleIds, rolesSet],
  )

  const { control, setValue, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
  })

  useEffect(() => {
    Object.keys(getValues()).forEach((key, i) => {
      const id = roleIds[i]
      setValue(key, id ? rolesSet[id] : null)
    })
  }, [roleIds, getValues, setValue, rolesSet])

  const handleRemoveRole = useCallback(
    (idx: number) => {
      const newRoles = roleIds.filter((_, i) => i !== idx)
      setRoleIds(newRoles)
    },
    [roleIds, setRoleIds],
  )
  const hasRoleIds = roleIds.some(r => r)

  return (
    <UserRolesWrapper oneInARow={oneInARow}>
      {roleIds.map((id, idx) => (
        <FieldContainer oneInARow={oneInARow} key={id ? `role${id}` : idx}>
          <FieldLabel>Роль</FieldLabel>
          <Controller
            name={`role${idx}`}
            control={control}
            render={({ field }) => (
              <RoleField>
                <Select
                  {...field}
                  placeholder='Укажите роль'
                  error={!hasRoleIds}
                  options={filteredRolesList}
                  dataTestIdFor={{ input: `selectRoleInput_${idx}` }}
                  getOptionLabel={(option: Role) => option.title}
                  renderOption={({
                    idx: index,
                    option,
                    onClose,
                    active,
                    hover,
                    onMouseEnterOption,
                  }) => (
                    <RoleOption
                      data-test-id={`selectRoleInput_${idx}_option_${index}`}
                      key={index}
                      hover={hover}
                      active={active}
                      onClick={() => {
                        field.onChange(option)
                        const newRoleIds = roleIds.map((roleId, i) =>
                          i === idx ? option?.id || null : roleId,
                        )
                        setRoleIds(newRoleIds)
                        onClose()
                      }}
                      onMouseEnter={() => onMouseEnterOption(index)}
                    >
                      <RoleTitle>{option.title}</RoleTitle>
                      {option.description ? (
                        <RoleDescription>{option.description}</RoleDescription>
                      ) : null}
                    </RoleOption>
                  )}
                  emptyOptionListMessage='Нет доступных совпадений'
                  onChange={r => {
                    field.onChange(r)
                    const newRoleIds = roleIds.map((roleId, i) =>
                      i === idx ? r?.id || null : roleId,
                    )
                    setRoleIds(newRoleIds)
                  }}
                />
                {roleIds.length > 1 ? (
                  <IconButton
                    onClick={() => {
                      field.onChange(null)
                      handleRemoveRole(idx)
                    }}
                    data-test-id='removeRoleBtn'
                  >
                    <DeleteFilled opacity='0.5' />
                  </IconButton>
                ) : null}
              </RoleField>
            )}
          />
        </FieldContainer>
      ))}
      <ButtonContainer oneInARow={oneInARow}>
        <Button
          onClick={() => setRoleIds([...roleIds, null])}
          leftIcon={<PlusCircle />}
          color='tertiary'
          disabled={!filteredRolesList.length || roleIds.includes(null)}
          data-test-id='addRoleBtn'
        >
          Добавить роль
        </Button>
      </ButtonContainer>
    </UserRolesWrapper>
  )
}
