import { useEffect, useMemo, useState } from 'react'
import {
  AllFolders,
  Checkbox,
  IconButton,
  Popup,
  RoundedCross,
  Button as UiKitButton,
} from '@gmini/ui-kit'

import { toastr } from 'react-redux-toastr'

import {
  ModuleAttributeValue,
  ProjectModuleAttributeValue,
} from '../../store/attributes/types'

import {
  attachAttributeValueToProject,
  getAttribute,
} from '../../store/attributes/actions'

import { useAppDispatch, useAppSelector } from '../../store/store'

import { DashboardIcon } from '../icons/DashboardIcon'

import {
  projectModuleSplitter,
  projectModuleValueSplitter,
} from '../../store/attributes/attributes-slice'

import { ExpandButton } from '../AttributeTab/AttributeValueGroupItem.styled'

import { ArrowExpandMore } from '../icons/ArrowExpandMore'

import { SnackbarWrapper } from '../toastrOptions/ToastrSnackbar.styled'

import {
  AttributeValuesContainer,
  Button,
  Container,
  Footer,
  Header,
  ListItemName,
  PopupContent,
  ProjectListItem,
  StyledCircularProgress,
  StyledValueName,
  Text,
  Title,
} from './AddAttributeValuesToProjectAfterActionModal.styled'

type AddAttributeValuesToProjectAfterActionModalProps = {
  data: {
    action: (params?: Record<string, unknown>) => Promise<unknown>
    groupId: number
    actionType: 'create' | 'move'
  }
  onClose: () => void
  checkedAttributeValuesIdsList: string[]
}

export const AddAttributeValuesToProjectAfterActionModal = ({
  data,
  onClose,
  checkedAttributeValuesIdsList,
}: AddAttributeValuesToProjectAfterActionModalProps) => {
  const { action, groupId, actionType } = data

  const [isCloseLoading, setIsCloseLoading] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [expandedMap, setExpandedMap] = useState<{ [x: string]: boolean }>({})
  const [checkedMap, setCheckedMap] = useState<{ [x: string]: boolean }>({})
  const {
    childrenFlatMap,
    projectModuleValues,
    projectModuleValuesMap,
    id: attributeId,
  } = useAppSelector(state => state.currentAttribute)

  const projectModulesContainingGroupMap = useMemo(
    () =>
      Object.keys(projectModuleValuesMap)
        .filter(
          key =>
            key.includes(projectModuleValueSplitter) &&
            childrenFlatMap[Number(key.split(projectModuleValueSplitter)[1])]
              ?.parentGroupId === groupId,
        )
        .reduce(
          (
            acc: { [x: string]: { [x: string]: boolean } },
            projectModuleKey,
          ) => {
            const [projectUrn, moduleId] = projectModuleKey.split(
              projectModuleSplitter,
            )
            return {
              ...acc,
              [projectUrn]: { ...(acc[projectUrn] || {}), [moduleId]: true },
            }
          },
          {},
        ),
    [projectModuleValuesMap, groupId, childrenFlatMap],
  )
  useEffect(() => {
    setCheckedMap(
      Object.entries(projectModulesContainingGroupMap).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...Object.keys(value).reduce(
            (acc, moduleKey) => ({
              ...acc,
              [`${key}${projectModuleSplitter}${moduleKey}`]: true,
            }),
            {},
          ),
        }),
        {},
      ),
    )
  }, [projectModulesContainingGroupMap])
  const projectModuleValuesFlatMap = useMemo(
    () =>
      projectModuleValues
        .reduce(
          (
            acc: ((ProjectModuleAttributeValue | ModuleAttributeValue) & {
              key: string
            })[],
            p,
          ) => [
            ...acc,
            { key: p.urn, ...p },
            ...p.modules.map(m => ({ key: m.id, ...m })),
          ],
          [],
        )
        .reduce(
          (
            acc: {
              [x: string]: (
                | ProjectModuleAttributeValue
                | ModuleAttributeValue
              ) & {
                key: string
              }
            },
            item,
          ) => ({ ...acc, [item.key]: item }),
          {},
        ),
    [projectModuleValues],
  )
  const dispatch = useAppDispatch()

  const handleClose = async () => {
    setIsCloseLoading(true)
    await action()
    actionType === 'move' && (await dispatch(getAttribute({ attributeId })))
    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>
          {actionType === 'move'
            ? 'Значения атрибута перемещены в группу'
            : 'Добавлено новое значение атрибута'}
        </SnackbarWrapper>
      ),
    })
    onClose()
    setIsCloseLoading(false)
  }

  const handleSubmit = async () => {
    setIsSubmitLoading(true)
    const value = await action()
    const createdValueId =
      typeof value === 'object' &&
      value !== null &&
      'payload' in value &&
      typeof value.payload === 'object' &&
      value.payload !== null &&
      'id' in value.payload &&
      typeof value.payload.id === 'number'
        ? value.payload.id
        : null
    actionType === 'move' && (await dispatch(getAttribute({ attributeId })))
    await dispatch(
      attachAttributeValueToProject({
        attributeId,
        itemValues: Object.keys(checkedMap)
          .filter(key => checkedMap[key] && key.includes(projectModuleSplitter))
          .map(key => {
            const [projectUrn, moduleId] = key.split(projectModuleSplitter)
            return {
              projectUrn,
              moduleId,
            }
          })
          .reduce(
            (
              acc: { projectUrn: string; moduleId: string; valueId: number }[],
              m,
            ) => [
              ...acc,
              ...(createdValueId
                ? [{ ...m, valueId: createdValueId }]
                : checkedAttributeValuesIdsList.map(valueId => ({
                    ...m,
                    valueId: Number(valueId),
                  }))),
            ],
            [],
          ),
        notificationText:
          actionType === 'move'
            ? 'Значения атрибута перемещены в группу и добавлены в проекты'
            : 'Новое значение атрибута добавлено в проекты',
      }),
    )
    setIsSubmitLoading(false)
    onClose()
  }

  const checkedMapLength = useMemo(
    () => Object.values(checkedMap).filter(Boolean).length,
    [checkedMap],
  )

  return (
    // Не закрывается по кликауту, чтобы избежать случайного закрытия на важном этапе
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Popup width={'640px'} open={Boolean(groupId)} onClose={() => {}}>
      <Container>
        <Header>
          <Title data-test-id='addAttributeValuesToProjectAfterActionModalHeading'>
            Добавление атрибутов в проекты
          </Title>
          <IconButton type='square'>
            <RoundedCross
              data-test-id='closeAddAttributeValuesToProjectAfterActionModal'
              onClick={() => handleClose()}
            />
          </IconButton>
        </Header>
        <AttributeValuesContainer>
          {checkedAttributeValuesIdsList.map(id => (
            <StyledValueName key={id}>
              {childrenFlatMap[Number(id)].name}
            </StyledValueName>
          ))}
        </AttributeValuesContainer>
        <Text>
          Выберите проекты, где представлена группа, в которую вы переносите
          значения:
        </Text>
        <PopupContent>
          {Object.entries(projectModulesContainingGroupMap).map(
            ([p, children], idx) => {
              const relatableToProjectKeys = Object.keys(checkedMap).filter(
                key => key.includes(p) && key !== p,
              )
              const isChecked = relatableToProjectKeys.every(
                key => checkedMap[key],
              )
              const isCheckedIncomplete = relatableToProjectKeys.some(
                key => checkedMap[key],
              )
              return (
                <ProjectListItem key={p}>
                  <ListItemName>
                    <ExpandButton
                      data-test-id={`addAttributeValuesToProjectAfterActionModal_ListItem_${idx}_ExpandBtn`}
                      expanded={expandedMap[p]}
                      onClick={() =>
                        setExpandedMap(prev => ({
                          ...prev,
                          [p]: prev[p] ? false : true,
                        }))
                      }
                    >
                      <ArrowExpandMore color='rgb(128, 129, 154)' />
                    </ExpandButton>
                    <Checkbox
                      data-test-id={`addAttributeValuesToProjectAfterActionModal_ListItem_${idx}_Checkbox`}
                      checked={isChecked}
                      indeterminate={isCheckedIncomplete && !isChecked}
                      onChange={() =>
                        setCheckedMap(prev => ({
                          ...prev,
                          ...relatableToProjectKeys.reduce(
                            (acc, key) => ({
                              ...acc,
                              [key]:
                                isChecked || isCheckedIncomplete ? false : true,
                            }),
                            {},
                          ),
                        }))
                      }
                    />
                    <AllFolders color='rgba(128, 129, 154, 1)' />
                    <span>{projectModuleValuesFlatMap[p].name}</span>
                  </ListItemName>
                  {expandedMap[p] &&
                    Object.keys(children).map((m, i) => (
                      <ListItemName key={m} nested>
                        <Checkbox
                          data-test-id={`addAttributeValuesToProjectAfterActionModal_ListItem_${idx}_${i}_Checkbox`}
                          checked={
                            checkedMap[`${p}${projectModuleSplitter}${m}`]
                          }
                          onChange={() =>
                            setCheckedMap(prev => ({
                              ...prev,
                              [`${p}${projectModuleSplitter}${m}`]: prev[
                                `${p}${projectModuleSplitter}${m}`
                              ]
                                ? false
                                : true,
                            }))
                          }
                        />
                        <DashboardIcon color='rgba(128, 129, 154, 1)' />
                        <span>{projectModuleValuesFlatMap[m].name}</span>
                      </ListItemName>
                    ))}
                </ProjectListItem>
              )
            },
          )}
        </PopupContent>
        <Footer>
          <Button
            data-test-id='addAttributeValuesToProjectAfterActionModalCancelBtn'
            onClick={() => handleClose()}
          >
            {isCloseLoading ? (
              <StyledCircularProgress color='inherit' size={20} />
            ) : (
              'Не добавлять'
            )}
          </Button>

          <UiKitButton
            data-test-id='addAttributeValuesToProjectAfterActionModalConfirmBtn'
            onClick={handleSubmit}
            disabled={isSubmitLoading || !checkedMapLength}
            size='regular'
          >
            {isSubmitLoading ? (
              <StyledCircularProgress color='inherit' size={20} />
            ) : (
              'Добавить'
            )}
          </UiKitButton>
        </Footer>
      </Container>
    </Popup>
  )
}
