import { SvgComponentType } from '@gmini/ui-kit'
import styled from 'styled-components'

const AvatarWrapper = styled.svg`
  min-width: 24px;
`

export const Avatar = ({ color = '#4C5ECF', ...other }: SvgComponentType) => (
  <AvatarWrapper
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      d='M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 3.6C13.992 3.6 15.6 5.208 15.6 7.2C15.6 9.192 13.992 10.8 12 10.8C10.008 10.8 8.4 9.192 8.4 7.2C8.4 5.208 10.008 3.6 12 3.6ZM12 20.64C9 20.64 6.348 19.104 4.8 16.776C4.836 14.388 9.6 13.08 12 13.08C14.388 13.08 19.164 14.388 19.2 16.776C17.652 19.104 15 20.64 12 20.64Z'
      fill={color}
      fillOpacity='0.3'
    />
  </AvatarWrapper>
)
