import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'

import { apiClient } from '../../services/api'

import { getErrorFromOKResponse } from '../../services/utils'

import { AttributeWithChildren } from '../attributes/types'

import { ModuleDto, ModuleUsersCount } from './types'

export const getModuleUsersCount = createAsyncThunk<ModuleUsersCount[], void>(
  'modules/getModuleUsersCount',
  async (_, { rejectWithValue, dispatch }) => {
    await dispatch(getModules())
    const [err, res] = await apiClient.get<ModuleUsersCount[]>({
      path: '/auth/admin/users/count-by-modules',
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(
            res,
            'Ошибка получения количества пользователей модулей: ',
          ),
      )
    }

    const data: ModuleUsersCount[] = get(res, 'data.data', [])

    return data
  },
)

export const getModules = createAsyncThunk<ModuleDto[], void>(
  'modules/getModules',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<ModuleDto[]>({
      baseUrl: '/api/hub-management/v1',
      path: '/modules',
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения модулей: '),
      )
    }

    const data: ModuleDto[] = get(res, 'data.data', [])

    return data
  },
)

export const getModuleAttributes = createAsyncThunk<
  AttributeWithChildren[],
  { moduleId: string }
>('modules/getModuleAttributes', async ({ moduleId }, { rejectWithValue }) => {
  const [err, res] = await apiClient.get<AttributeWithChildren[]>({
    baseUrl: '/api/hub-management/v1',
    path: `/attributes/module/${moduleId}/attribute-values`,
  })

  if (err || ('status' in res.data && res.data.status !== 'success')) {
    throw rejectWithValue(
      err || getErrorFromOKResponse(res, 'Ошибка получения атрибутов модуля: '),
    )
  }

  const data: AttributeWithChildren[] = get(res, 'data.data', [])

  return data
})
