import { AllFolders, PlusCircle } from '@gmini/ui-kit'
import { useState } from 'react'

import { useAppSelector } from '../../store/store'

import { AddProjectPopup } from '../AddProjectPopup/AddProjectPopup'
import { ProjectList } from '../ProjectList/ProjectList'

import {
  ModuleScopes,
  checkProjectsPermissionsSelector,
} from '../../store/users/permissions-selectors'

import {
  AddProjectButton,
  ButtonContainer,
  ListHeading,
  ProjectListWrapper,
  Title,
  TitleCount,
} from './HubPage.styled'

export const HubPage = () => {
  const [addProjectPopupOpen, setAddProjectPopupOpen] = useState(false)
  const {
    list: projects,
    total,
    pending,
  } = useAppSelector(state => state.projects)
  const checkProjectsPermissions = useAppSelector(
    checkProjectsPermissionsSelector,
  )

  return (
    <ProjectListWrapper>
      <ListHeading>
        <Title>
          <AllFolders />
          <span data-test-id='hubPageHeading'>Проекты аккаунта</span>
          <TitleCount>{total}</TitleCount>
        </Title>
        <ButtonContainer>
          <AddProjectButton
            data-test-id='addProjectBtn'
            onClick={() => setAddProjectPopupOpen(true)}
            size='regular'
            color='primary'
            leftIcon={<PlusCircle width='16px' height='16px' />}
            disabled={!checkProjectsPermissions(ModuleScopes.AC_CREATE)}
          >
            <span>Добавить проект</span>
          </AddProjectButton>
        </ButtonContainer>
      </ListHeading>
      <AddProjectPopup
        open={addProjectPopupOpen}
        onClose={() => setAddProjectPopupOpen(false)}
      />
      <ProjectList projects={projects} pending={pending} />
    </ProjectListWrapper>
  )
}
