import { Page403 } from '@gmini/ui-kit'

import { ErrPageDescription } from './Error403Page.styled'

export const Error403Page = () => (
  <Page403
    customDescription={
      <ErrPageDescription>
        Видимо, у вас нет прав на использование модуля Administration.
        <br />
        Свяжитесь с нашей{' '}
        <a
          href='https://gmini.freshdesk.com/a/tickets/new'
          target='_blank'
          rel='noreferrer'
        >
          техподдержкой
        </a>
        .
      </ErrPageDescription>
    }
  />
)
