type El = {
  scrollHeight: number
  clientHeight: number
}

export const checkForScrollEl = <T extends El>(
  element: T | null | undefined,
) => {
  if (!element) {
    return false
  }

  return element.scrollHeight !== element.clientHeight
}
