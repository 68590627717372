import React, { SVGAttributes } from 'react'

type SvgComponentType = SVGAttributes<SVGSVGElement> & {
  color?: string
}

export const AddFolderIcon = ({
  color = '#353B60',
  width = '24',
  height = '24',
  ...other
}: SvgComponentType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ flexShrink: 0 }}
    {...other}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19 21.5C19 21.7761 19.2239 22 19.5 22H20.5C20.7761 22 21 21.7761 21 21.5V19H23.5C23.7761 19 24 18.7761 24 18.5V17.5C24 17.2239 23.7761 17 23.5 17H21V14.5C21 14.2239 20.7761 14 20.5 14H19.5C19.2239 14 19 14.2239 19 14.5V17H16.5C16.2239 17 16 17.2239 16 17.5V18.5C16 18.7761 16.2239 19 16.5 19H19V21.5Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 6.75H19.2C20.199 6.75 21 7.52875 21 8.5V12.563C20.8402 12.5219 20.6726 12.5 20.5 12.5H19.5C18.3954 12.5 17.5 13.3954 17.5 14.5V15.5H16.5C15.3954 15.5 14.5 16.3954 14.5 17.5V18.5C14.5 18.6726 14.5219 18.8402 14.563 19H4.8C3.801 19 3 18.2212 3 17.25L3.009 6.75C3.009 5.77875 3.801 5 4.8 5H9.79401C10.0544 5 10.3044 5.10153 10.4911 5.28301L12 6.75Z'
      fill={color}
    />
  </svg>
)
