import styled from 'styled-components'

export const InfoPanel = styled.div`
  position: relative;
  width: 446px;
  border-radius: 4px;
  background: #fffbf6;
  padding: 24px;
  gap: 8px;
  font-family: 'Roboto';
  border: 1px solid rgba(255, 237, 206, 1);
  &::after {
    content: '';
    position: absolute;
    left: -10px;
    top: 55%;
    border: 1px solid rgba(255, 237, 206, 1);
    background: #fffbf6;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    transform: rotate(45deg);
    clip-path: polygon(0 16%, 84% 100%, 0 100%);
  }
`

export const InfoPanelTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  color: rgba(0, 0, 61, 1);
  margin-bottom: 24px;
`

export const InfoText = styled.div`
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: rgba(0, 0, 61, 0.7);
  margin-bottom: 16px;
`
export const SpecialInfoText = styled.div`
  display: flex;
  gap: 10px;
  padding: 14px;
  padding-right: 17px;
  border-radius: 4px;
  background: rgba(255, 237, 206, 0.6);
  margin-top: 32px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
`
