import { AllFolders, UnfoldLess } from '@gmini/ui-kit'
import { useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router'

import { useAppSelector } from '../../store/store'
import { CompaniesIcon } from '../icons/CompaniesIcon'

import { DashboardIcon } from '../icons/DashboardIcon'
import { KeyIcon } from '../icons/KeyIcon'
import { LockIcon } from '../icons/LockIcon'
import { PieDiagramIcon } from '../icons/PieDiagramIcon'
import { UsersIcon } from '../icons/UsersIcon'

import {
  ModuleScopes,
  checkRolesPermissionsSelector,
} from '../../store/users/permissions-selectors'

import { SettingsListIcon } from '../icons/SettingsListIcon'

import {
  HubInfo,
  IconButton,
  List,
  Marker,
  MenuItem,
  SidebarWrapper,
} from './AppSidebar.styled'

export const AppSidebar = () => {
  const { hubId } = useParams<{ hubId: string }>()
  const checkRolesPermissions = useAppSelector(checkRolesPermissionsSelector)

  const menuItems = useMemo(
    () => [
      { disabled: true, title: 'Аккаунт', icon: <PieDiagramIcon />, url: '' },
      { disabled: true, title: 'Лицензии', icon: <KeyIcon />, url: '' },
      {
        title: 'Проекты',
        icon: <AllFolders />,
        url: `/hub/${hubId}`,
      },
      {
        title: 'Модули',
        icon: <DashboardIcon />,
        url: `/hub/${hubId}/modules`,
      },
      {
        title: 'Компании',
        icon: <CompaniesIcon />,
        url: `/hub/${hubId}/companies`,
      },
      {
        title: 'Роли',
        icon: <LockIcon />,
        url: `/hub/${hubId}/roles`,
        disabled: !checkRolesPermissions(ModuleScopes.AC_READ),
      },
      {
        title: 'Пользователи',
        icon: <UsersIcon />,
        url: `/hub/${hubId}/users`,
      },
      {
        title: 'Кастомные атрибуты',
        icon: <SettingsListIcon />,
        url: `/hub/${hubId}/attributes`,
      },
    ],
    [hubId, checkRolesPermissions],
  )
  const history = useHistory()
  const [folded, setFolded] = useState(false)
  return (
    <SidebarWrapper data-test-id='sidebarMenu' folded={folded}>
      <HubInfo>
        <IconButton
          data-test-id='foldSidebarMenuBtn'
          onClick={() => setFolded(!folded)}
          folded={folded}
        >
          <UnfoldLess color='rgba(53, 59, 96, 0.5)' />
        </IconButton>
      </HubInfo>
      <List folded={folded}>
        {menuItems.map((item, idx) => {
          const selected = item.url === history.location.pathname
          return (
            <MenuItem
              disabled={Boolean(item.disabled)}
              selected={selected}
              key={item.title}
              onClick={() => {
                history.push(item.url)
              }}
              data-test-id={`sidebarMenuItem_${idx}`}
            >
              {item.icon}
              <span>{item.title}</span>
              {selected ? <Marker /> : null}
            </MenuItem>
          )
        })}
      </List>
    </SidebarWrapper>
  )
}
