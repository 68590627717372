import styled, { css } from 'styled-components'

type CompanyLogoFrameProps = {
  photo: string
  size: 'big' | 'medium'
}

export const CompanyLogoFrame = styled.div`
  ${({ photo }: CompanyLogoFrameProps) =>
    photo ? `background: url(${photo});` : ''}
  background-size: cover;
  border: 1px solid #e5e7f1;
  border-radius: 7.5px;
  ${({ size }: CompanyLogoFrameProps) =>
    size === 'big'
      ? css`
          width: 72px;
          height: 72px;
        `
      : css`
          width: 54px;
          height: 54px;
        `}
  overflow: hidden;
`

type DefaultCompanyImageProps = {
  size: 'big' | 'medium'
}

export const DefaultCompanyImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #f4f4f8;
  svg {
    ${({ size }: DefaultCompanyImageProps) =>
      size === 'big'
        ? css`
            width: 45px;
            height: 45px;
          `
        : css`
            width: 33.75px;
            height: 33.75px;
          `}
  }

  path {
    fill: rgba(53, 59, 96, 0.3);
  }
`
