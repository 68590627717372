import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'
import { apiClient } from '../../services'

import { getErrorFromOKResponse } from '../../services/utils'

import { Hub } from './types'

export const getHubs = createAsyncThunk<Hub[], void>(
  'hubs/getHubs',
  async (_, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<Hub[]>({
      // path: '/auth/admin/hubs',
      baseUrl: '/api/hub-management/v1',
      path: '/hubs',
      params: { 'retrieve-projects': true },
    })

    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения списка хабов: '),
      )
    }

    const data: Hub[] = get(res, 'data.data', [])

    return data
  },
)

export const getHubById = createAsyncThunk<Hub, { hubId: string }>(
  'hubs/getHubById',
  async ({ hubId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<Hub>({
      path: `/auth/admin/hubs/${hubId}`,
    })

    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения хаба: '),
      )
    }

    const data: Hub = get(res, 'data.data', {
      id: 0,
      name: '',
      deleted: false,
    })

    return data
  },
)

export const createHub = createAsyncThunk<Hub, { hubName: string }>(
  'hubs/createHub',
  async ({ hubName }, { rejectWithValue }) => {
    const [err, res] = await apiClient.post<{ hubName: string }, Hub>({
      path: '/auth/admin/hubs',
      body: {
        hubName,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка создания хаба: '),
      )
    }

    const data: Hub = get(res, 'data.data', {
      id: 0,
      name: '',
      deleted: false,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Хаб "${hubName}" успешно создан`}</SnackbarWrapper>
      ),
    })

    return data
  },
)

export const updateHub = createAsyncThunk<Hub, { id: number; name: string }>(
  'hubs/updateHub',
  async ({ name, id }, { rejectWithValue }) => {
    const [err, res] = await apiClient.put<{ name: string }, Hub>({
      path: `/auth/admin/hubs/${id}`,
      body: {
        name,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка редактирования хаба: '),
      )
    }

    const data: Hub = get(res, 'data.data', {
      id: 0,
      name: '',
      deleted: false,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Название изменено на "${name}"`}</SnackbarWrapper>
      ),
    })

    return data
  },
)
