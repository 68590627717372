import styled from 'styled-components'
import { Button } from '@gmini/ui-kit'

export const TableWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`

export const CompanyListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const AddCompanyButton = styled(Button)`
  background: #4c5ecf;
  color: #fff;
  & path {
    fill: #fff;
  }
  &:hover,
  &:active {
    background: #2e387c;
  }
`
export const ButtonContainer = styled.div``

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
`
export const TitleCount = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(53, 59, 96, 0.5);
`
export const ListHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 27px 24px;
`
