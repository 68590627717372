import { SvgComponentType } from '@gmini/ui-kit'

export const DownloadIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ flexShrink: 0 }}
    {...other}
  >
    <g clipPath='url(#clip0_579_48757)'>
      <path
        d='M19 15C18.4477 15 18 15.4477 18 16V18H6V16C6 15.4477 5.55228 15 5 15C4.44772 15 4 15.4477 4 16V18C4 19.1 4.9 20 6 20H18C19.1 20 20 19.1 20 18V16C20 15.4477 19.5523 15 19 15ZM16.2943 11.7057C16.6841 11.3159 16.6841 10.6841 16.2943 10.2943C15.9051 9.90511 15.2743 9.9045 14.8843 10.293L13 12.17V5C13 4.44771 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V12.17L9.11568 10.293C8.72572 9.9045 8.09489 9.90511 7.70568 10.2943C7.31594 10.6841 7.31594 11.3159 7.70568 11.7057L11.6464 15.6464C11.8417 15.8417 12.1583 15.8417 12.3536 15.6464L16.2943 11.7057Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_579_48757'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
