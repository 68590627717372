import { useEffect, useState } from 'react'

import { ExpandButton } from '../AttributeTab/AttributeValueGroupItem.styled'
import { ArrowExpandMore } from '../icons/ArrowExpandMore'
import { AttributeIcon } from '../icons/AttributeIcon'

import {
  AttributeInstances,
  AttributeWithChildren,
} from '../../store/attributes/types'
import { FolderBigIcon } from '../icons/FolderBigIcon'

import {
  ListItem,
  ProjectOrModuleAttributeListWrapper,
  Value,
} from './ProjectOrModuleAttributeList.styled'

type ProjectOrModuleAttributeListProps = {
  list: AttributeWithChildren[]
  fetchList: () => void
}

export const ProjectOrModuleAttributeList = ({
  list,
  fetchList,
}: ProjectOrModuleAttributeListProps) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({})

  useEffect(() => {
    fetchList()
  }, [fetchList])

  return (
    <ProjectOrModuleAttributeListWrapper>
      {list.map((a, idx) => (
        <>
          <ListItem>
            <ExpandButton
              data-test-id={`attributeListItem_${idx}_ExpandBtn`}
              expanded={expanded[a.id]}
              onClick={() =>
                setExpanded(prev => ({
                  ...prev,
                  [a.id]: expanded[a.id] ? false : true,
                }))
              }
            >
              <ArrowExpandMore />
            </ExpandButton>
            <AttributeIcon />
            {a.name}
          </ListItem>
          {expanded[a.id] &&
            a.children.map((v, i) =>
              v.type === AttributeInstances.GROUP ? (
                <>
                  <ListItem nestedPadding={24}>
                    <ExpandButton
                      expanded={expanded[`${a.id}_${v.id}`]}
                      data-test-id={`attributeListItem_${idx}_${i}_ExpandBtn`}
                      onClick={() =>
                        setExpanded(prev => ({
                          ...prev,
                          [`${a.id}_${v.id}`]: expanded[`${a.id}_${v.id}`]
                            ? false
                            : true,
                        }))
                      }
                    >
                      <ArrowExpandMore />
                    </ExpandButton>
                    <FolderBigIcon color='rgba(128, 129, 154, 1)' />
                    {v.name}
                  </ListItem>
                  {expanded[`${a.id}_${v.id}`] &&
                    v.children.map(value => (
                      <ListItem nestedPadding={60} key={value.id}>
                        <Value>{value.name}</Value>
                      </ListItem>
                    ))}
                </>
              ) : (
                <ListItem nestedPadding={36} key={v.id}>
                  <Value>{v.name}</Value>
                </ListItem>
              ),
            )}
        </>
      ))}
    </ProjectOrModuleAttributeListWrapper>
  )
}
