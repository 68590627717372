import styled, { css } from 'styled-components'

import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'

import { ValueName } from '../AttributeTab/AttributeValueGroupItem.styled'

export const PopupContent = styled.div`
  padding: 12px;
  margin: 0 24px;
  border-bottom: 1px solid rgba(229, 231, 241, 1);
  border-top: 1px solid rgba(229, 231, 241, 1);
  max-height: 50vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
  padding-bottom: 16px;
`
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  width: 100%;
`

type ButtonProps = {
  disabled?: boolean
}

export const Button = styled.div`
  padding: 8px;
  border-radius: 4px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: rgba(53, 59, 96, 1);
  background: rgba(53, 59, 96, 0.05);

  &:hover {
    cursor: pointer;
    background: rgba(53, 59, 96, 0.07);
  }
  &:active {
    background: rgba(53, 59, 96, 0.09);
  }
  ${({ disabled }: ButtonProps) =>
    disabled
      ? css`
          pointer-events: none;
          background: rgba(53, 59, 96, 0.03);
          color: rgba(53, 59, 96, 0.09);
        `
      : ''}
`

export const Text = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: rgba(0, 0, 61, 1);
  margin: 0 24px;
  padding-bottom: 16px;
`

export const AttributeValuesContainer = styled.div`
  margin: 0 24px;
  margin-bottom: 16px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 4px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }
`

export const StyledValueName = styled(ValueName)`
  width: max-content;
  white-space: nowrap;
`

export const ProjectListItem = styled.div`
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  color: rgba(0, 0, 61, 1);
  display: flex;
  flex-direction: column;
  gap: 8px;
`

type ListItemNameProps = {
  nested?: boolean
}

export const ListItemName = styled.div`
  display: flex;
  align-items: center;

  ${({ nested }: ListItemNameProps) => (nested ? 'margin-left: 36px;' : '')}
`

export const StyledCircularProgress = styled(CircularProgress)`
  margin: 0 25.44px;
`
