import { Button } from '@gmini/ui-kit'
import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'
import styled, { css } from 'styled-components'

import { DownloadIcon } from '../icons/DownloadIcon'
export const UserListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
`
export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
`
export const TitleCount = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  color: rgba(53, 59, 96, 0.5);
`
export const ListHeading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
`
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
`

export const AddUserButton = styled(Button)`
  background: #4c5ecf;
  color: #fff;
  & path {
    fill: #fff;
  }
  &:hover,
  &:active {
    background: #2e387c;
  }
`

type ExportUsersButtonProps = {
  disabled: boolean
}

export const ExportUsersButton = styled(DownloadIcon)`
  padding: 6px;
  box-sizing: unset;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 61, 0.1);
  }
  ${({ disabled }: ExportUsersButtonProps) =>
    disabled
      ? css`
          opacity: 0.2;
          pointer-events: none;
        `
      : ''}
`

export const ExportUsersButtonWrapper = styled.div`
  position: relative;
`

export const StyledCircularProgress = styled(CircularProgress)`
  width: 36px !important;
  height: 36px !important;
  position: absolute;
  left: 0px;
  top: 0px;
  pointer-events: none;
`
