import styled, { css } from 'styled-components'

import { Button, RoundedCross as RoundedCrossInit } from '@gmini/ui-kit'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 18.5px 24px;
`

export const Content = styled.div`
  overflow-y: auto;
  max-height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
`

type FooterProps = {
  existScroll?: boolean
}

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  padding: 16px 24px;
  width: 100%;
  box-shadow: ${(props: FooterProps) =>
    props.existScroll ? '0px -10px 20px rgba(0, 0, 0, 0.1)' : 'none'};
`

type CreateButtonProps = {
  disabled?: boolean
}

export const ResetButton = styled(Button)`
  color: #fff;
  background: #d9575e;
  &:hover {
    background: #d5444b;
  }

  &:active {
    background: #d02f37;
  }
`

export const CreateButton = styled(Button)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
    font-family: Roboto, sans-serif;
    background: #4c5ecf;
    transition: all 0.2s ease-out;
    ${(props: CreateButtonProps) =>
      props.disabled &&
      css`
        background: rgba(53, 59, 96, 0.05);
        color: rgba(53, 59, 96, 0.3);
      `}
    height: 36px;
    margin: auto 0;

    &:hover {
      ${(props: CreateButtonProps) =>
        !props.disabled &&
        css`
          background: #3649c9;
        `}
    }

    &:active {
      ${(props: CreateButtonProps) =>
        !props.disabled &&
        css`
          background: #3042b5;
        `}
    }
  }
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  color: #000335;
  letter-spacing: 0.1px;
`

export const RoundedCross = styled(RoundedCrossInit)`
  cursor: pointer;

  path {
    fill: #353b60;
  }
`
