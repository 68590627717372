import { HTMLAttributes, ReactNode, useRef, useState } from 'react'

import {
  Container,
  Content,
  Title,
  CollapseButton,
  ContentWrapper,
  Badge,
} from './Accordion.styled'

export type AccordionProps = {
  children?: ReactNode
  title?: ReactNode
  badge?: ReactNode
} & HTMLAttributes<HTMLDivElement>

export const Accordion = ({
  children,
  title,
  badge,
  ...other
}: AccordionProps) => {
  const [open, setOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const height = contentRef?.current?.clientHeight || 0

  return (
    <Container open={open} {...other}>
      <Title onClick={() => setOpen(val => !val)}>
        {title}
        <CollapseButton color='#353b60' open={open} />
      </Title>
      {badge ? <Badge>{badge}</Badge> : null}
      <ContentWrapper open={open} height={height}>
        <Content ref={contentRef}>{children}</Content>
      </ContentWrapper>
    </Container>
  )
}
