import React, { SVGAttributes } from 'react'

type SvgComponentType = SVGAttributes<SVGSVGElement> & {
  color?: string
}

export const ArrowExpandMore = ({
  color = '#80819A',
  width = '12',
  height = '13',
  ...other
}: SvgComponentType) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 12 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <path
      d='M6.00078 9.49981C6.26078 9.49981 6.51078 9.3998 6.71078 9.2098L10.2553 5.6653C10.6449 5.27567 10.6449 4.64394 10.2553 4.25431C9.86604 3.86506 9.23508 3.86462 8.84529 4.25331L6.00078 7.08981L3.16578 4.2548C2.77642 3.86544 2.14514 3.86544 1.75578 4.2548C1.36642 4.64417 1.36642 5.27544 1.75578 5.6648L5.30078 9.2098C5.49078 9.3998 5.74078 9.49981 6.00078 9.49981Z'
      fill={color}
    />
  </svg>
)
