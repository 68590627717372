import { ActionPopupWrapper } from '@gmini/ui-kit'

import { useForm, Controller } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from '../../store/store'

import { addNewProject, getProjects } from '../../store/projects/actions'

import { envName } from '../../config'

import { addUserRoles } from '../../store/users/actions'
import { GSTATION_ADMIN_ROLE_ID } from '../../constants'

import {
  EmptyContainer,
  FieldContainer,
  FieldError,
  FieldLabel,
  Form,
  TextField,
} from './AddProjectPopup.styled'

type AddProjectPopupProps = {
  open: boolean
  onClose: () => void
}
const requiredErrorMessage = 'Это поле является обязательным'

const defaultValues = {
  name: '',
}

const popupButtonsDataTestIds = {
  acceptButton: 'addProjectPopupConfirmBtn',
  declineButton: 'addProjectPopupCancelBtn',
}

export const AddProjectPopup = ({ open, onClose }: AddProjectPopupProps) => {
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.currentUser)

  const onSubmit = async (data: typeof defaultValues) => {
    const { name: nameInit } = data
    const newProject = await dispatch(
      addNewProject({ name: nameInit.trim(), hubName: envName }),
    ).unwrap()
    await dispatch(
      addUserRoles({
        projectUrn: newProject.urn,
        id: user.id,
        name: user.name,
        roleIds: [GSTATION_ADMIN_ROLE_ID],
      }),
    )
    dispatch(getProjects())
    reset()
    onClose()
  }

  return (
    <ActionPopupWrapper
      title={<span data-test-id='addProjectPopupHeading'>Добавить проект</span>}
      submitButtonTitle='Добавить'
      pending={!watch().name.trim()}
      open={open}
      width='600px'
      onClose={() => {
        reset()
        onClose()
      }}
      onSubmit={handleSubmit(onSubmit)}
      dataTestIdFor={popupButtonsDataTestIds}
    >
      {({ existScroll }) => (
        <Form>
          <FieldContainer>
            <FieldLabel required>Наименование проекта</FieldLabel>
            <Controller
              name='name'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
              }}
              render={({ field }) => (
                <TextField
                  data-test-id='projectNameInput'
                  {...field}
                  error={Boolean(errors.name)}
                  clearable
                />
              )}
            />
            <FieldError hidden={!errors.name}>
              {errors.name && 'message' in errors.name
                ? errors.name.message
                : null}
            </FieldError>
          </FieldContainer>
          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}
