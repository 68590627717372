import styled from 'styled-components'

import { Option } from '../AddUserPopup/AddUserPopup.styled'

export const RoleField = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
type FieldContainerProps = {
  oneInARow: boolean
}
export const FieldContainer = styled.div`
  width: ${({ oneInARow }: FieldContainerProps) =>
    oneInARow ? 'unset' : '48%'};
`
type UserRolesWrapperProps = {
  oneInARow: boolean
}
export const UserRolesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ oneInARow }: UserRolesWrapperProps) =>
    oneInARow ? 'column' : 'row'};
  gap: 12px;
`
type ButtonContainerProps = {
  oneInARow: boolean
}
export const ButtonContainer = styled.div`
  height: 40px;
  ${({ oneInARow }: ButtonContainerProps) =>
    oneInARow ? '' : 'align-self: flex-end;'}
  display: flex;
  align-items: center;
`

export const RoleTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.132923px;
  color: #353b60;
`

export const RoleDescription = styled.div`
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #353b60;
  opacity: 0.7;
`

export const RoleOption = styled.div`
  ${Option}
`
