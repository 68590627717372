import { SvgComponentType } from '@gmini/ui-kit'

export const EditTransparentIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g opacity='0.5' clipPath='url(#clip0_625_227449)'>
      <path
        d='M14.06 9.02L14.98 9.94L5.92 19H5V18.08L14.06 9.02ZM17.66 3C17.41 3 17.15 3.1 16.96 3.29L15.4836 4.76645C15.2883 4.96171 15.2883 5.27829 15.4836 5.47355L18.5264 8.51645C18.7217 8.71171 19.0383 8.71171 19.2336 8.51645L20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C18.17 3.09 17.92 3 17.66 3ZM14.4136 6.54355C14.2183 6.34829 13.9017 6.34829 13.7064 6.54355L3.14645 17.1036C3.05268 17.1973 3 17.3245 3 17.4571V21H6.54289C6.6755 21 6.80268 20.9473 6.89645 20.8536L17.4564 10.2936C17.6517 10.0983 17.6517 9.78171 17.4564 9.58645L14.4136 6.54355Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_625_227449'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
