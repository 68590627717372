import { useEffect, useState } from 'react'

import { PlusCircle, Tooltip } from '@gmini/ui-kit'

import { AttributeTab } from '../AttributeTab/AttributeTab'

import { SettingsListIcon } from '../icons/SettingsListIcon'

import { useAppDispatch, useAppSelector } from '../../store/store'

import { EditOrAddAttributeModal } from '../EditOrAddAttributeModal/EditOrAddAttributeModal'

import { getAttributes } from '../../store/attributes/actions'
import { Attribute } from '../../store/attributes/types'

import {
  StyledAttributesInfo,
  StyledIconButton,
  Tab,
  TabsBar,
  TabsContainer,
  Title,
  TitleHeading,
  UserListWrapper,
} from './CustomAttributesPage.styled'

export const CustomAttributesPage = () => {
  const [isAttributeModalOpen, setIsAttributeModalOpen] = useState(false)

  const { list: attributes } = useAppSelector(state => state.attributes)
  const dispatch = useAppDispatch()

  const [selectedAttribute, setSelectedAttribute] = useState<Attribute | null>(
    attributes[0] || null,
  )

  useEffect(() => {
    const initAttributes = async () => {
      const [attribute] = await dispatch(getAttributes({})).unwrap()
      if (attribute) {
        setSelectedAttribute(attribute)
      }
    }
    initAttributes()
  }, [dispatch])

  return (
    <UserListWrapper>
      <Title>
        <TitleHeading data-test-id='customAttributesPageHeading'>
          <SettingsListIcon />
          Кастомные атрибуты
        </TitleHeading>
        <TabsContainer>
          <TabsBar>
            {attributes.map((a, idx) => (
              <Tab
                key={a.id}
                data-test-id={`customAttributesPageTab_${idx}`}
                onClick={() => {
                  setSelectedAttribute(a)
                }}
                selected={selectedAttribute?.id === a.id}
              >
                {a.name}
              </Tab>
            ))}
          </TabsBar>
          <Tooltip title='Добавить атрибут'>
            <StyledIconButton
              data-test-id='customAttributesPageAddAttributeBtn'
              onClick={() => setIsAttributeModalOpen(true)}
              type='circle'
              size='regular'
            >
              <PlusCircle />
            </StyledIconButton>
          </Tooltip>
        </TabsContainer>
      </Title>
      {selectedAttribute ? (
        <AttributeTab
          attribute={selectedAttribute}
          setSelectedAttribute={setSelectedAttribute}
        />
      ) : (
        <StyledAttributesInfo />
      )}
      <EditOrAddAttributeModal
        open={isAttributeModalOpen}
        onClose={() => setIsAttributeModalOpen(false)}
        setSelected={setSelectedAttribute}
      />
    </UserListWrapper>
  )
}
