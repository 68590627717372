import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'
import { apiClient } from '../../services'
import { getCompaniesUsersCount } from '../users/actions'

import { getErrorFromOKResponse } from '../../services/utils'

import { Company } from './types'

export const getCompanies = createAsyncThunk<Company[], void>(
  'companies/getCompanies',
  async (_, { dispatch, rejectWithValue }) => {
    await dispatch(getCompaniesUsersCount())

    const [err, res] = await apiClient.get<Company[]>({
      baseUrl: '/api/hub-management/v1',
      path: '/companies',
    })

    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения компаний: '),
      )
    }

    const data: Company[] = get(res, 'data.data', [])

    return data
  },
)

export const getCompanyById = createAsyncThunk<Company, { companyId: string }>(
  'companies/getCompanyById',
  async ({ companyId }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<Company>({
      baseUrl: '/api/hub-management/v1',
      path: `/companies/${companyId}`,
    })

    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения компании: '),
      )
    }

    const data: Company = get(res, 'data.data', {
      id: 0,
      companyName: '',
      photo: '',
      count: 0,
    })

    return data
  },
)

export const addNewCompany = createAsyncThunk<Company, { companyName: string }>(
  'companies/addCompany',
  async ({ companyName }, { rejectWithValue }) => {
    const [err, res] = await apiClient.post<{ companyName: string }, Company>({
      baseUrl: '/api/hub-management/v1',
      path: '/companies',
      body: {
        companyName,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка создания компании: '),
      )
    }

    const data: Company = get(res, 'data.data', {
      id: 0,
      companyName: '',
      photo: '',
      count: 0,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Компания "${companyName}" успешно добавлена`}</SnackbarWrapper>
      ),
    })

    return data
  },
)

export const updateCompany = createAsyncThunk<
  Company,
  { id: number; companyName: string }
>(
  'companies/updateCompany',
  async ({ companyName, id }, { rejectWithValue }) => {
    const [err, res] = await apiClient.put<{ companyName: string }, Company>({
      baseUrl: '/api/hub-management/v1',
      path: `/companies/${id}`,
      body: {
        companyName,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка редактирования компании: '),
      )
    }

    const data: Company = get(res, 'data.data', {
      id: 0,
      companyName: '',
      photo: '',
      count: 0,
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Название изменено на "${companyName}"`}</SnackbarWrapper>
      ),
    })

    return data
  },
)
