import { createSlice } from '@reduxjs/toolkit'

import { getHubs } from './actions'
import { Hub } from './types'

const initialState: {
  list: Hub[]
  pending: boolean
  setById: { [x: string]: Hub }
  countSet: { [x: string]: number }
  total: number
} = {
  list: [],
  setById: {},
  total: 0,
  pending: false,
  countSet: {},
}

const hubs = createSlice({
  name: 'hubs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getHubs.fulfilled, (state, { payload }) => {
        const setById = payload.reduce(
          (acc: { [x: number]: Hub }, hub) => ({
            ...acc,
            [hub.id]: hub,
          }),
          {},
        )

        return {
          ...state,
          list: payload,
          setById,
          total: payload.length || 0,
          pending: false,
        }
      })
      .addCase(getHubs.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getHubs.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const hubsReducer = hubs.reducer
