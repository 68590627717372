import { Button } from '@gmini/ui-kit'
import styled from 'styled-components'

export const FieldContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;
`

export const Section = styled.div`
  border-radius: 4px;
  background: rgba(53, 59, 96, 0.03);
  padding: 10px 15px;
  margin: 12px 0 25px;
`

export const Title = styled.div`
  color: #353b60;
  letter-spacing: 0.1px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
`

export const Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.15px;
  color: #353b60;
  opacity: 0.7;
`

export const Divider = styled.div`
  height: 1px;
  background: rgba(229, 231, 241, 1);
`

export const ScopesContainer = styled.div`
  margin: 10px 0 20px 15px;
`

export const TextContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 5px;
`

export const UserDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

export const ActionButtons = styled.div`
  padding: 16px 0;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`
type ActionButtonProps = {
  disabled?: boolean
  color: 'primary' | 'secondary'
}

export const ActionButton = styled(Button)`
  && {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: ActionButtonProps) =>
      props.color === 'primary' ? '#fff' : '#353b60'};
    font-family: Roboto, sans-serif;
    background: ${(props: ActionButtonProps) =>
      props.color === 'primary' ? '#4C5ECF' : 'rgba(53, 59, 96, 0.05)'};
    transition: all 0.2s ease-out;
    ${(props: ActionButtonProps) =>
      props.disabled &&
      'background: rgba(53, 59, 96, 0.05); color: rgba(53, 59, 96, 0.3);'}
    height: 36px;
    margin: auto 0;
  }
`
