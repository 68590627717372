import styled from 'styled-components'

type RoleBadgeWrapperProps = {
  bg?: string
  noMargin?: boolean
}

export const RoleBadgeWrapper = styled.div`
  background: ${(props: RoleBadgeWrapperProps) =>
    props.bg ? props.bg : 'rgba(76, 94, 207, 0.15)'};
  border-radius: 4px;
  padding: 0 5px;
  margin-right: ${(props: RoleBadgeWrapperProps) =>
    props.noMargin ? 'initial' : '2px'};
  white-space: nowrap;
`

type RoleBadgeTextProps = {
  color?: string
}

export const RoleBadgeText = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: ${({ color }: RoleBadgeTextProps) => color};
`
export const AdditionalRolesCount = styled.div`
  margin-left: 5px;
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #4c5ecf;
`
