import { ReactNode, useRef } from 'react'

import { IconButton, Popup } from '@gmini/ui-kit'

import {
  Container,
  Content,
  CreateButton,
  Footer,
  Header,
  ResetButton,
  RoundedCross,
  Title,
} from './RoleChangesPopup.styled'

type RoleChangesPopupProps = {
  open: boolean
  onClose: () => void
  children: ({ existScroll }: { existScroll: boolean }) => ReactNode
  onSubmit: () => void
  onReset: () => void
  pending?: boolean
  width?: string
  title: string
  submitButtonTitle: string
}

export const RoleChangesPopup = ({
  open,
  onClose,
  children,
  onSubmit,
  onReset,
  pending,
  width = '800px',
  title,
  submitButtonTitle,
}: RoleChangesPopupProps) => {
  const contentRef = useRef<HTMLDivElement>(null)

  const contentRefCurrent = contentRef.current

  const existScroll =
    contentRefCurrent?.scrollHeight !== contentRefCurrent?.clientHeight

  return (
    <Popup width={width} open={open} onClose={onClose}>
      <Container>
        <Header>
          <Title data-test-id='roleChangesPopupHeading'>{title}</Title>
          <IconButton type='square'>
            <RoundedCross
              data-test-id='closeRoleChangesPopupBtn'
              onClick={onClose}
            />
          </IconButton>
        </Header>

        <Content ref={contentRef}>{children({ existScroll })}</Content>
        <Footer existScroll={existScroll}>
          <ResetButton
            data-test-id='roleChangesPopupCancelBtn'
            size='regular'
            onClick={() => {
              onReset()
              onClose()
            }}
          >
            Отменить изменения
          </ResetButton>

          <CreateButton
            data-test-id='roleChangesPopupConfirmBtn'
            disabled={pending}
            onClick={() => {
              onSubmit()
              onClose()
            }}
          >
            {submitButtonTitle}
          </CreateButton>
        </Footer>
      </Container>
    </Popup>
  )
}
