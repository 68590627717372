import { createSlice } from '@reduxjs/toolkit'

type Error403State = {
  enabled: boolean
}

const initialState: Error403State = {
  enabled: false,
}

const error403Slice = createSlice({
  name: 'error403',
  initialState,
  reducers: {
    enableError403: state => {
      state.enabled = true
    },
    disableError403: state => {
      state.enabled = false
    },
  },
})

export const { enableError403, disableError403 } = error403Slice.actions

export const error403 = error403Slice.reducer
