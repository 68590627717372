import Keycloak from 'keycloak-js'

export const envType = getEnv('SPA_ENV_TYPE')
export const keycloakUrl = getEnv('SPA_KEYCLOAK_SERVER_URL')
export const clientId = getEnv('SPA_KEYCLOAK_CLIENT_ID')
export const realm = getEnv('SPA_KEYCLOAK_REALM')
export const envLinks = getEnv('SPA_LINKS_CONFIG')
export const envName = getEnv('SPA_ENVIRONMENT')

function getEnv(name: string): string {
  const value: null | undefined | string = (globalThis as any)[name]

  if (value == null) {
    console.warn(`\`${name}\` environment variable is not defined`)
    return ''
  }

  return value
}

export const keycloakClient = Keycloak({
  clientId,
  realm,
  url: keycloakUrl,
})
