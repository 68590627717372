import { AttributeValue } from '../attributes/types'

export type ProjectDto = {
  attributeValues?: AttributeValue[]
  objects: null
  project: {
    name: string
    projectSource: 'Bim360' | 'GStation'
    urn: string
    archived: boolean
  }
}

export type Project = ProjectDto['project'] &
  Pick<ProjectDto, 'attributeValues'> & {
    count?: number
    searchString?: string
  }

export const mapSourceTypeDescription: Record<
  Project['projectSource'],
  string
> = {
  Bim360: 'BIM360',
  GStation: 'G-station',
}
