import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '../../store/store'

import { getAvailableModules } from '../../store/users/actions'

import {
  ModuleLink,
  ModulesWrapper,
  PageWrapper,
  Title,
} from './AvailableModulesRedirectPage.styled'

export const AvailableModulesRedirectPage = () => {
  const dispatch = useAppDispatch()
  const { availableModules } = useAppSelector(state => state.currentUser)

  useEffect(() => {
    dispatch(getAvailableModules())
  }, [dispatch])

  return (
    <PageWrapper>
      <Title>Модули доступные для перехода</Title>
      <ModulesWrapper>
        {availableModules.map(m =>
          m.url ? (
            <ModuleLink
              available={m.enabled}
              href={m.url}
              key={m.module.moduleId}
            >
              {m.module.name}
            </ModuleLink>
          ) : null,
        )}
      </ModulesWrapper>
    </PageWrapper>
  )
}
