import styled, { css } from 'styled-components'
import { TextArea, TextField as TextFieldInit } from '@gmini/ui-kit'
export const FieldContainer = styled.div`
  width: 100%;
`
export const EmptyContainer = styled.div`
  height: 60px;
`
type FieldErrorProps = {
  hidden: boolean
}

export const FieldError = styled.div`
  opacity: ${(props: FieldErrorProps) => (props.hidden ? 0 : 1)};
  display: flex;
  flex-direction: column;
  gap: 3px;
  color: #d9575e;
  font-size: 12px;
  font-weight: 500;
  margin-left: 6px;
  margin-top: 4px;
  min-height: 21px;
  max-height: 140px;
  overflow-y: auto;
`
type FieldLabelProps = {
  required?: boolean
}

export const FieldLabel = styled.div`
  font-family: Roboto, sans-serif;
  color: #000335;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;

  &:after {
    ${(props: FieldLabelProps) =>
      props.required &&
      css`
        content: ' *';
      `}
    color: #de4242;
    font-size: 13px;
    line-height: 20px;
    font-weight: bold;
    font-family: Roboto, sans-serif;
  }
`
export const TextField = styled(TextFieldInit)`
  background: #f4f4f8;
`
export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  padding: 10px 24px 24px 24px;
`

export const StyledTextArea = styled(TextArea)`
  background: #f4f4f8;
`
