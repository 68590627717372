import { createAsyncThunk } from '@reduxjs/toolkit'
import get from 'lodash/get'
import { toastr } from 'react-redux-toastr'

import { SnackbarWrapper } from '../../components/toastrOptions/ToastrSnackbar.styled'

import { apiClient } from '../../services/api'
import { getProjectsUsersCount } from '../users/actions'

import { getErrorFromOKResponse } from '../../services/utils'

import { AttributeWithChildren } from '../attributes/types'

import { Project, ProjectDto } from './types'

export const getProjects = createAsyncThunk<ProjectDto[], void>(
  'projects/getProjects',
  async (_, { dispatch, rejectWithValue }) => {
    await dispatch(getProjectsUsersCount())

    const [err, res] = await apiClient.get<ProjectDto[]>({
      baseUrl: '/api/hub-management/v1',
      path: '/projects',
    })

    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка получения проектов: '),
      )
    }

    const data: ProjectDto[] = get(res, 'data.data', [])

    return data
  },
)

export const addNewProject = createAsyncThunk<
  Project,
  { name: string; hubName: string }
>('projects/addProject', async ({ name, hubName }, { rejectWithValue }) => {
  const [err, res] = await apiClient.post<
    { name: string; hubName: string; sourceSystem: Project['projectSource'] },
    Project
  >({
    baseUrl: '/api/hub-management/v1',
    path: '/projects',
    body: {
      name,
      hubName,
      sourceSystem: 'GStation',
    },
  })
  if (err || ('status' in res.data && res.data.status !== 'success')) {
    throw rejectWithValue(
      err || getErrorFromOKResponse(res, 'Ошибка создания проекта: '),
    )
  }

  const data: Project = get(res, 'data.data', {
    name: '',
    projectSource: 'GStation',
    urn: '',
    archived: false,
    attributeValues: [],
  })

  toastr.info('', '', {
    icon: <div />,
    component: (
      <SnackbarWrapper>{`Проект "${name}" успешно добавлен`}</SnackbarWrapper>
    ),
  })

  return data
})

export const updateProject = createAsyncThunk<
  Project,
  { name: string; hubName: string; urn: string }
>(
  'projects/updateProject',
  async ({ name, hubName, urn }, { rejectWithValue }) => {
    const [err, res] = await apiClient.patch<
      { name: string; hubName: string },
      Project
    >({
      baseUrl: '/api/hub-management/v1',
      path: `/projects/${urn}`,
      body: {
        name,
        hubName,
      },
    })
    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err || getErrorFromOKResponse(res, 'Ошибка редактирования проекта: '),
      )
    }

    const data: Project = get(res, 'data.data', {
      name: '',
      projectSource: 'GStation',
      urn: '',
      archived: false,
      attributeValues: [],
    })

    toastr.info('', '', {
      icon: <div />,
      component: (
        <SnackbarWrapper>{`Проект "${name}" успешно изменен`}</SnackbarWrapper>
      ),
    })

    return data
  },
)

export const getProjectAttributes = createAsyncThunk<
  AttributeWithChildren[],
  { projectUrn: string }
>(
  'projects/getProjectAttributes',
  async ({ projectUrn }, { rejectWithValue }) => {
    const [err, res] = await apiClient.get<AttributeWithChildren[]>({
      baseUrl: '/api/hub-management/v1',
      path: `/attributes/project/${projectUrn}/attribute-values`,
    })

    if (err || ('status' in res.data && res.data.status !== 'success')) {
      throw rejectWithValue(
        err ||
          getErrorFromOKResponse(res, 'Ошибка получения атрибутов проекта: '),
      )
    }

    const data: AttributeWithChildren[] = get(res, 'data.data', [])

    return data
  },
)
