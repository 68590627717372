import styled, { css } from 'styled-components'

export const PageWrapper = styled.div`
  margin: 50px auto;
  width: 600px;
  height: min-content;
`

export const ModulesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
`

type ModuleLinkProps = {
  available: boolean
}

export const ModuleLink = styled.a`
  padding: 15px 30px;
  border-radius: 4px;
  color: #00003d;
  font-size: 12px;
  letter-spacing: 0.1px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  background: #f5f5f5;
  text-decoration: none;
  &:hover {
    background: #e5e7f1;
    cursor: pointer;
  }
  ${({ available }: ModuleLinkProps) =>
    !available
      ? css`
          opacity: 0.4;
          pointer-events: none;
        `
      : ''}
`

export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`
