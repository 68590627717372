import { AxiosResponse } from 'axios'
import get from 'lodash/get'

import { NoFileSpecifiedError } from './constants'
import { MixedFormData } from './types'

export const createFileBlobRequestBody = (body: File | Blob): FormData => {
  const requestBody = new FormData()

  if (!body) {
    throw new Error(NoFileSpecifiedError)
  }

  requestBody.append('file', body)

  return requestBody
}

export const createMixedRequestBody = (
  mixedFormData: MixedFormData,
): FormData => {
  const requestBody = new FormData()

  if (mixedFormData.model) {
    requestBody.append(
      'model',
      new Blob([JSON.stringify(mixedFormData.model)], {
        type: 'application/json',
      }),
    )
  }

  if (!mixedFormData.file) {
    throw new Error(NoFileSpecifiedError)
  }

  requestBody.append('file', mixedFormData.file)

  return requestBody
}

const errorMessagesMap: Record<string, string> = {
  'Role with same title exist':
    'Такое название роли уже есть. Попробуйте переформулировать или задать другое название',
}

export const getErrorFromOKResponse = (
  res: AxiosResponse,
  messagePrefix?: string,
) => {
  const customErr: { failReason: string } | null = get(res, 'data.data', {
    failReason: '',
  })

  const message =
    customErr?.failReason ||
    ('message' in res.data && typeof res.data.message === 'string'
      ? res.data.message
      : '')

  const messageModified = errorMessagesMap[message] || message

  return new Error(`${messagePrefix}${messageModified}`)
}
