import { HTMLAttributes, useMemo } from 'react'

import { Tooltip } from '@gmini/ui-kit'

import { Role } from '../../store/roles/types'
import { UserType } from '../../store/users/types'
import { RolesListArrowIcon } from '../icons/RolesListArrowIcon'
import { RoleBadge } from '../RoleBadge/RoleBadge'

import { LockIcon } from '../icons/LockIcon'

import {
  RoleDescription,
  RoleInfoBlock,
  RolesListItemWrapper,
  RoleTitle,
  RoleModules,
} from './RolesListItem.styled'

type RoleListItemProps = {
  hubRole: Role
  selected: boolean
} & HTMLAttributes<HTMLDivElement>

export const RolesListItem = ({
  hubRole,
  selected,
  onClick,
  ...other
}: RoleListItemProps) => {
  const { title, description, resources, system } = hubRole

  const modulesInRole = useMemo(
    () =>
      Object.values(resources || {}).filter(
        v => Object.values(v.resources).flatMap(r => r.scopes).length,
      ),
    [resources],
  )
  return (
    <RolesListItemWrapper {...other} onClick={onClick} selected={selected}>
      <RoleInfoBlock>
        <RoleTitle>
          {title}
          {system ? (
            <Tooltip title='Системная роль'>
              <LockIcon
                color={'rgba(53, 59, 96, 0.6)'}
                width={16}
                height={16}
              />
            </Tooltip>
          ) : null}
        </RoleTitle>
        {description && <RoleDescription>{description}</RoleDescription>}
        {modulesInRole.length ? (
          <RoleModules>
            {modulesInRole.map(m => (
              <RoleBadge key={m.id} roleId={UserType.NEW} roleTitle={m.name} />
            ))}
          </RoleModules>
        ) : null}
      </RoleInfoBlock>
      <RolesListArrowIcon />
    </RolesListItemWrapper>
  )
}
