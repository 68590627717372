import { VerticalThreeDots } from '@gmini/ui-kit'
import styled from 'styled-components'

export const UserRolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background: #e5e7f1;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 17px 0;
`
export const Heading = styled.div`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: rgba(0, 3, 53, 1);
`

export const ProjectName = styled.div`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(53, 59, 96, 1);
  word-break: break-all;
  align-self: center;
`
export const ProjectRolesContainer = styled.div`
  margin-bottom: 20px;
`
export const ProjectWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 16px;
`
export const Button = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 6px 8px;
  border-radius: 50px;
  & span {
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    color: rgba(76, 94, 207, 1);
  }
  & svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    cursor: pointer;
    background: rgba(0, 5, 100, 0.04);
  }
  &:active {
    background: rgba(0, 5, 100, 0.07);
  }
`
export const RolesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`
export const RoleBlueBadge = styled.div`
  padding: 4px 10px;
  border-radius: 50px;
  background: rgba(76, 94, 207, 1);
  font-family: 'Roboto';
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
`
export const Divider = styled.div`
  height: 1px;
  background: rgba(229, 231, 241, 1);
  margin-bottom: 20px;
`
export const ActionsMenu = styled.div`
  position: absolute;
  top: 80%;
  right: 30%;
  visibility: hidden;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 7px 0;
  border-radius: 4px;
  background: #fff;
`
export const ActionsMenuItem = styled.div`
  padding: 6px 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: rgba(0, 3, 53, 1);
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    background: rgba(0, 5, 100, 0.04);
  }
`
export const VerticalThreeDotsStyled = styled(VerticalThreeDots)`
  padding: 11px;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 4px;
`
export const ActionsWrapper = styled.div`
  margin-left: auto;
  position: relative;
  display: flex;
  align-self: center;
  &:hover {
    ${ActionsMenu} {
      visibility: visible;
    }
    ${VerticalThreeDotsStyled} {
      background: rgba(0, 5, 100, 0.04);
    }
  }
`
