import { SvgComponentType } from '@gmini/ui-kit'

export const RolesListArrowIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ flexShrink: 0 }}
    {...other}
  >
    <g clipPath='url(#clip0_2098_296607)'>
      <path
        d='M7.38141 21.0114C7.87141 21.5014 8.66141 21.5014 9.15141 21.0114L17.4614 12.7014C17.8514 12.3114 17.8514 11.6814 17.4614 11.2914L9.1514 2.98141C8.6614 2.49141 7.8714 2.49141 7.3814 2.98141C6.8914 3.47141 6.8914 4.26141 7.3814 4.75141L14.6214 12.0014L7.37141 19.2514C6.89141 19.7314 6.89141 20.5314 7.38141 21.0114Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_2098_296607'>
        <rect
          width='24'
          height='24'
          fill='white'
          transform='translate(24 24) rotate(180)'
        />
      </clipPath>
    </defs>
  </svg>
)
