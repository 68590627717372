import { TableColumn, TableDataItem } from '@gmini/ui-kit'
import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import { CELL_SYMBOL_WIDTH, MIN_COLUMN_WIDTH } from '../../constants'

export function getResizeHandler<T extends TableDataItem>(
  setColumns: Dispatch<
    SetStateAction<
      (TableColumn<T> & {
        width?: number | undefined
      })[]
    >
  >,
) {
  return (
    col: TableColumn<T> & { width?: number },
    deltaX: number,
    currentWidth?: number,
  ) =>
    setColumns(prev => {
      const minColWidth =
        ((typeof col.name === 'string' && col.name?.length) || 0) *
          CELL_SYMBOL_WIDTH || MIN_COLUMN_WIDTH

      const targetWidth = (col?.width || currentWidth || 0) + deltaX
      const newWidth = targetWidth > minColWidth ? targetWidth : minColWidth

      const idx = prev.findIndex(val => col.field === val.field)
      return prev.toSpliced(idx, 1, {
        ...col,
        width: newWidth,
        cellStyle: { ...col.cellStyle, width: `${newWidth}px` },
      })
    })
}

export const WidthProviderWrapper = styled.div`
  width: inherit;
`
