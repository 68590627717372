import { SvgComponentType } from '@gmini/ui-kit'

export const PieDiagramIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g clipPath='url(#clip0_520_390063)'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C15.0874 22 17.8481 20.6008 19.6824 18.402L12.1799 12.1499C12.0659 12.0549 12 11.9142 12 11.7658V2Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.8485 3.89405C21.0446 4.10804 21.0023 4.44272 20.767 4.61269L14.7927 8.92748C14.4621 9.16629 14 8.93003 14 8.52214V0.756775C14 0.447589 14.2781 0.211871 14.5801 0.278046C17.0355 0.816042 19.2095 2.10587 20.8485 3.89405Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2685 16.5148C20.5095 16.7077 20.8668 16.6384 21.0016 16.3607C21.6413 15.0428 22 13.5633 22 12C22 10.3225 21.587 8.74152 20.8571 7.35316C20.7219 7.09605 20.3915 7.02594 20.1535 7.19252L14.547 11.1171C14.2737 11.3084 14.2609 11.7087 14.5214 11.9171L20.2685 16.5148Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_520_390063'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
