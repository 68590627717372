import { useHistory, useParams } from 'react-router'
import { Pointer, ThreeDotsIcon } from '@gmini/ui-kit'
import { useEffect, useState, MouseEvent, useCallback } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { useContextMenu } from '@gmini/common'

import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch, useAppSelector } from '../../store/store'

import {
  FieldError,
  FieldLabel,
  TextField,
} from '../AddProjectPopup/AddProjectPopup.styled'

import { getRoles } from '../../store/roles/actions'

import { Project } from '../../store/projects/types'
import { getProjects, updateProject } from '../../store/projects/actions'

import { envName } from '../../config'

import { CompaniesIcon } from '../icons/CompaniesIcon'

import {
  BackButton,
  BtnContainer,
  EditUserPageWrapper,
  FieldContainer,
  Form,
  Title,
  TitleButton,
  TitleContainer,
} from './EditProjectPage.styled'

const requiredErrorMessage = 'Это поле является обязательным'

export const EditProjectPage = () => {
  const history = useHistory()
  const { projectUrn, hubId } = useParams<{
    projectUrn: string
    hubId: string
  }>()
  const { list: projects } = useAppSelector(state => state.projects)
  const [projectState, setProjectState] = useState<Project | undefined>(
    projects.find(p => p.urn === projectUrn),
  )
  const defaultValues = {
    name: projectState?.name || '',
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })

  const dispatch = useAppDispatch()

  const getProject = useCallback(async () => {
    const projects = await dispatch(getProjects()).unwrap()
    const project = projects.find(p => p.project.urn === projectUrn)?.project
    setProjectState(project)
  }, [dispatch, projectUrn])

  const onSubmit = useCallback(
    async (data: typeof defaultValues) => {
      const { name } = data
      if (!name) {
        return
      }
      await dispatch(
        updateProject({
          urn: projectUrn,
          name,
          hubName: envName,
        }),
      )
      getProject()
    },
    [dispatch, projectUrn, getProject],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(defaultValues), [projectState])

  useEffect(() => {
    if (!projectState) {
      getProject()
    }
  }, [getProject, projectState])

  useEffect(() => {
    dispatch(getRoles({ projectUrn }))
  }, [dispatch, projectUrn])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: Project
    event: MouseEvent
  }>([
    {
      title: 'Архивировать',
      dataTestId: 'projectCtxMenuArchive',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      dataTestId: 'projectCtxMenuDelete',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  if (!projectState) {
    return null
  }
  return (
    <EditUserPageWrapper>
      <ContextMenu />
      <TitleContainer>
        <BackButton
          data-test-id='backToHubBtn'
          onClick={() => history.push(`/hub/${hubId}`)}
        >
          <Pointer />
        </BackButton>
        <CompaniesIcon />
        <Title data-test-id='editProjectPageHeading'>
          Проект: {projectState.name}
        </Title>
        <BtnContainer>
          <TitleButton
            data-test-id='projectActionsBtn'
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item: projectState, event: e },
              })
            }}
            active={Boolean(ctxMenu.item?.item.urn)}
            size='small'
          >
            <ThreeDotsIcon />
          </TitleButton>
        </BtnContainer>
      </TitleContainer>
      <Form>
        <FieldContainer>
          <FieldLabel required>Наименование проекта</FieldLabel>
          <Controller
            name='name'
            control={control}
            rules={{
              required: { message: requiredErrorMessage, value: true },
            }}
            render={({ field }) => (
              <TextField
                data-test-id='projectNameInput'
                {...field}
                onBlur={() => {
                  if (
                    field.value === projectState?.name ||
                    !field.value.trim()
                  ) {
                    return
                  }
                  handleSubmit(onSubmit)()
                }}
                error={Boolean(errors.name)}
                clearable
              />
            )}
          />
          <FieldError hidden={!errors.name}>
            {errors.name && 'message' in errors.name
              ? errors.name.message
              : null}
          </FieldError>
        </FieldContainer>
      </Form>
    </EditUserPageWrapper>
  )
}
