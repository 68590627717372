import React, { SVGAttributes } from 'react'

type SvgComponentType = SVGAttributes<SVGSVGElement> & {
  color?: string
}

export const StarInfoIcon = ({
  color = '#F5A623',
  width = '24',
  height = '24',
  ...other
}: SvgComponentType) => (
  <svg
    width='10'
    height='11'
    viewBox='0 0 10 11'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ flexShrink: 0 }}
    {...other}
  >
    <path
      d='M1.44922 9.04834L3.70654 5.94873L0.314941 4.93799L0.842773 3.25342L4.23438 4.5L4.1333 0.636719H5.84033L5.72803 4.56738L9.07471 3.3208L9.59131 5.03906L6.14355 6.06104L8.35596 9.10449L6.96338 10.1489L4.88574 6.91455L2.85303 10.0703L1.44922 9.04834Z'
      fill={color}
    />
  </svg>
)
