import {
  useEffect,
  useState,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from 'react'
import { useParams, useHistory } from 'react-router'
import { CheckMark, Table, TableColumn, Tabs } from '@gmini/ui-kit'
import qs from 'query-string'
import { useDebounceState } from '@gmini/utils'

import { useContextMenu, useSortTable, defaultSortKeysMap } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'

import { useAppDispatch } from '../../store/store'

import { User, GetUsersPaginatedListParams } from '../../store/users/types'
import {
  deleteUserRoles,
  getUserById,
  sendVerificationEmail,
} from '../../store/users/actions'

import { InfiniteScroll } from '../InfiniteScroll/InfiniteScroll'

import {
  editUrlSearchParams,
  getColumnsFromStorage,
  setColumnsToStorage,
  WidthProviderWrapper,
  getResizeHandler,
} from '../../helpers'

import { AddUserIcon } from '../icons/AddUserIcon'

import {
  ProjectsColumn,
  ProjectsRolesColumn,
  RowsCountText,
  SearchInputWrapper,
  StyledInput,
  StyledMagnifier,
  TableWrapper,
} from './UserList.styled'
import { ProjectsRolesCell } from './ProjectsRolesCell/ProjectsRolesCell'
import { CompanyCell } from './CompanyCell'

type UserListProps = {
  localStoragePrefix: string
  setUserDetails: Dispatch<SetStateAction<User | null>>
  pending: boolean
  users: User[]
  total: number
  size: number
  fetchUserList: (params: GetUsersPaginatedListParams) => void
  noExtendedEditOrDelete?: boolean
  excludeCompanyField?: boolean
  inProject?: boolean
  excludeProjectRolesField?: boolean
  unsavedUserChanges: boolean
  setIsUnsavedChangesPopupOpen: Dispatch<SetStateAction<boolean>>
  activeUsersTab?: number
  setActiveUsersTab?: Dispatch<SetStateAction<number>>
}

type UserListRow = User & {
  email?: string
}

const initColumns: TableColumn<UserListRow>[] = [
  {
    field: 'name',
    name: 'Пользователь',
    visible: true,
    cellStyle: { wordBreak: 'break-all' },
    renderCell: ({ row }) => (
      <WidthProviderWrapper>{row.name}</WidthProviderWrapper>
    ),
  },
  {
    field: 'email',
    name: 'Email',
    visible: true,
    cellStyle: { wordBreak: 'break-all' },
    renderCell: ({ row }) => (
      <WidthProviderWrapper>{row.email}</WidthProviderWrapper>
    ),
  },
  {
    field: 'phone',
    name: 'Телефон',
    visible: true,
    renderCell: ({ row }) => (
      <WidthProviderWrapper>{row.phone}</WidthProviderWrapper>
    ),
  },
]

const projectRolesField: (
  inProject: boolean,
) => TableColumn<UserListRow> = inProject => ({
  field: 'userRoles',
  name: inProject ? 'Роль' : 'Проект / Роль',
  visible: true,
  renderCell: ({ row, rowIdx }) => (
    <WidthProviderWrapper>
      <ProjectsRolesCell
        inProject={inProject}
        userRoles={row.userRoles}
        idx={rowIdx}
      />
    </WidthProviderWrapper>
  ),
  thContent: (
    <ProjectsRolesColumn>
      {!inProject && <ProjectsColumn>Проект</ProjectsColumn>}
      <div>Роль</div>
    </ProjectsRolesColumn>
  ),
})

const companyNameField: TableColumn<UserListRow> = {
  field: 'companyId',
  name: 'Компания',
  visible: true,
  renderCell: ({ row }) => (
    <WidthProviderWrapper>
      <CompanyCell id={row.companyId} />
    </WidthProviderWrapper>
  ),
}

const sendVerifyEmailDateField: TableColumn<UserListRow> = {
  field: 'sendVerifyEmailDate',
  name: 'Дата отправки приглашения',
  visible: true,
  renderCell: ({ row }) => (
    <WidthProviderWrapper>
      {row.sendVerifyEmailDate
        ? new Date(row.sendVerifyEmailDate).toLocaleString('ru-RU')
        : '-'}
    </WidthProviderWrapper>
  ),
}

const isEmailVerifiedField: TableColumn<UserListRow> = {
  field: 'isEmailVerified',
  name: 'Верификация',
  visible: true,
  renderCell: ({ row }) => (
    <WidthProviderWrapper>
      {row.isEmailVerified ? <CheckMark /> : '-'}
    </WidthProviderWrapper>
  ),
}

const getInitColumns = (
  initColumns: TableColumn<UserListRow>[],
  params: {
    excludeCompanyField: boolean
    excludeProjectRolesField: boolean
    inProject: boolean
    isUnverifiedUsersTab: boolean
  },
) => {
  const {
    excludeCompanyField,
    excludeProjectRolesField,
    inProject,
    isUnverifiedUsersTab,
  } = params
  let columns = initColumns
  if (!excludeProjectRolesField) {
    columns = [...columns, projectRolesField(inProject)]
  }
  if (!excludeCompanyField) {
    columns = [...columns, companyNameField]
  }
  if (inProject && isUnverifiedUsersTab) {
    columns = [...columns, sendVerifyEmailDateField, isEmailVerifiedField]
  }
  return columns
}

export const UserList = ({
  setUserDetails,
  pending,
  users,
  total,
  size,
  fetchUserList,
  noExtendedEditOrDelete = false,
  localStoragePrefix,
  excludeProjectRolesField = false,
  excludeCompanyField = false,
  inProject = false,
  setIsUnsavedChangesPopupOpen,
  unsavedUserChanges,
  activeUsersTab,
  setActiveUsersTab,
}: UserListProps) => {
  const initSearch = qs.parse(window.location.search).search?.toString() || ''
  const selectedSortField =
    qs.parse(window.location.search)[defaultSortKeysMap.fieldKey]?.toString() ||
    null
  const selectedSortOperator =
    qs
      .parse(window.location.search)
      [defaultSortKeysMap.operatorKey]?.toString() || null
  const history = useHistory()

  const [page, setPage] = useState(0)
  const params = useParams<{
    hubId: string
    projectUrn: string
    companyId: string
  }>()
  const projectUrn = params.projectUrn || 'default'
  const { companyId } = params
  const { state: inputValue, submit: onInputChange } = useDebounceState<string>(
    {
      onChange: val => {
        const trimmedVal = val.trim()
        const prev = qs.parse(window.location.search)
        const search = qs.stringify(
          { ...prev, search: trimmedVal },
          { skipEmptyString: true },
        )
        history.replace({ search })
      },
      delay: 500,
      initialState: initSearch,
    },
  )

  const dispatch = useAppDispatch()
  const [columns, setColumns] = useState(
    getColumnsFromStorage(
      `${localStoragePrefix}UserListColumnOrder${activeUsersTab}`,
      getInitColumns(initColumns, {
        excludeCompanyField,
        excludeProjectRolesField,
        inProject,
        isUnverifiedUsersTab: activeUsersTab === 2,
      }),
    ),
  )

  useEffect(() => {
    setColumns(
      getColumnsFromStorage(
        `${localStoragePrefix}UserListColumnOrder${activeUsersTab}`,
        getInitColumns(initColumns, {
          excludeCompanyField,
          excludeProjectRolesField,
          inProject,
          isUnverifiedUsersTab: activeUsersTab === 2,
        }),
      ),
    )
  }, [
    activeUsersTab,
    excludeCompanyField,
    excludeProjectRolesField,
    inProject,
    localStoragePrefix,
  ])

  useEffect(() => {
    fetchUserList({
      page: 0,
      size,
      ...(initSearch ? { search: initSearch } : null),
    })
    setPage(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initSearch, activeUsersTab])

  useEffect(() => {
    if (page === 0) {
      return
    }
    const search = qs.parse(window.location.search).search?.toString() || ''
    fetchUserList({ page, size, ...(search ? { search } : null) })
  }, [page, fetchUserList, size])

  useEffect(() => {
    setColumnsToStorage(
      `${localStoragePrefix}UserListColumnOrder${activeUsersTab}`,
      columns,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, localStoragePrefix])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: User
    event: MouseEvent
  }>([
    {
      title: 'Редактировать',
      dataTestId: 'userListCtxMenuEdit',
      onClick: props => {
        setUserDetails(props.item)
      },
      icon: Icon.EDIT,
    },
    {
      title: 'Отправить приглашение повторно',
      dataTestId: 'userListCtxMenuSendInvitation',
      onClick: async props => {
        await dispatch(sendVerificationEmail(props.item.id)).unwrap()
        setPage(0)
        const search = qs.parse(window.location.search).search?.toString() || ''
        fetchUserList({ page: 0, size, ...(search ? { search } : null) })
      },
      customIcon: <AddUserIcon />,
      show: () => inProject && activeUsersTab === 2,
    },
    {
      title: 'Архивировать',
      dataTestId: 'userListCtxMenuArchive',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      show: () => false,
    },
    {
      title: 'Удалить',
      dataTestId: 'userListCtxMenuDelete',
      onClick: async ({ item: { name, userRoles, id } }) => {
        const roleIds = userRoles
          .find(r => r.projectUrn === projectUrn)
          ?.roles.map(r => r.id)
        if (roleIds) {
          await dispatch(
            deleteUserRoles({
              id,
              name,
              toastrText: 'Пользователь удален',
              roleIds,
              projectUrn,
            }),
          )
          getUserById({ id, projectUrn, companyId })
        }
      },
      icon: Icon.DELETE,
      show: () => !noExtendedEditOrDelete,
    },
  ])

  const { renderTh, isSorted, SortContextMenu } = useSortTable({
    selectedSortField,
    selectedSortOperator,
    getFieldKey: (col: TableColumn<UserListRow>) => col.field,
    unSortableFields: [
      'name',
      'email',
      'phone',
      'userRoles',
      'companyId',
      'sendVerifyEmailDate',
      'isEmailVerified',
    ],
    onSort: params => editUrlSearchParams(params, history),
    onResize: getResizeHandler(setColumns),
  })

  const listIsNotOver = (total || 0) > (page + 1) * size
  const showedUsersCount = !listIsNotOver ? total : (page + 1) * size
  return (
    <>
      {' '}
      <SearchInputWrapper>
        <StyledMagnifier />
        <StyledInput
          onChange={val => onInputChange(val)}
          value={inputValue}
          clearable
          placeholder='Поиск'
          data-test-id='userListSearchInput'
        />
      </SearchInputWrapper>
      {typeof activeUsersTab === 'number' && setActiveUsersTab && (
        <Tabs
          tabs={[
            {
              title: 'Все пользователи',
              content: null,
              dataTestId: 'allUsersTabBtn',
            },
            {
              title: 'Верифицированные',
              content: null,
              dataTestId: 'activeUsersTabBtn',
            },
            {
              title: 'Не верифицированные',
              content: null,
              dataTestId: 'unverifiedUsersTabBtn',
            },
          ]}
          onChangeTab={setActiveUsersTab}
          activeTabIndex={activeUsersTab}
          headerStyles={{ margin: '0 24px', flexShrink: 0 }}
        />
      )}
      <TableWrapper>
        {!pending && <ContextMenu />}
        <SortContextMenu />
        <InfiniteScroll
          hasMore={total !== 0 && listIsNotOver}
          next={() => {
            setPage(prev => prev + 1)
          }}
          triggersObserve={[users]}
        >
          <Table
            columns={columns}
            rows={users}
            onRowCtxMenu={(e, item) => {
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item, event: e },
              })
            }}
            pending={pending}
            activeRowKey={ctxMenu.item?.item.id}
            getRowKey={row => row.id}
            onChangeColumns={setColumns}
            onClick={(e, item) => {
              if (unsavedUserChanges) {
                setIsUnsavedChangesPopupOpen(true)
                return
              }
              setUserDetails(item)
            }}
            renderTh={renderTh}
            isSelectedCol={isSorted}
            data-test-id='usersTable'
            trProps={rowKey => ({ 'data-test-id': `usersTableRow_${rowKey}` })}
          />
        </InfiniteScroll>
        <RowsCountText data-test-id='tableBottomItemsCount'>
          Показаны 1 - {showedUsersCount} из {total}
        </RowsCountText>
      </TableWrapper>
    </>
  )
}
