import styled, { css } from 'styled-components'
import { ArrowDown as ArrowDownInit, Tooltip } from '@gmini/ui-kit'

import { RoleBadge } from '../../RoleBadge/RoleBadge'

export const ProjectsRolesWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }
`

export const ProjectsItemWrapper = styled.div`
  position: relative;
  overflow-x: clip;
  width: 280px;
  padding-right: 30px;
`

const ItemCss = css`
  display: flex;
  max-width: 250px;
  flex-direction: column;
  font-size: 14px;
  margin-right: 50px;
`

type ProjectsItemProps = {
  expanded: boolean
}

export const ProjectsItem = styled.div`
  ${ItemCss}
  overflow: hidden;
  max-width: unset;
  margin-right: 0;
  max-height: ${({ expanded }: ProjectsItemProps) =>
    expanded ? 'unset' : '40px'};
`

type NameProps = {
  unchosen: boolean
}

export const Name = styled.div`
  width: fit-content;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: rgba(53, 59, 96, 1);
  white-space: nowrap;
  padding: 2px 6px;
  border-radius: 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  max-width: 100%;
  &:hover {
    background: rgba(76, 94, 207, 0.15);
  }
  opacity: ${({ unchosen }: NameProps) => (unchosen ? '0.4' : '1')};
`

export const ShowAllButton = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: #4c5ecf;
  padding: 2px 6px;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    background: rgba(76, 94, 207, 0.15);
  }
`
type ArrowDownProps = {
  expanded: boolean
}
export const ArrowDown = styled(ArrowDownInit)`
  transition: all ease-in 0.3s;
  ${({ expanded }: ArrowDownProps) =>
    expanded
      ? css`
          transform: rotateX(180deg);
        `
      : ''}
`
type RolesItemWrapperProps = {
  expanded: boolean
  inProject: boolean
}
export const RolesItemWrapper = styled.div`
  position: relative;
  overflow-x: clip;
  display: flex;
  ${({ inProject }: RolesItemWrapperProps) =>
    !inProject ? 'flex-direction: column;' : ''}
  height: ${({ expanded }: RolesItemWrapperProps) =>
    expanded ? 'unset' : '40px'};
  overflow-y: clip;
`

export const StyledRoleBadge = styled(RoleBadge)``

type ProjectRolesLineProps = {
  unchosen: boolean
}
export const ProjectRolesLine = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0;
  gap: 4px;
  ${({ unchosen }: ProjectRolesLineProps) =>
    unchosen
      ? css`
          ${StyledRoleBadge} {
            background: rgba(0, 5, 100, 0.04);
            div {
              color: rgba(210, 211, 225, 1);
            }
          }
        `
      : ''}
`
export const ShowAllButtonMock = styled(ShowAllButton)`
  pointer-events: none;
  visibility: hidden;
  height: 20px;
`
export const TooltipStyled = styled(Tooltip)`
  width: max-content;
  max-width: 100%;
`
