import { SvgComponentType } from '@gmini/ui-kit'

export const AddUserIcon = ({ color = '#fff', ...other }: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g clipPath='url(#clip0_2153_110261)'>
      <path
        d='M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C12.79 4 11 5.79 11 8C11 10.21 12.79 12 15 12ZM15 14C12.33 14 7 15.34 7 18V19C7 19.5523 7.44772 20 8 20H22C22.5523 20 23 19.5523 23 19V18C23 15.34 17.67 14 15 14ZM5 15C5.55228 15 6 14.5523 6 14V12H8C8.55228 12 9 11.5523 9 11C9 10.4477 8.55228 10 8 10H6V8C6 7.44772 5.55228 7 5 7C4.44772 7 4 7.44772 4 8V10H2C1.44772 10 1 10.4477 1 11C1 11.5523 1.44772 12 2 12H4V14C4 14.5523 4.44772 15 5 15Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_2153_110261'>
        <rect width='24' height='24' fill={color} />
      </clipPath>
    </defs>
  </svg>
)
