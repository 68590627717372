import { IconButton } from '@gmini/ui-kit'
import styled, { css } from 'styled-components'

import { AttributesInfo } from '../AttributeTab/AttributesInfo'

export const UserListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-width: 0;
`
export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #353b60;
  padding: 32px 24px;
  padding-bottom: 0;
  box-shadow: 0px -20px 20px 9px;
  clip-path: polygon(0 0, 100% 0, 100% 200%, 0 200%);
`

export const TitleHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`
export const TabsBar = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #e5e7f1 0 85%, transparent 85% 100%);
    border-radius: 30px;
  }
`

type TabProps = {
  disabled?: boolean
  selected: boolean
}
export const Tab = styled.div`
  position: relative;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: rgba(162, 163, 183, 1);
  cursor: pointer;
  padding: 23px 10px;
  min-width: max-content;
  &:hover {
    color: rgba(76, 94, 207, 1);
  }
  ${({ selected }: TabProps) =>
    selected
      ? css`
          color: rgba(76, 94, 207, 1);
          &::after {
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
            content: '';
            background: rgba(76, 94, 207, 1);
            height: 4px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
          }
        `
      : ''}
  ${({ disabled }: TabProps) =>
    disabled
      ? css`
          pointer-events: none;
          opacity: 0.5;
        `
      : ''}
`

export const StyledIconButton = styled(IconButton)`
  margin: 17px 0 14px;
`

export const StyledAttributesInfo = styled(AttributesInfo)`
  margin-left: 50px;
  margin-top: 50px;
`
