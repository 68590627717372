import { Tooltip } from '@gmini/ui-kit'
import { useParams } from 'react-router'

import { useAppSelector } from '../../store/store'

import { User, UserType } from '../../store/users/types'
import {
  EmailAndPhone,
  Name,
  TextBlock,
  UserBadge,
  UserInfoWrapper,
} from '../AddUserPopup/AddUserPopup.styled'
import { InfoIconRed } from '../icons/InfoIconRed'
import { RoleBadge } from '../RoleBadge/RoleBadge'
import { AdditionalRolesCount } from '../RoleBadge/RoleBadge.styled'

import {
  BadgeTooltipWrapper,
  Divider,
  Header,
  Info,
  NoRoles,
  RoleListItem,
  RoleTable,
  UserTypeInfoPanelWrapper,
} from './UserTypeInfoPanel.styled'

type UserTypeInfoPanelProps = {
  userType: UserType
  user: User | null
}

export const UserTypeInfoPanel = ({
  userType,
  user,
}: UserTypeInfoPanelProps) => {
  const { setByUrn: projectsSet } = useAppSelector(state => state.projects)
  const params = useParams<{ projectUrn: string }>()
  const { projectUrn } = params

  if (!user) {
    return null
  }

  const { name, phone, userRoles, email } = user

  const currProjectRoles = userRoles.find(
    r => r.projectUrn === projectUrn,
  )?.roles

  if (userType === UserType.ACCOUNT) {
    return (
      <UserTypeInfoPanelWrapper>
        <UserInfoWrapper>
          <UserBadge bg='rgba(76, 94, 207, 0.15)'>
            {`${name[0]}${name[name.indexOf(' ') + 1]}`}
          </UserBadge>
          <TextBlock>
            <Name>{name}</Name>
          </TextBlock>
        </UserInfoWrapper>
        <EmailAndPhone>
          <span>{email}</span>
          <span>{phone}</span>
        </EmailAndPhone>
        <RoleTable>
          {userRoles.length ? (
            <>
              <Header>
                <span>Проект</span>
                <span>Роль</span>
              </Header>
              {userRoles.map(({ projectUrn, roles }) =>
                roles.map(({ id, title }) => (
                  <RoleListItem key={`${projectUrn}${id}`}>
                    <span>{projectsSet[projectUrn]?.name || projectUrn}</span>
                    <RoleBadge roleId={id} roleTitle={title} />
                  </RoleListItem>
                )),
              )}
            </>
          ) : (
            <NoRoles>У этого пользователя пока нет ролей</NoRoles>
          )}
        </RoleTable>
      </UserTypeInfoPanelWrapper>
    )
  } else if (userType === UserType.PROJECT) {
    return (
      <UserTypeInfoPanelWrapper>
        <Info>
          <InfoIconRed />
          <span>
            Пользователь уже добавлен в проект {projectsSet[projectUrn]?.name}
          </span>
        </Info>
        <Divider />
        <UserInfoWrapper>
          <UserBadge bg='rgba(76, 94, 207, 0.15)'>
            {`${name[0]}${name[name.indexOf(' ') + 1]}`}
          </UserBadge>
          <TextBlock>
            <Name>{name}</Name>
          </TextBlock>
          {currProjectRoles ? (
            <Tooltip
              TransitionProps={{
                style: {
                  background: 'rgba(241, 241, 241, 0.95)',
                  padding: '3px 6px',
                },
              }}
              title={currProjectRoles.map(r => (
                <BadgeTooltipWrapper key={r.id}>
                  <RoleBadge roleId={r.id} roleTitle={r.title} />
                </BadgeTooltipWrapper>
              ))}
            >
              <>
                <RoleBadge
                  roleId={currProjectRoles[0].id}
                  roleTitle={currProjectRoles[0].title}
                />
                {currProjectRoles.length > 1 ? (
                  <AdditionalRolesCount>{`+${
                    currProjectRoles.length - 1
                  }`}</AdditionalRolesCount>
                ) : null}
              </>
            </Tooltip>
          ) : null}
        </UserInfoWrapper>
        <EmailAndPhone>
          <span>{email}</span>
          <span>{phone}</span>
        </EmailAndPhone>
      </UserTypeInfoPanelWrapper>
    )
  }
  return null
}
