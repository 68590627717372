import { SvgComponentType } from '@gmini/ui-kit'

export const ResetArrowIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g clipPath='url(#clip0_519_432718)'>
      <path
        d='M5.60094 10.6C7.45094 8.99 9.85094 8 12.5009 8C17.1509 8 21.0809 11.03 22.4609 15.22L20.1009 16C19.0509 12.81 16.0509 10.5 12.5009 10.5C10.5509 10.5 8.77094 11.22 7.38094 12.38L11.0009 16H2.00094V7L5.60094 10.6Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_519_432718'>
        <rect
          width='24'
          height='24'
          fill='white'
          transform='matrix(-1 0 0 1 24 0)'
        />
      </clipPath>
    </defs>
  </svg>
)
