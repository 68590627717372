import { useState } from 'react'
import { AddAfter, IconButton, Popup, RoundedCross } from '@gmini/ui-kit'

import { toastr } from 'react-redux-toastr'

import { AttributeGroup } from '../../store/attributes/types'
import { FolderBigIcon } from '../icons/FolderBigIcon'
import { AddFolderIcon } from '../icons/AddFolderIcon'

import {
  getAttribute,
  updateAttributeValue,
} from '../../store/attributes/actions'

import { useAppDispatch, useAppSelector } from '../../store/store'

import { AddAttributeValueGroupPopup } from '../AddAttributeValueGroupPopup/AddAttributeValueGroupPopup'

import { SnackbarWrapper } from '../toastrOptions/ToastrSnackbar.styled'

import { AttributeValuesContainer } from '../LinkAttributeValuesToProjectModal/LinkAttributeValuesToProjectModal.styled'

import { projectModuleValueSplitter } from '../../store/attributes/attributes-slice'

import {
  AddAttributeValueGroupBtn,
  Button,
  Container,
  Footer,
  GroupListItem,
  Header,
  PopupContent,
  StyledButton,
  StyledValueName,
  Text,
  Title,
} from './MoveAttributeValueModal.styled'

type MoveAttributeValueModalProps = {
  onClose: (reset?: boolean) => void
  groupList: AttributeGroup[]
  isOpen: boolean
  checkedAttributeValuesIdsList: string[]
  altSubmit: (data: {
    action: () => Promise<unknown>
    groupId: number
    actionType: 'create' | 'move'
  }) => void
}

export const MoveAttributeValueModal = ({
  altSubmit,
  onClose,
  groupList,
  isOpen,
  checkedAttributeValuesIdsList,
}: MoveAttributeValueModalProps) => {
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null)
  const [
    isAddAttributeValueGroupPopupOpen,
    setIsAddAttributeValueGroupPopupOpen,
  ] = useState(false)
  const dispatch = useAppDispatch()
  const {
    id: attributeId,
    childrenFlatMap,
    projectModuleValuesMap,
  } = useAppSelector(state => state.currentAttribute)

  const handleSubmit = async () => {
    if (typeof selectedGroupId !== 'number') {
      return
    }

    const actions = checkedAttributeValuesIdsList.map(id =>
      updateAttributeValue({
        attributeId,
        valueId: Number(id),
        parentGroupId: selectedGroupId,
        name: childrenFlatMap[Number(id)].name,
        noAttributeFetch: true,
      }),
    )
    if (selectedGroupId === 0) {
      await Promise.all(actions.map(a => dispatch(a)))
      await dispatch(getAttribute({ attributeId }))
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>
            Значения атрибута перемещены в корень списка
          </SnackbarWrapper>
        ),
      })
    } else if (
      !Object.keys(projectModuleValuesMap).filter(
        key =>
          key.includes(projectModuleValueSplitter) &&
          childrenFlatMap[Number(key.split(projectModuleValueSplitter)[1])]
            .parentGroupId === selectedGroupId,
      ).length
    ) {
      await Promise.all(actions.map(a => dispatch(a)))
      await dispatch(getAttribute({ attributeId }))
      toastr.info('', '', {
        icon: <div />,
        component: (
          <SnackbarWrapper>
            Значения атрибута перемещены в группу
          </SnackbarWrapper>
        ),
      })
    } else {
      altSubmit({
        action: () => Promise.all(actions.map(a => dispatch(a))),
        groupId: selectedGroupId,
        actionType: 'move',
      })
    }
    handleClose(true)
  }
  const handleClose = (reset?: boolean) => {
    setSelectedGroupId(null)
    onClose(reset)
  }
  return (
    <Popup width={'640px'} open={isOpen} onClose={handleClose}>
      <Container>
        <AddAttributeValueGroupPopup
          open={isAddAttributeValueGroupPopupOpen}
          onClose={() => setIsAddAttributeValueGroupPopupOpen(false)}
        />

        <Header>
          <Title data-test-id='moveAttributeValueModalHeading'>
            Переместить значения атрибута
          </Title>
          <IconButton type='square'>
            <RoundedCross
              data-test-id='closeMoveAttributeValueModal'
              onClick={() => handleClose()}
            />
          </IconButton>
        </Header>
        <AttributeValuesContainer>
          {checkedAttributeValuesIdsList.map(id => (
            <StyledValueName key={id}>
              {childrenFlatMap[Number(id)].name}
            </StyledValueName>
          ))}
        </AttributeValuesContainer>
        <Text>
          Выберите группу, куда необходимо переместить значения атрибута.
          <br />
          Далее, значения можно будет добавить в проекты, где представлена
          группа.
        </Text>
        <PopupContent>
          {!groupList.length ? (
            <AddAttributeValueGroupBtn
              onClick={() => setIsAddAttributeValueGroupPopupOpen(true)}
              color='secondary'
              data-test-id='addAttributeValueGroupPopupOpenBtn'
            >
              <AddFolderIcon />
              Создать группировку
            </AddAttributeValueGroupBtn>
          ) : (
            <>
              <GroupListItem
                selected={selectedGroupId === 0}
                onClick={() => setSelectedGroupId(0)}
                key={0}
                data-test-id='moveAttributeValueModalListItem_root'
              >
                <AddAfter color='rgba(128, 129, 154, 1)' />
                <span>В корень списка</span>
              </GroupListItem>

              {groupList.map((g, idx) => (
                <GroupListItem
                  data-test-id={`moveAttributeValueModalListItem_${idx}`}
                  selected={g.id === selectedGroupId}
                  onClick={() => setSelectedGroupId(g.id)}
                  key={g.id}
                >
                  <FolderBigIcon color='rgba(128, 129, 154, 1)' />
                  <span>{g.name}</span>
                </GroupListItem>
              ))}
            </>
          )}
        </PopupContent>
        <Footer>
          <Button
            data-test-id='moveAttributeValueModalCancelBtn'
            onClick={() => handleClose()}
          >
            Отменить
          </Button>

          <StyledButton
            data-test-id='moveAttributeValueModalConfirmBtn'
            disabled={typeof selectedGroupId !== 'number'}
            onClick={handleSubmit}
          >
            Переместить
          </StyledButton>
        </Footer>
      </Container>
    </Popup>
  )
}
