import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import { DndProvider } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend'

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import '../components/toastrOptions/toastrCorrections.css'
import './globalStyles.css'

import { DefaultSnackbarProvider, muiTheme, OfflineModal } from '@gmini/ui-kit'

import { MetrikaProvider } from '@gmini/common/lib/metrika/provider'

import ReduxToastr from 'react-redux-toastr'

import { store } from '../store/store'
import { CustomHTML5toTouch } from '../components/dnd'

import { AppContent } from './AppContent'

export const App = (): JSX.Element => (
  <Provider store={store}>
    <MetrikaProvider>
      <BrowserRouter>
        <ThemeProvider theme={muiTheme}>
          <DndProvider
            backend={MultiBackend as any}
            options={CustomHTML5toTouch}
          >
            <DefaultSnackbarProvider>
              <CssBaseline />
              <Helmet>
                <link
                  rel='stylesheet'
                  href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
                />
              </Helmet>

              <OfflineModal />
              <ReduxToastr
                timeOut={3000}
                newestOnTop={true}
                preventDuplicates={false}
                position='top-center'
                transitionIn='fadeIn'
                transitionOut='fadeOut'
                progressBar={false}
              />
              <Route
                path={['/hub/:hubId', '/']}
                render={() => <AppContent />}
              />
            </DefaultSnackbarProvider>
          </DndProvider>
        </ThemeProvider>
      </BrowserRouter>
    </MetrikaProvider>
  </Provider>
)

App.displayName = 'App'
