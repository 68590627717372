import { IconButton } from '@gmini/ui-kit'
import styled from 'styled-components'

export const EditUserPageWrapper = styled.div`
  width: 100%;
  padding: 24px;
  padding-bottom: 0;
  height: inherit;
  display: flex;
  flex-direction: column;

  & > :last-child {
    flex: 1;
    padding-bottom: 0;
  }
`

export const Title = styled.div`
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.1px;
  color: #353b60;
  margin-left: 4px;
`
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  svg {
    flex-shrink: 0;
  }
`

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 28px;
`

export const TitleButton = styled(IconButton)`
  padding: 0;
  margin-left: 'auto';
  margin-right: '6px';

  & svg {
    width: 20px;
    height: 20px;
    & path {
      fill: #353b60;
    }
  }
`
export const Form = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  flex-wrap: wrap;
`
export const FieldContainer = styled.div`
  width: 48%;
`
export const BackButton = styled(IconButton)`
  margin-right: 12px;
  width: 24px;
  height: 24px;
  path {
    fill: #4c5ecf;
  }
`
