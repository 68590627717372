import styled, { css } from 'styled-components'
import { Shevron as ShevronInit } from '@gmini/ui-kit'

type CollapseButtonProps = {
  open?: boolean
}

export const CollapseButton = styled(ShevronInit)`
  transform: ${(props: CollapseButtonProps) =>
    props.open ? 'rotate(270deg)' : 'rotate(90deg)'};
  transition: all 0.2s ease-out;
  width: 10px;
  min-width: 10px;
  height: 10px;

  path {
    transition: all 0.2s ease-out;
  }
`

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #353b60;
  letter-spacing: 0.15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-out;
  padding: 21px 16px;
`

type ContainerProps = {
  open?: boolean
}

export const Container = styled.div`
  position: relative;
  border: 1px solid #e5e7f1;
  border-radius: 4px;
  cursor: pointer;

  ${({ open }: ContainerProps) =>
    open &&
    css`
      cursor: default;
    `}
`

export const Content = styled.div`
  padding-top: 0;
`

type ContentWrapperProps = {
  open?: boolean
  height: number
}

export const ContentWrapper = styled.div`
  max-height: 0;
  overflow-y: hidden;
  transition: all 0.2s ease-out;

  ${(props: ContentWrapperProps) =>
    props.open &&
    css`
      max-height: ${props.height}px;
    `}
`

export const Badge = styled.div`
  position: absolute;
  right: 41px;
  top: 22px;
  pointer-events: none;
`
