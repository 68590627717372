import {
  Action,
  AnyAction,
  configureStore,
  Dispatch,
  isRejectedWithValue,
  MiddlewareAPI,
  ThunkAction,
} from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { enableES5 } from 'immer'

import { resolveExceptionError } from './error/resolveExceptionError'

import { rootReducer } from './root-reducer'

// TODO move to application start
const SHOW_VALIDATION_ERRORS = true

enableES5()

const errorInterceptor =
  ({ dispatch }: MiddlewareAPI<Dispatch<any>, any>) =>
  (next: Dispatch<AnyAction>) =>
  (action: AnyAction) => {
    if (isRejectedWithValue(action)) {
      const payload = action.payload as Error

      dispatch(
        resolveExceptionError({
          error: payload,
          showValidationErr: SHOW_VALIDATION_ERRORS,
        }),
      )
    }
    return next(action)
  }

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(errorInterceptor),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const useAppDispatch = <
  T extends Dispatch<AnyAction> = AppDispatch,
>(): T => useDispatch<T>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
