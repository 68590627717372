import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import { Checkbox } from '@gmini/ui-kit'

import {
  AttributeInstances,
  AttributeValue,
  ModuleAttributeValue,
} from '../../store/attributes/types'

import { ArrowExpandMore } from '../icons/ArrowExpandMore'

import { DashboardIcon } from '../icons/DashboardIcon'

import {
  projectModuleSplitter,
  projectModuleValueSplitter,
} from '../../store/attributes/attributes-slice'

import { useAppSelector } from '../../store/store'

import {
  ExpandButton,
  GroupName,
  Item,
  ItemSide,
} from './AttributeValueGroupItem.styled'
import { AttributeValueGroupItem } from './AttributeValueGroupItem'
import { AttributeValueItem } from './AttributeValueItem'

type AttributeModuleItemProps = {
  projectUrn: string
  item: ModuleAttributeValue
  dataTestIdPrefix: string
  checkedMap: { [x: string]: boolean }
  setCheckedMap: Dispatch<SetStateAction<{ [x: string]: boolean }>>
  nestingLevel?: number
  isBaseAttributeValuesChecked: boolean
}

export const AttributeModuleItem = ({
  projectUrn,
  item,
  dataTestIdPrefix,
  checkedMap,
  setCheckedMap,
  nestingLevel = 0,
  isBaseAttributeValuesChecked,
}: AttributeModuleItemProps) => {
  const [expanded, setExpanded] = useState(false)
  const { name, attributeValues, id } = item
  const { groupMap } = useAppSelector(state => state.currentAttribute)

  const attributeValuesNamesIndexesMap = useMemo(
    () =>
      attributeValues.reduce(
        (acc: { [x: string]: number }, v, idx) => ({
          ...acc,
          [v.parentGroupId ? groupMap[v.parentGroupId]?.name : v.name]: idx,
        }),
        {},
      ),
    [attributeValues, groupMap],
  )
  const attributeGroupValuesList = useMemo(
    () =>
      Object.entries(
        attributeValues.reduce<{
          [x: string]: AttributeValue | AttributeValue[]
        }>((acc, v) => {
          const group = acc[`${v.parentGroupId}_group`]
          return {
            ...acc,
            ...(v.parentGroupId
              ? {
                  [`${v.parentGroupId}_group`]: [
                    ...(group instanceof Array ? group : []),
                    v,
                  ],
                }
              : { [v.id]: v }),
          }
        }, {}),
      )
        .map(([key, value]) =>
          value instanceof Array
            ? { ...groupMap[Number(key.split('_group')[0])], children: value }
            : value,
        )
        .sort(
          (a, b) =>
            attributeValuesNamesIndexesMap[a.name] -
            attributeValuesNamesIndexesMap[b.name],
        ),
    [attributeValues, attributeValuesNamesIndexesMap, groupMap],
  )

  const keysRelatableToProjectModule = useMemo(
    () =>
      Object.keys(checkedMap).filter(key =>
        key.includes(`${projectUrn}${projectModuleSplitter}${id}`),
      ),
    [checkedMap, projectUrn, id],
  )

  const isChecked = keysRelatableToProjectModule.every(key => checkedMap[key])
  const isCheckedIncomplete = keysRelatableToProjectModule.some(
    key => checkedMap[key],
  )

  useEffect(() => {
    const keysWithoutModuleKey = keysRelatableToProjectModule.filter(
      key => key !== `${projectUrn}${projectModuleSplitter}${id}`,
    )
    if (keysWithoutModuleKey.length) {
      if (
        checkedMap[`${projectUrn}${projectModuleSplitter}${id}`] &&
        keysWithoutModuleKey.every(key => !checkedMap[key])
      ) {
        setCheckedMap(prev => ({
          ...prev,
          [`${projectUrn}${projectModuleSplitter}${id}`]: false,
        }))
      }
      if (
        !checkedMap[`${projectUrn}${projectModuleSplitter}${id}`] &&
        keysWithoutModuleKey.every(key => checkedMap[key])
      ) {
        setCheckedMap(prev => ({
          ...prev,
          [`${projectUrn}${projectModuleSplitter}${id}`]: true,
        }))
      }
    }
  }, [keysRelatableToProjectModule, projectUrn, id, setCheckedMap, checkedMap])

  return (
    <>
      <Item nestedPadding={nestingLevel * 29}>
        <ItemSide>
          <ExpandButton
            data-test-id={`${dataTestIdPrefix}_ExpandBtn`}
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            <ArrowExpandMore color='rgb(128, 129, 154)' />
          </ExpandButton>
          <Checkbox
            data-test-id={`${dataTestIdPrefix}_Checkbox`}
            indeterminate={isCheckedIncomplete && !isChecked}
            checked={isChecked}
            onChange={() =>
              setCheckedMap(prev => ({
                ...prev,
                ...keysRelatableToProjectModule.reduce(
                  (acc, key) => ({
                    ...acc,
                    [key]: isChecked || isCheckedIncomplete ? false : true,
                  }),
                  {},
                ),
              }))
            }
          />
          <DashboardIcon color='rgb(128, 129, 154)' />
          <GroupName>
            {name}{' '}
            {attributeValues.length ? (
              <span>{`(${attributeValues.length})`}</span>
            ) : null}
          </GroupName>
        </ItemSide>
      </Item>
      {expanded &&
        attributeGroupValuesList.map((v, idx) => {
          const keyPrefix = `${projectUrn}${projectModuleSplitter}${id}${projectModuleValueSplitter}`
          const valueKey = `${keyPrefix}${v.id}`
          let isGroupChecked = false,
            isGroupCheckedIncomplete = false
          if (v.type === AttributeInstances.GROUP) {
            isGroupChecked = v.children.every(
              c => checkedMap?.[`${keyPrefix}${c.id}`],
            )
            isGroupCheckedIncomplete = v.children.some(
              c => checkedMap?.[`${keyPrefix}${c.id}`],
            )
          }
          return v.type === AttributeInstances.GROUP ? (
            <AttributeValueGroupItem
              dataTestIdPrefix={`${dataTestIdPrefix}_Group_${idx}`}
              key={v.id}
              group={v}
              renderCheckbox={
                <Checkbox
                  data-test-id={`${dataTestIdPrefix}_Group_${idx}_Checkbox`}
                  checked={isGroupChecked && !isBaseAttributeValuesChecked}
                  indeterminate={
                    isGroupCheckedIncomplete &&
                    !isGroupChecked &&
                    !isBaseAttributeValuesChecked
                  }
                  disabled={isBaseAttributeValuesChecked}
                  onChange={() =>
                    setCheckedMap(prev => ({
                      ...prev,
                      ...v.children.reduce(
                        (acc, c) => ({
                          ...acc,
                          [`${keyPrefix}${c.id}`]:
                            isGroupChecked || isGroupCheckedIncomplete
                              ? false
                              : true,
                        }),
                        {},
                      ),
                    }))
                  }
                />
              }
              renderChildCheckbox={(id, innerIdx) => (
                <Checkbox
                  data-test-id={`${dataTestIdPrefix}_Group_${idx}_${innerIdx}_Checkbox`}
                  checked={
                    checkedMap?.[`${keyPrefix}${id}`] &&
                    !isBaseAttributeValuesChecked
                  }
                  disabled={isBaseAttributeValuesChecked}
                  onChange={() =>
                    setCheckedMap(prev => ({
                      ...prev,
                      [`${keyPrefix}${id}`]: checkedMap?.[`${keyPrefix}${id}`]
                        ? false
                        : true,
                    }))
                  }
                />
              )}
              noAddValue
              nestingLevel={nestingLevel + 1}
            />
          ) : (
            <AttributeValueItem
              dataTestIdPrefix={`${dataTestIdPrefix}_${idx}`}
              key={v.id}
              value={v}
              renderCheckbox={
                <Checkbox
                  data-test-id={`${dataTestIdPrefix}_${idx}_Checkbox`}
                  checked={
                    checkedMap?.[valueKey] && !isBaseAttributeValuesChecked
                  }
                  disabled={isBaseAttributeValuesChecked}
                  onChange={() =>
                    setCheckedMap(prev => ({
                      ...prev,
                      [valueKey]: checkedMap?.[valueKey] ? false : true,
                    }))
                  }
                />
              }
              nestingLevel={nestingLevel + 1}
            />
          )
        })}
    </>
  )
}
