import { Magnifier, TextField } from '@gmini/ui-kit'
import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  min-height: 0;
`
export const ProjectsColumn = styled.div`
  width: 280px;
`
export const ProjectsRolesColumn = styled.div`
  display: flex;
  align-items: center;
`

export const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 300px;
  align-items: center;
  margin: 0 24px 10px;
`

export const StyledMagnifier = styled(Magnifier)`
  position: absolute;
  left: 8px;
  z-index: 1;
  pointer-events: none;
`

export const StyledInput = styled(TextField)`
  padding-left: 37px;
`

export const RowsCountText = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 61, 0.3);
  margin: 15px 0;
`
