import styled from 'styled-components'

import { RoleBadgeWrapper } from '../RoleBadge/RoleBadge.styled'

export const UserTypeInfoPanelWrapper = styled.div`
  padding: 10px 18px 24px 16px;
  border-radius: 4px;
  border: 1px solid #e5e7f1;
  margin-bottom: 14px;
`
export const Divider = styled.div`
  background: #e5e7f1;
  height: 1px;
  margin-top: 8px;
  margin-bottom: 12px;
`

export const Info = styled.div`
  gap: 8px;
  color: #d9575e;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.132923px;
`
export const RoleTable = styled.div`
  margin: 16px 0 3.5px 32px;
`

export const Header = styled.div`
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.15px;
  color: #353b60;
  opacity: 0.7;
  border-bottom: 1px solid #e5e7f1;
  padding-bottom: 3px;
  padding-right: 5px;
`

export const NoRoles = styled.div`
  color: #353b60;
  opacity: 0.7;
`

export const RoleListItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #353b60;

  ${RoleBadgeWrapper} {
    margin-right: 0;
  }
`
export const BadgeTooltipWrapper = styled.div`
  margin-bottom: 3px;
`
