import { useEffect, useState, MouseEvent, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { Tooltip, Table, TableColumn } from '@gmini/ui-kit'
import { useContextMenu, useSortTable, defaultSortKeysMap } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import * as ismApi from '@gmini/ism-api-sdk'
import qs from 'query-string'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { getModuleUsersCount } from '../../store/modules/actions'
import { ModuleUsersCount } from '../../store/modules/types'
import { SimpleScrollbar } from '../ProjectList/ProjectList.styled'

import { DashboardIcon } from '../icons/DashboardIcon'

import {
  editUrlSearchParams,
  getResizeHandler,
  getColumnsFromStorage,
  setColumnsToStorage,
  WidthProviderWrapper,
} from '../../helpers'

import { AttributeValuesTableCell } from '../AttributeValuesTableCell/AttributeValuesTableCell'
import { AttributeValue } from '../../store/attributes/types'

import {
  ListHeading,
  ModulesListWrapper,
  TableWrapper,
  Title,
  TitleCount,
} from './ModulesListPage.styled'

const handleSort = (a: ModuleListRow, b: ModuleListRow) => {
  const key =
    qs.parse(window.location.search)[defaultSortKeysMap.fieldKey]?.toString() ||
    ''
  const sortByOperator =
    qs
      .parse(window.location.search)
      [defaultSortKeysMap.operatorKey]?.toString() || ''
  const isAsc = sortByOperator === ismApi.SortByOperator.ASC
  if (key === 'name') {
    return isAsc
      ? (a?.[key] || '').localeCompare(b?.[key] || '')
      : (b?.[key] || '').localeCompare(a?.[key] || '')
  }
  if (key === 'count') {
    return isAsc
      ? (a?.[key] || 0) - (b?.[key] || 0)
      : (b?.[key] || 0) - (a?.[key] || 0)
  }
  return 0
}

type ModuleListRow = ModuleUsersCount & {
  name?: string
  attributeValues?: AttributeValue[]
}

const initColumns: TableColumn<ModuleListRow>[] = [
  {
    field: 'name',
    name: 'Модуль',
    visible: true,
    cellStyle: { wordBreak: 'break-all' },
    renderCell: ({ row }) => (
      <WidthProviderWrapper>
        {row.name && row.name?.length > 25 ? (
          <Tooltip placement='bottom' title={row.name || ''}>
            <>{row.name?.substring(0, 25)}...</>
          </Tooltip>
        ) : (
          row.name || row.module
        )}
      </WidthProviderWrapper>
    ),
  },
  {
    field: 'count',
    name: 'Пользователи',
    visible: true,
    renderCell: ({ row }) => (
      <WidthProviderWrapper>{row.count}</WidthProviderWrapper>
    ),
  },
  {
    field: 'attributeValues',
    name: 'Атрибуты',
    visible: true,
    renderCell: ({ row, rowIdx }) => (
      <WidthProviderWrapper>
        {row.attributeValues && row.attributeValues.length ? (
          <AttributeValuesTableCell
            attributeValues={row.attributeValues}
            link={(hubId: string) => `/hub/${hubId}/modules/${row.module}/1`}
            dataTestIdPrefix={`modulesTableRow_${rowIdx}`}
          />
        ) : null}
      </WidthProviderWrapper>
    ),
  },
]

export const ModulesListPage = () => {
  const selectedSortField =
    qs.parse(window.location.search)[defaultSortKeysMap.fieldKey]?.toString() ||
    null
  const selectedSortOperator =
    qs
      .parse(window.location.search)
      [defaultSortKeysMap.operatorKey]?.toString() || null
  const params = useParams<{ hubId: string }>()
  const hubId = params.hubId || 'default'

  const [columns, setColumns] = useState(
    getColumnsFromStorage('modulesListColumnOrder', initColumns),
  )
  const history = useHistory()

  const {
    list: modules,
    pending,
    total,
  } = useAppSelector(state => state.modules)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getModuleUsersCount())
  }, [dispatch])

  useEffect(() => {
    setColumnsToStorage('modulesListColumnOrder', columns)
  }, [columns])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: ModuleUsersCount
    event: MouseEvent
  }>([
    {
      title: 'Перейти',
      dataTestId: 'modulesListCtxMenuGo',
      onClick: props => {
        history.push(`/hub/${hubId}/modules/${props.item.module}/0`)
      },
      icon: Icon.SEARCH,
    },
    {
      title: 'Архивировать',
      dataTestId: 'modulesListCtxMenuArchive',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      dataTestId: 'modulesListCtxMenuDelete',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  const { renderTh, isSorted, SortContextMenu } = useSortTable({
    selectedSortField,
    selectedSortOperator,
    getFieldKey: (col: TableColumn<ModuleListRow>) => col.field,
    onSort: params => editUrlSearchParams(params, history),
    onResize: getResizeHandler(setColumns),
  })
  const preparedModules = useMemo(
    () => modules.toSorted(handleSort),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modules, history.location.search],
  )

  return (
    <ModulesListWrapper>
      <ListHeading>
        <Title>
          <DashboardIcon />
          <span data-test-id='modulesHeading'>Модули</span>
          <TitleCount>{total}</TitleCount>
        </Title>
      </ListHeading>
      <TableWrapper>
        {!pending && <ContextMenu />}
        <SortContextMenu />
        <SimpleScrollbar>
          <Table
            columns={columns}
            rows={preparedModules}
            onRowCtxMenu={(e, item) => {
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item, event: e },
              })
            }}
            pending={pending}
            activeRowKey={ctxMenu.item?.item.module}
            getRowKey={row => row.module}
            onChangeColumns={setColumns}
            onClick={(e, item) => {
              history.push(`/hub/${hubId}/modules/${item.module}/0`)
            }}
            renderTh={renderTh}
            isSelectedCol={isSorted}
            data-test-id='modulesTable'
            trProps={rowKey => ({
              'data-test-id': `modulesTableRow_${rowKey}`,
            })}
          />
        </SimpleScrollbar>
      </TableWrapper>
    </ModulesListWrapper>
  )
}
