import { useEffect, useState, MouseEvent, useMemo } from 'react'
import { useHistory, useParams } from 'react-router'
import { Table, TableColumn } from '@gmini/ui-kit'
import { useContextMenu, useSortTable, defaultSortKeysMap } from '@gmini/common'
import { Icon } from '@gmini/common/lib/classifier-editor/ContextMenuItem'
import * as ismApi from '@gmini/ism-api-sdk'
import qs from 'query-string'

import { useAppDispatch, useAppSelector } from '../../store/store'
import { Project, mapSourceTypeDescription } from '../../store/projects/types'
import { getProjects } from '../../store/projects/actions'
import { SimpleScrollbar } from '../ProjectList/ProjectList.styled'

import {
  ModuleScopes,
  checkProjectsPermissionsSelector,
} from '../../store/users/permissions-selectors'

import {
  SearchInputWrapper,
  StyledInput,
  StyledMagnifier,
} from '../UserList/UserList.styled'

import {
  editUrlSearchParams,
  getResizeHandler,
  getColumnsFromStorage,
  setColumnsToStorage,
  WidthProviderWrapper,
} from '../../helpers'

import { AttributeValuesTableCell } from '../AttributeValuesTableCell/AttributeValuesTableCell'

import { TableWrapper } from './ProjectList.styled'

const handleSort = (a: ProjectListRow, b: ProjectListRow) => {
  const key =
    qs.parse(window.location.search)[defaultSortKeysMap.fieldKey]?.toString() ||
    ''
  const sortByOperator =
    qs
      .parse(window.location.search)
      [defaultSortKeysMap.operatorKey]?.toString() || ''
  const isAsc = sortByOperator === ismApi.SortByOperator.ASC
  if (key === 'name' || key === 'projectSource') {
    return isAsc ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key])
  }
  if (key === 'count') {
    return isAsc
      ? (a?.[key] || 0) - (b?.[key] || 0)
      : (b?.[key] || 0) - (a?.[key] || 0)
  }
  if (key === 'archived') {
    const aVal = a[key] === true ? 0 : 1
    const bVal = b[key] === true ? 0 : 1
    return isAsc ? aVal - bVal : bVal - aVal
  }
  return 0
}

type ProjectListProps = {
  projects: Project[]
  pending: boolean
}

type ProjectListRow = Project

const initColumns: TableColumn<ProjectListRow>[] = [
  {
    field: 'name',
    name: 'Проект',
    visible: true,
    cellStyle: { wordBreak: 'break-all' },
    renderCell: ({ row }) => (
      <WidthProviderWrapper>{row.name}</WidthProviderWrapper>
    ),
  },
  {
    renderCell: ({ row }) => (
      <WidthProviderWrapper>
        {mapSourceTypeDescription[row.projectSource]}
      </WidthProviderWrapper>
    ),
    name: 'Ресурс',
    field: 'projectSource',
    visible: true,
  },
  // {
  //   field: 'status',
  //   name: 'Статус',
  //   visible: true,
  //   style: {
  //     width: '145px',
  //   },
  //   renderCell: ({ row }) => <WidthProviderWrapper>{row.status || 'Активный'}</WidthProviderWrapper>,
  // },
  {
    field: 'count',
    name: 'Пользователи',
    visible: true,
    renderCell: ({ row }) => (
      <WidthProviderWrapper>{row.count || 0}</WidthProviderWrapper>
    ),
  },
  {
    field: 'attributeValues',
    name: 'Атрибуты',
    visible: true,
    renderCell: ({ row, rowIdx }) => (
      <WidthProviderWrapper>
        {row.attributeValues && row.attributeValues.length ? (
          <AttributeValuesTableCell
            attributeValues={row.attributeValues}
            link={(hubId: string) => `/hub/${hubId}/project/${row.urn}/1`}
            dataTestIdPrefix={`projectsTableRow_${rowIdx}`}
          />
        ) : null}
      </WidthProviderWrapper>
    ),
  },
]

export const ProjectList = ({ projects, pending }: ProjectListProps) => {
  const selectedSortField =
    qs.parse(window.location.search)[defaultSortKeysMap.fieldKey]?.toString() ||
    null
  const selectedSortOperator =
    qs
      .parse(window.location.search)
      [defaultSortKeysMap.operatorKey]?.toString() || null
  const params = useParams<{ hubId: string }>()
  const hubId = params.hubId || 'default'
  const [inputValue, setInputValue] = useState('')
  const [columns, setColumns] = useState(
    getColumnsFromStorage('projectListColumnOrder', initColumns),
  )
  const history = useHistory()

  const dispatch = useAppDispatch()
  const checkProjectsPermissions = useAppSelector(
    checkProjectsPermissionsSelector,
  )

  useEffect(() => {
    dispatch(getProjects())
  }, [dispatch])

  useEffect(() => {
    setColumnsToStorage('projectListColumnOrder', columns)
  }, [columns])

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    item: Project
    event: MouseEvent
  }>([
    {
      title: 'Перейти',
      dataTestId: 'projectListCtxMenuGo',
      onClick: props => {
        history.push(`/hub/${hubId}/project/${props.item.urn}/0`)
      },
      icon: Icon.SEARCH,
    },
    {
      title: 'Редактировать',
      dataTestId: 'projectListCtxMenuEdit',
      onClick: props => {
        history.push(`/hub/${hubId}/project/${props.item.urn}/edit`)
      },
      icon: Icon.EDIT,
      disabled: () => !checkProjectsPermissions(ModuleScopes.AC_EDIT),
    },
    {
      title: 'Архивировать',
      dataTestId: 'projectListCtxMenuArchive',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.ARCHIVE,
      disabled: () => true,
    },
    {
      title: 'Удалить',
      dataTestId: 'projectListCtxMenuDelete',
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: props => {},
      icon: Icon.DELETE,
      disabled: () => true,
    },
  ])

  const { renderTh, isSorted, SortContextMenu } = useSortTable({
    selectedSortField,
    selectedSortOperator,
    getFieldKey: (col: TableColumn<ProjectListRow>) => col.field,
    onSort: params => editUrlSearchParams(params, history),
    onResize: getResizeHandler(setColumns),
  })

  const preparedProjects = useMemo(
    () =>
      projects
        .filter(p =>
          (p.searchString || '')
            .toLocaleLowerCase()
            .includes(inputValue.toLocaleLowerCase()),
        )
        .toSorted(handleSort),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, projects, history.location.search],
  )

  return (
    <>
      <SearchInputWrapper>
        <StyledMagnifier />
        <StyledInput
          onChange={val => setInputValue(val)}
          value={inputValue}
          clearable
          placeholder='Поиск'
          data-test-id='projectListSearchInput'
        />
      </SearchInputWrapper>
      <TableWrapper>
        {!pending && <ContextMenu />}
        <SortContextMenu />
        <SimpleScrollbar>
          <Table
            columns={columns}
            rows={preparedProjects}
            onRowCtxMenu={(e, item) => {
              e.preventDefault()
              setCtxMenu({
                coords: { x: e.clientX, y: e.clientY },
                item: { item, event: e },
              })
            }}
            pending={pending}
            activeRowKey={ctxMenu.item?.item.urn}
            getRowKey={row => row.urn}
            onChangeColumns={setColumns}
            onClick={(e, item) => {
              history.push(`/hub/${hubId}/project/${item.urn}/0`)
            }}
            renderTh={renderTh}
            isSelectedCol={isSorted}
            data-test-id='projectsTable'
            trProps={rowKey => ({
              'data-test-id': `projectsTableRow_${rowKey}`,
            })}
          />
        </SimpleScrollbar>
      </TableWrapper>
    </>
  )
}
