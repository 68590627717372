import { Dispatch, SetStateAction, useMemo, useRef, useState } from 'react'

import { ClickAwayListener } from '@material-ui/core'

import { useAppSelector } from '../../store/store'
import { RoleBlueBadge } from '../UserRolesDrawerTab/UserRolesDrawerTab.styled'

import {
  CheckboxStyled,
  CrossStyled,
  Description,
  Input,
  Menu,
  Name,
  Option,
  OptionTextWrapper,
  TextInput,
  TextInputWrapper,
  Wrapper,
  CrossCircleButton,
} from './SelectMultipleRolesSingleInput.styled'

type SelectMultipleRolesSingleInputProps = {
  roleIds: number[]
  setRoleIds: Dispatch<SetStateAction<number[]>>
}

export const SelectMultipleRolesSingleInput = ({
  roleIds,
  setRoleIds,
}: SelectMultipleRolesSingleInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [error, setError] = useState(false)
  const [textInput, setTextInput] = useState('')
  const { list: options, setById } = useAppSelector(state => state.roles)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const roleIdsMap = roleIds.reduce(
    (acc: { [x: number]: boolean }, id) => ({ ...acc, [id]: true }),
    {},
  )

  const openMenu = () => {
    inputRef.current?.focus()
    setIsMenuOpen(true)
  }

  const handleSelectRole = (targetId: number) => {
    roleIdsMap[targetId]
      ? setRoleIds(s => {
          const newState = s.filter(id => id !== targetId)
          if (!newState.length) {
            setError(true)
          }
          return newState
        })
      : setRoleIds(s => {
          setError(false)
          return [...s, targetId]
        })
  }

  const filteredOptions = useMemo(
    () =>
      options.filter(o =>
        o.title.toLowerCase().includes(textInput.toLowerCase()),
      ),
    [options, textInput],
  )

  return (
    <Wrapper>
      <ClickAwayListener onClickAway={() => setIsMenuOpen(false)}>
        <div>
          <Input
            data-test-id='editProjectRolesPopupRolesInput'
            active={isMenuOpen}
            onClick={() => openMenu()}
            error={error}
          >
            {roleIds.map((id, idx) => (
              <RoleBlueBadge key={id}>
                {setById[id].title}
                <CrossStyled
                  data-test-id={`editProjectRolesPopupRemoveRoleBtn_${idx}`}
                  onClick={e => {
                    e.stopPropagation()
                    handleSelectRole(id)
                  }}
                />
              </RoleBlueBadge>
            ))}
            <TextInputWrapper>
              <TextInput
                placeholder={roleIds.length ? '' : 'Укажите роль(и)'}
                ref={inputRef}
                value={textInput}
                onChange={e => setTextInput(e.target.value)}
                data-test-id='editProjectRolesPopupRolesInput_Input'
              />
              {textInput && (
                <CrossCircleButton
                  onClick={() => setTextInput('')}
                  color='rgba(218, 218, 218, 1)'
                  data-test-id='editProjectRolesPopupRolesInput_InputResetBtn'
                />
              )}
            </TextInputWrapper>
          </Input>
          {isMenuOpen ? (
            <Menu>
              {filteredOptions.length ? (
                filteredOptions.map((o, idx) => (
                  <Option
                    data-test-id={`editProjectRolesPopupRoleInputOption_${idx}`}
                    key={o.id}
                    onClick={() => handleSelectRole(o.id)}
                  >
                    <CheckboxStyled readOnly checked={roleIdsMap[o.id]} />
                    <OptionTextWrapper>
                      <Name>{o.title}</Name>
                      <Description>{o.description}</Description>
                    </OptionTextWrapper>
                  </Option>
                ))
              ) : (
                <Option>Нет совпадений</Option>
              )}
            </Menu>
          ) : null}
        </div>
      </ClickAwayListener>
    </Wrapper>
  )
}
