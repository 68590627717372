import * as t from 'io-ts'

import Axios, {
  AxiosResponse,
  AxiosInstance,
  AxiosRequestConfig,
  ResponseType,
} from 'axios'
import * as E from 'fp-ts/Either'
import reporter from 'io-ts-reporters'

import { ValidationErrorException } from './exceptions/validationError.exception'

import { normalizeResponse } from './utils'
import { EmptyTokenException } from './exceptions/emptyToken.exception'
import { UnauthorizedException } from './exceptions/unauthorized.exception'
import { ForbiddenException } from './exceptions/forbidden.exception'
import { ApiException } from './exceptions/api.exception'
import { ServerUnavailableException } from './exceptions/serverUnavailable.exception'
import { ErrorException } from './exceptions/error.exception'
interface BaseRequest {
  path: string
  baseUrl?: string
  headers?: Record<string, string>
  abortController?: (controller: AbortController) => void
  decoder?: t.Mixed
  params?: Record<any, any>
  responseType?: ResponseType
  credentials?: boolean
}

interface RequestConfig extends Omit<BaseRequest, 'abortController' | 'path'> {
  signal: AbortSignal
}
interface MixedRequest<T> extends BaseRequest {
  body?: T
}

type CustomRequestConfig = AxiosRequestConfig & {
  decoder?: BaseRequest['decoder']
  isRetryRequest?: boolean
}
interface ApiServiceProps {
  baseUrl: string
  validation?: boolean
  defaultHeaders?: Record<string, string>
  refreshTokenFunc?: () => Promise<string>
}
export class ApiService {
  private token: string | null = null
  private httpRequest: AxiosInstance
  private baseUrl: string
  private validation: boolean
  private defaultHeaders: Record<string, string>
  private refreshToken?: () => Promise<string>

  constructor({
    baseUrl,
    defaultHeaders,
    validation,
    refreshTokenFunc,
  }: ApiServiceProps) {
    this.refreshToken = refreshTokenFunc
    this.defaultHeaders = defaultHeaders ? defaultHeaders : {}
    this.httpRequest = Axios.create()
    this.validation = !!validation
    this.baseUrl = baseUrl
    this.initInterceptors()
  }

  public setToken(token: string) {
    this.token = token
  }

  private clearToken() {
    this.token = null
  }

  private async getAuthHeaders() {
    if (!this.token) {
      const token = await this.refreshToken?.()

      if (!token) {
        throw new EmptyTokenException()
      }

      return { Authorization: `Bearer ${token}` }
    }
    return { Authorization: `Bearer ${this.token}` }
  }

  private async handleError(response: AxiosResponse) {
    switch (response.status) {
      case 401:
      case 551: {
        const { config } = response
        if ('isRetryRequest' in config) {
          throw new UnauthorizedException()
        }

        this.clearToken()

        const authHeaders = await this.getAuthHeaders()

        const currentConfig = {
          ...config,
          isRetryRequest: true,
          headers: { ...config.headers, ...authHeaders },
        }

        return this.httpRequest.request(currentConfig)
      }
      case 403:
        throw new ForbiddenException()

      case 503:
        throw new ServerUnavailableException()

      default: {
        throw new ApiException({
          statusCode: response.status,
          req: {
            url: response.config.url,
            method: response.config.method,
            body: response.config.data,
          },
          data: response.data,
        })
      }
    }
  }

  private async prepareConfig({
    headers,
    baseUrl,
    credentials = true,
    ...data
  }: RequestConfig) {
    const authHeaders = credentials ? await this.getAuthHeaders() : {}

    const currentHeaders = {
      ...this.defaultHeaders,
      ...headers,
      ...authHeaders,
    }
    const config: CustomRequestConfig = {
      headers: currentHeaders,
      baseURL: baseUrl ? baseUrl : this.baseUrl,
      ...data,
    }

    return config
  }

  private initInterceptors() {
    this.httpRequest.interceptors.response.use(
      (response: AxiosResponse<any, any> & { config: CustomRequestConfig }) => {
        if (response.status >= 400) {
          this.handleError(response)
        }

        const { decoder } = response.config

        if (this.validation && decoder) {
          const result = decoder.decode(response.data)

          if (E.isLeft(result)) {
            const errors = reporter.report(result)

            throw new ValidationErrorException({
              errors,
              path: response.config.url || '',
            })
          }
        }
        return response
      },
      err => {
        const message =
          err.response.data.status === 'fail' ? err.response.data.data : ''

        throw new ErrorException({
          status: err.response.status,
          message: message || err.response.data.message || '',
        })
      },
    )
  }

  async get<Res>({
    abortController,
    path,
    ...data
  }: BaseRequest): Promise<[Error, AxiosResponse<Res>]> {
    const controller = new AbortController()
    abortController?.(controller)

    const config = await this.prepareConfig({
      ...data,
      signal: controller.signal,
    })

    return normalizeResponse(this.httpRequest.get<Res>(path, config))
  }

  async post<Req, Res>({
    abortController,
    body,
    path,
    ...data
  }: MixedRequest<Req>): Promise<[Error, AxiosResponse<Res>]> {
    const controller = new AbortController()
    abortController?.(controller)

    const config = await this.prepareConfig({
      ...data,
      signal: controller.signal,
    })

    return normalizeResponse(this.httpRequest.post<Res>(path, body, config))
  }

  async patch<Req, Res>({
    path,
    abortController,
    body,
    ...data
  }: MixedRequest<Req>): Promise<[Error, AxiosResponse<Res>]> {
    const controller = new AbortController()
    abortController?.(controller)

    const config = await this.prepareConfig({
      ...data,
      signal: controller.signal,
    })
    return normalizeResponse(this.httpRequest.patch<Res>(path, body, config))
  }

  async put<Req, Res>({
    path,
    abortController,
    body,
    ...data
  }: MixedRequest<Req>): Promise<[Error, AxiosResponse<Res>]> {
    const controller = new AbortController()
    abortController?.(controller)

    const config = await this.prepareConfig({
      ...data,
      signal: controller.signal,
    })

    return normalizeResponse(this.httpRequest.put<Res>(path, body, config))
  }

  async delete<Res>({
    path,
    abortController,
    ...data
  }: MixedRequest<Res>): Promise<[Error, AxiosResponse<Res>]> {
    const controller = new AbortController()
    abortController?.(controller)

    const config = await this.prepareConfig({
      ...data,
      signal: controller.signal,
    })

    const [err, res] = await normalizeResponse(
      this.httpRequest.delete<Res>(path, { ...config, data: data.body }),
    )

    return [err, res]
  }
}
