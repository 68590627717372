import { createSlice } from '@reduxjs/toolkit'

import { Module, Resource, RoleExtended } from '../roles/types'

import {
  getResources,
  getRoles,
  updateRole,
  getHubPermissions,
} from './actions'

const initialState: {
  list: RoleExtended[]
  setById: { [x: string]: RoleExtended }
  total: number
  pending: boolean
} = {
  list: [],
  setById: {},
  total: 0,
  pending: false,
}

const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        const listWithSearchStrings = payload.map(r => ({
          ...r,
          searchString: `${r.title}${r.description}${Object.values(
            r.resources || {},
          )
            .map(v => v.name)
            .join()}`,
        }))
        const setById = listWithSearchStrings.reduce(
          (acc: { [x: number]: RoleExtended }, r) => ({
            ...acc,
            [r.id]: r,
          }),
          {},
        )
        return {
          list: listWithSearchStrings,
          setById,
          total: payload.length,
          pending: false,
        }
      })
      .addCase(getRoles.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getRoles.pending, (state, _) => ({ ...state, pending: true }))
      .addCase(updateRole.fulfilled, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(updateRole.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(updateRole.pending, (state, _) => ({ ...state, pending: true }))
  },
})

export const rolesReducer = roles.reducer

const initialStateResources: {
  list: Module[]
  total: number
  pending: boolean
} = {
  list: [],
  total: 0,
  pending: false,
}

const resources = createSlice({
  name: 'resources',
  initialState: initialStateResources,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getResources.fulfilled, (state, { payload }) => ({
        list: payload,
        total: payload.length,
        pending: false,
      }))
      .addCase(getResources.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getResources.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const resourcesReducer = resources.reducer

const initialStateHubPermissions: {
  list: Resource[]
  map: { [x: string]: boolean }
  total: number
  pending: boolean
} = {
  list: [],
  map: {},
  total: 0,
  pending: false,
}

const hubPermissions = createSlice({
  name: 'hubPermissions',
  initialState: initialStateHubPermissions,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getHubPermissions.fulfilled, (state, { payload }) => {
        const map = payload.reduce(
          (acc, res) => ({
            ...acc,
            ...res.scopes.reduce(
              (accu, scope) => ({
                ...accu,
                [`${res.resourceId}_${scope.scopeId}`]: false,
              }),
              {},
            ),
          }),
          {},
        )
        return {
          list: payload,
          map,
          total: payload.length,
          pending: false,
        }
      })
      .addCase(getHubPermissions.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getHubPermissions.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const hubPermissionsReducer = hubPermissions.reducer
