import { IconButton, Popup, RoundedCross } from '@gmini/ui-kit'

import { ReactNode } from 'react'

import {
  Button,
  Container,
  Footer,
  Header,
  PopupContent,
  StyledButton,
  Title,
} from './DeletePopup.styled'

type DeletePopupProps = {
  open: boolean
  onClose: () => void
  handleSubmit: () => void
  title: ReactNode
  dataTestIdPrefix: string
  width?: string
  noCross?: boolean
  children: ReactNode
}

export const DeletePopup = ({
  open,
  onClose,
  handleSubmit,
  title,
  dataTestIdPrefix,
  children,
  width = '530px',
  noCross,
}: DeletePopupProps) => (
  <Popup width={width} open={open} onClose={onClose}>
    <Container>
      <Header>
        <Title data-test-id={`delete${dataTestIdPrefix}PopupHeading`}>
          {title}
        </Title>
        {!noCross && (
          <IconButton type='square'>
            <RoundedCross
              data-test-id={`closeDelete${dataTestIdPrefix}Popup`}
              onClick={onClose}
            />
          </IconButton>
        )}
      </Header>
      <PopupContent>{children}</PopupContent>
      <Footer>
        <Button
          data-test-id={`delete${dataTestIdPrefix}PopupCancelBtn`}
          onClick={onClose}
        >
          Отменить
        </Button>

        <StyledButton
          data-test-id={`delete${dataTestIdPrefix}PopupConfirmBtn`}
          onClick={handleSubmit}
        >
          Удалить
        </StyledButton>
      </Footer>
    </Container>
  </Popup>
)
