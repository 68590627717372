import { SvgComponentType } from '@gmini/ui-kit'

export const DashboardIcon = ({
  color = '#353B60',
  ...other
}: SvgComponentType) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...other}
  >
    <g clipPath='url(#clip0_520_390135)'>
      <rect x='3' y='3' width='8' height='11' rx='1' fill={color} />
      <rect x='3' y='16' width='8' height='5' rx='1' fill={color} />
      <path
        d='M13 18C13 18.5523 13.4477 19 14 19H20C20.5523 19 21 18.5523 21 18V12C21 11.4477 20.5523 11 20 11H14C13.4477 11 13 11.4477 13 12V18Z'
        fill={color}
      />
      <path
        d='M13 8C13 8.55228 13.4477 9 14 9H20C20.5523 9 21 8.55228 21 8V6C21 5.44772 20.5523 5 20 5H14C13.4477 5 13 5.44772 13 6V8Z'
        fill={color}
      />
    </g>
    <defs>
      <clipPath id='clip0_520_390135'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
