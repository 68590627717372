import { ActionPopupWrapper } from '@gmini/ui-kit'

import { useForm, Controller } from 'react-hook-form'

import { Dispatch, SetStateAction } from 'react'

import { useAppDispatch } from '../../store/store'

import {
  addNewCompany,
  getCompanies,
  updateCompany,
} from '../../store/companies/actions'

import { Company } from '../../store/companies/types'

import {
  EmptyContainer,
  FieldContainer,
  FieldError,
  FieldLabel,
  Form,
  TextField,
} from './AddOrEditCompanyPopup.styled'

type AddOrEditCompanyPopupProps = {
  open: boolean
  onClose: () => void
  mode?: 'add' | 'edit'
  company?: Company
  setCompany?: Dispatch<SetStateAction<Company | undefined>>
}
const requiredErrorMessage = 'Это поле является обязательным'

const popupButtonsDataTestIds = {
  acceptButton: 'addCompanyPopupConfirmBtn',
  declineButton: 'addCompanyPopupCancelBtn',
}

export const AddOrEditCompanyPopup = ({
  open,
  onClose,
  mode = 'add',
  company,
  setCompany,
}: AddOrEditCompanyPopupProps) => {
  const defaultValues = {
    companyName: company?.companyName || '',
  }

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })
  const dispatch = useAppDispatch()

  const onSubmit = async (data: typeof defaultValues) => {
    const { companyName: companyNameInit } = data
    if (mode === 'add') {
      await dispatch(addNewCompany({ companyName: companyNameInit.trim() }))
      dispatch(getCompanies())
    } else {
      if (!company?.id) {
        return
      }
      const newCompany = await dispatch(
        updateCompany({ id: company?.id, companyName: companyNameInit.trim() }),
      ).unwrap()
      setCompany?.(newCompany)
    }
    reset()
    onClose()
  }

  return (
    <ActionPopupWrapper
      title={
        <span data-test-id='addCompanyPopupHeading'>
          {mode === 'add' ? 'Добавить компанию' : 'Редактировать компанию'}
        </span>
      }
      submitButtonTitle={mode === 'add' ? 'Добавить' : 'Применить'}
      pending={!watch().companyName.trim()}
      open={open}
      width='600px'
      onClose={() => {
        reset()
        onClose()
      }}
      onSubmit={handleSubmit(onSubmit)}
      dataTestIdFor={popupButtonsDataTestIds}
    >
      {({ existScroll }) => (
        <Form>
          <FieldContainer>
            <FieldLabel required>Наименование компании</FieldLabel>
            <Controller
              name='companyName'
              control={control}
              rules={{
                required: { message: requiredErrorMessage, value: true },
              }}
              render={({ field }) => (
                <TextField
                  data-test-id='addCompanyPopupNameInput'
                  {...field}
                  error={Boolean(errors.companyName)}
                  clearable
                />
              )}
            />
            <FieldError hidden={!errors.companyName}>
              {errors.companyName && 'message' in errors.companyName
                ? errors.companyName.message
                : null}
            </FieldError>
          </FieldContainer>
          {existScroll && <EmptyContainer />}
        </Form>
      )}
    </ActionPopupWrapper>
  )
}
