import { createSlice } from '@reduxjs/toolkit'

import { getCompaniesUsersCount } from '../users/actions'

import { getCompanies } from './actions'
import { Company } from './types'

const initialState: {
  list: Company[]
  pending: boolean
  setById: { [x: string]: Company }
  countSet: { [x: string]: number }
  total: number
} = {
  list: [],
  setById: {},
  total: 0,
  pending: false,
  countSet: {},
}

const companies = createSlice({
  name: 'companies',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCompanies.fulfilled, (state, { payload }) => {
        const setById = payload.reduce(
          (acc: { [x: number]: Company }, company) => ({
            ...acc,
            [company.id]: { ...company, count: state.countSet[company.id] },
          }),
          {},
        )
        const fusedList = payload.map(company => ({
          ...company,
          count: state.countSet[company.id],
        }))

        return {
          ...state,
          list: fusedList,
          setById,
          total: payload.length || 0,
          pending: false,
        }
      })
      .addCase(getCompanies.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getCompanies.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getCompaniesUsersCount.fulfilled, (state, { payload }) => {
        const countSet = payload.reduce(
          (acc: { [x: string]: number }, { companyId, count }) => ({
            ...acc,
            [companyId]: count,
          }),
          {},
        )
        return {
          ...state,
          countSet,
        }
      })
  },
})

export const companiesReducer = companies.reducer
