import { useMemo, useState } from 'react'
import {
  AllFolders,
  IconButton,
  Popup,
  RoundedCross,
  Button as UiKitButton,
} from '@gmini/ui-kit'

import {
  ModuleAttributeValue,
  ProjectModuleAttributeValue,
} from '../../store/attributes/types'

import { attachAttributeValueToProject } from '../../store/attributes/actions'

import { useAppDispatch, useAppSelector } from '../../store/store'

import { DashboardIcon } from '../icons/DashboardIcon'

import { projectModuleSplitter } from '../../store/attributes/attributes-slice'

import {
  AttributeValuesContainer,
  Button,
  Container,
  Footer,
  Header,
  ListItemName,
  PopupContent,
  ProjectListItem,
  StyledCircularProgress,
  StyledValueName,
  Text,
  Title,
} from './LinkAttributeValuesToProjectModal.styled'

type LinkAttributeValuesToProjectModalProps = {
  isOpen: boolean
  onClose: (reset?: boolean) => void
  checkedAttributeValuesIdsList: string[]
  checkedProjectModuleValuesMap: {
    [x: string]: boolean
  }
}

export const LinkAttributeValuesToProjectModal = ({
  isOpen,
  onClose,
  checkedAttributeValuesIdsList,
  checkedProjectModuleValuesMap,
}: LinkAttributeValuesToProjectModalProps) => {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const {
    childrenFlatMap,
    name,
    projectModuleValues,
    id: attributeId,
  } = useAppSelector(state => state.currentAttribute)

  const checkedProjectModulesMap = useMemo(
    () =>
      Object.keys(checkedProjectModuleValuesMap)
        .filter(
          key =>
            checkedProjectModuleValuesMap[key] &&
            !key.endsWith(projectModuleSplitter),
        )
        .reduce(
          (
            acc: { [x: string]: { [x: string]: boolean } },
            projectModuleKey,
          ) => {
            const [projectUrn, moduleId] = projectModuleKey.split(
              projectModuleSplitter,
            )
            return {
              ...acc,
              [projectUrn]: { ...(acc[projectUrn] || {}), [moduleId]: true },
            }
          },
          {},
        ),
    [checkedProjectModuleValuesMap],
  )
  const projectModuleValuesFlatMap = useMemo(
    () =>
      projectModuleValues
        .reduce(
          (
            acc: ((ProjectModuleAttributeValue | ModuleAttributeValue) & {
              key: string
            })[],
            p,
          ) => [
            ...acc,
            { key: p.urn, ...p },
            ...p.modules.map(m => ({ key: m.id, ...m })),
          ],
          [],
        )
        .reduce(
          (
            acc: {
              [x: string]: (
                | ProjectModuleAttributeValue
                | ModuleAttributeValue
              ) & {
                key: string
              }
            },
            item,
          ) => ({ ...acc, [item.key]: item }),
          {},
        ),
    [projectModuleValues],
  )
  const dispatch = useAppDispatch()

  const handleSubmit = async () => {
    setIsSubmitLoading(true)
    await dispatch(
      attachAttributeValueToProject({
        attributeId,
        itemValues: Object.entries(checkedProjectModulesMap)
          .flatMap(([projectUrn, moduleIdsMap]) =>
            Object.keys(moduleIdsMap).map(moduleId => ({
              projectUrn,
              moduleId,
            })),
          )
          .reduce(
            (
              acc: { projectUrn: string; moduleId: string; valueId: number }[],
              m,
            ) => [
              ...acc,
              ...checkedAttributeValuesIdsList.map(valueId => ({
                ...m,
                valueId: Number(valueId),
              })),
            ],
            [],
          ),
      }),
    )
    setIsSubmitLoading(false)
    onClose(true)
  }

  return (
    <Popup width={'640px'} open={isOpen} onClose={() => onClose()}>
      <Container>
        <Header>
          <Title data-test-id='linkAttributeValuesToProjectModalHeading'>
            Привязать значения атрибута “{name}”
          </Title>
          <IconButton type='square'>
            <RoundedCross
              data-test-id='closeLinkAttributeValuesToProjectModal'
              onClick={() => onClose()}
            />
          </IconButton>
        </Header>
        <AttributeValuesContainer>
          {checkedAttributeValuesIdsList.map(id => (
            <StyledValueName key={id}>
              {childrenFlatMap[Number(id)].name}
            </StyledValueName>
          ))}
        </AttributeValuesContainer>
        <Text>Вы привязываете значения к следующим проектам:</Text>
        <PopupContent>
          {Object.entries(checkedProjectModulesMap).map(([p, children]) => (
            <ProjectListItem key={p}>
              <ListItemName>
                <AllFolders color='rgba(128, 129, 154, 1)' />
                <span>{projectModuleValuesFlatMap[p].name}</span>
              </ListItemName>
              {Object.keys(children).map(m => (
                <ListItemName key={m} nested>
                  <DashboardIcon color='rgba(128, 129, 154, 1)' />
                  <span>{projectModuleValuesFlatMap[m].name}</span>
                </ListItemName>
              ))}
            </ProjectListItem>
          ))}
        </PopupContent>
        <Footer>
          <Button
            data-test-id='linkAttributeValuesToProjectModalCancelBtn'
            onClick={() => onClose()}
          >
            Отменить
          </Button>

          <UiKitButton
            data-test-id='linkAttributeValuesToProjectModalConfirmBtn'
            onClick={handleSubmit}
            disabled={isSubmitLoading}
            size='regular'
          >
            {isSubmitLoading ? (
              <StyledCircularProgress color='inherit' size={20} />
            ) : (
              'Привязать'
            )}
          </UiKitButton>
        </Footer>
      </Container>
    </Popup>
  )
}
