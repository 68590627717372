import styled, { css } from 'styled-components'
import { IconButton as IconButtonInit } from '@gmini/ui-kit'

type SidebarWrapperProps = {
  folded: boolean
}

export const SidebarWrapper = styled.div`
  padding: 8px;
  border-right: 1px solid #e5e7f1;
  width: ${({ folded }: SidebarWrapperProps) => (folded ? '70px' : '300px')};
  transition: all ease-in-out 0.3s;
`
type MenuItemProps = {
  selected: boolean
  disabled: boolean
}

type ListProps = {
  folded: boolean
}

export const List = styled.div`
  transition: all ease-in-out 0.3s;
  position: relative;
  left: 0;
  ${({ folded }: ListProps) =>
    folded
      ? css`
          left: -200px;
          ${MenuItem} {
            opacity: 0;
            pointer-events: none;
          }
        `
      : ''}
`

export const MenuItem = styled.div`
  position: relative;
  padding: 11px 12px;
  min-width: max-content;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #353b60;
  border-radius: 4px;
  transition: opacity ease-in-out 0.3s;
  ${({ selected }: MenuItemProps) =>
    selected
      ? css`
          background: #f1f3ff;
          color: #2235aa;
          path,
          rect {
            fill: #2235aa;
          }
        `
      : ''}

  &:hover {
    cursor: pointer;
    background: #f1f3ff;
    color: #2235aa;
    path,
    rect {
      fill: #2235aa;
    }
  }
  ${({ disabled }: MenuItemProps) =>
    disabled
      ? css`
          opacity: 0.3;
          pointer-events: none;
        `
      : ''}
`
export const Marker = styled.div`
  position: absolute;
  right: -8px;
  width: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #4c5ecf;
  height: 100%;
`

export const HubInfo = styled.div`
  margin-bottom: 9px;
  padding-right: 11px;
  display: flex;
  justify-content: flex-end;
`

type IconButtonProps = {
  folded: boolean
}

export const IconButton = styled(IconButtonInit)`
  transition: all ease-in 0.3s;
  ${({ folded }: IconButtonProps) =>
    folded
      ? css`
          transform: rotateY(180deg);
        `
      : ''}
`
