import { CompaniesIcon } from '../icons/CompaniesIcon'

import {
  CompanyLogoFrame,
  DefaultCompanyImage,
} from './CompanyLogoPlaceholder.styled'

type CompanyLogoPlaceholderProps = {
  photo: string
  size?: 'big' | 'medium'
}

export const CompanyLogoPlaceholder = ({
  photo,
  size = 'big',
}: CompanyLogoPlaceholderProps) => (
  <CompanyLogoFrame size={size} photo={photo}>
    {photo ? null : (
      <DefaultCompanyImage size={size}>
        <CompaniesIcon />
      </DefaultCompanyImage>
    )}
  </CompanyLogoFrame>
)
