import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { Checkbox } from '@gmini/ui-kit'

import { useParams } from 'react-router'

import { useAppDispatch, useAppSelector } from '../../store/store'
import {
  getUserById,
  updateUserHubPermissions,
} from '../../store/users/actions'

import { User } from '../../store/users/types'

import { RoleChangesPopup } from '../RoleChangesPopup/RoleChangesPopup'
import { ChangesPopupContent } from '../RoleProfile/RoleProfile.styled'
import { envName } from '../../config'

import {
  FieldContainer,
  UserDetailsWrapper,
  ActionButton,
  ActionButtons,
  TextContainer,
  Title,
  Description,
  ScopesContainer,
  Section,
  Divider,
} from './UserAccessDrawerTab.styled'

type UserAccessDrawerTabProps = {
  user: User
  setUserState: Dispatch<SetStateAction<User | null>>
  isUnsavedChangesPopupOpen: boolean
  setUnsavedUserChanges: Dispatch<SetStateAction<boolean>>
  setIsUnsavedChangesPopupOpen: Dispatch<SetStateAction<boolean>>
}

export const UserAccessDrawerTab = ({
  user,
  setUserState,
  isUnsavedChangesPopupOpen,
  setUnsavedUserChanges,
  setIsUnsavedChangesPopupOpen,
}: UserAccessDrawerTabProps) => {
  const params = useParams<{
    hubId: string
    projectUrn: string
    companyId: string
  }>()
  const projectUrn = params.projectUrn || 'default'
  const { companyId } = params
  const { list: allHubPermissions, map: allHubPermissionsMap } = useAppSelector(
    state => state.hubPermissions,
  )
  const dispatch = useAppDispatch()

  const userPermissionsMap = Object.entries(
    user.hubPermissions?.[`${envName}`] || {},
  ).reduce(
    (acc: { [x: string]: boolean }, [res, scopes]) => ({
      ...acc,
      ...scopes.reduce(
        (accu: { [x: string]: boolean }, scope) => ({
          ...accu,
          [`${res}_${scope}`]: true,
        }),
        {},
      ),
    }),
    allHubPermissionsMap,
  )

  const defaultValues = {
    support: user.support,
    adminPanelUser: user.adminPanelUser || false,
    permissions: userPermissionsMap,
  }
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
  } = useForm({ mode: 'onChange', defaultValues })

  useEffect(() => {
    setUnsavedUserChanges(isDirty)
  }, [isDirty, setUnsavedUserChanges])

  const onSubmit = useCallback(
    async (data: typeof defaultValues) => {
      const { adminPanelUser, support, permissions } = data
      const formattedPermissions = allHubPermissions.map(res => ({
        ...res,
        scopes: res.scopes.filter(
          scope => permissions[`${res.resourceId}_${scope.scopeId}`],
        ),
      }))
      await dispatch(
        updateUserHubPermissions({
          userId: user.id,
          hubId: envName,
          name: user.name,
          hubPermissions: {
            adminPanelUser,
            support,
            permissions: formattedPermissions,
          },
        }),
      )
      const newUser = await dispatch(
        getUserById({ id: user.id, projectUrn, companyId }),
      ).unwrap()
      setUnsavedUserChanges(false)
      setUserState(newUser)
    },
    [
      user,
      dispatch,
      setUserState,
      setUnsavedUserChanges,
      allHubPermissions,
      projectUrn,
      companyId,
    ],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => reset(defaultValues), [user])

  if (!user) {
    return null
  }
  return (
    <UserDetailsWrapper>
      <div>
        <Title>Настройки</Title>
        <Section>
          <FieldContainer>
            <TextContainer>
              <Title>IS SUPPORT</Title>
              <Description>
                Лейбл, который выдается тем пользователям/сервисным учетным
                записям, которые не должны быть учтены в лицензиях пользователей
                (команда разработки G-tech, Bimsoft и тд). Администраторы, у
                которых не поставлен IS SUPPORT, не видят пользователей с
                лейблом в общем списке в разделе Пользователи, а также в списке
                пользователей в проектах/модулях/компаниях.
              </Description>
            </TextContainer>
            <Controller
              name='support'
              control={control}
              render={({ field }) => (
                <Checkbox
                  data-test-id='accessDrawerTabSupportCheckbox'
                  name={field.name}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldContainer>
          <FieldContainer>
            <TextContainer>
              <Title>IS ADMIN PANEL</Title>
              <Description>
                Активирует доступ разделам Проекты, Модули, Компании,
                Пользователи в режиме просмотр и редактирование.
              </Description>
            </TextContainer>
            <Controller
              name='adminPanelUser'
              control={control}
              render={({ field }) => (
                <Checkbox
                  data-test-id='accessDrawerTabAdminPanelUserCheckbox'
                  name={field.name}
                  checked={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldContainer>
        </Section>
        <Title>Ресурсы</Title>
        <Section>
          <Controller
            name='permissions'
            control={control}
            render={({ field }) => (
              <>
                {allHubPermissions.map(res => (
                  <div key={res.resourceId}>
                    <TextContainer>
                      <Title>{res.resourceId}</Title>
                      <Description>{res.name}</Description>
                    </TextContainer>
                    <Divider />
                    <ScopesContainer>
                      {res.scopes.map(scope => (
                        <FieldContainer
                          key={`${res.resourceId}_${scope.scopeId}`}
                        >
                          <TextContainer>
                            <Title>{scope.scopeId}</Title>
                            <Description>{scope.name}</Description>
                          </TextContainer>
                          <Checkbox
                            checked={
                              field.value[`${res.resourceId}_${scope.scopeId}`]
                            }
                            onChange={() =>
                              field.onChange({
                                ...field.value,
                                [`${res.resourceId}_${scope.scopeId}`]:
                                  !field.value[
                                    `${res.resourceId}_${scope.scopeId}`
                                  ],
                              })
                            }
                          />
                        </FieldContainer>
                      ))}
                    </ScopesContainer>
                  </div>
                ))}
              </>
            )}
          />
        </Section>
      </div>
      <ActionButtons>
        <ActionButton
          data-test-id='detailsCancelBtn'
          onClick={() => reset(defaultValues)}
          color='secondary'
        >
          Отменить
        </ActionButton>
        <ActionButton
          data-test-id='detailsConfirmBtn'
          color='primary'
          disabled={!isDirty}
          onClick={handleSubmit(onSubmit)}
        >
          Применить
        </ActionButton>
      </ActionButtons>
      <RoleChangesPopup
        title='Изменения не сохранены'
        submitButtonTitle='Сохранить изменения'
        open={isUnsavedChangesPopupOpen}
        width='600px'
        onClose={() => {
          setIsUnsavedChangesPopupOpen(false)
        }}
        onSubmit={handleSubmit(onSubmit)}
        onReset={() => reset(defaultValues)}
      >
        {() => (
          <ChangesPopupContent>
            У вас остались несохраненные изменения в профиле. Выберите требуемое
            действие.
          </ChangesPopupContent>
        )}
      </RoleChangesPopup>
    </UserDetailsWrapper>
  )
}
