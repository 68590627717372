import { Pointer, Tabs } from '@gmini/ui-kit'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { modulesNames } from '../../store/modules/types'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { getModuleUsers } from '../../store/users/actions'
import { GetUsersPaginatedListParams, User } from '../../store/users/types'
import { EditUserDrawer } from '../EditUserPage/EditUserDrawer'
import { UserList } from '../UserList/UserList'

import { getCompanies } from '../../store/companies/actions'

import { DashboardIcon } from '../icons/DashboardIcon'

import { ProjectOrModuleAttributeList } from '../ProjectOrModuleAttributeList/ProjectOrModuleAttributeList'
import { getModuleAttributes } from '../../store/modules/actions'

import { StyledTabTitle } from '../ProjectPage/ProjectPage.styled'

import {
  BackButton,
  ListHeading,
  Title,
  UserListWrapper,
} from './ModulePage.styled'

const size = 100

export const ModulePage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { moduleName, hubId, tabId } = useParams<{
    hubId: string
    moduleName: keyof typeof modulesNames
    tabId: string
  }>()
  const [userDetails, setUserDetails] = useState<User | null>(null)
  const [isUnsavedChangesPopupOpen, setIsUnsavedChangesPopupOpen] =
    useState(false)
  const [unsavedUserChanges, setUnsavedUserChanges] = useState(false)

  const {
    list: users,
    totalBySearch,
    pending,
  } = useAppSelector(state => state.moduleUsers)

  const { list: moduleAttributesList } = useAppSelector(
    state => state.currentModuleAttributes,
  )

  useEffect(() => {
    dispatch(getCompanies())
  }, [dispatch])

  const fetchModuleAttributesList = useCallback(() => {
    dispatch(getModuleAttributes({ moduleId: moduleName }))
  }, [moduleName, dispatch])

  const fetchUserList = useCallback(
    (params: GetUsersPaginatedListParams) =>
      dispatch(getModuleUsers({ moduleName, ...params })),
    [dispatch, moduleName],
  )

  useEffect(() => {
    const unblock = history.block(() => {
      if (unsavedUserChanges) {
        setIsUnsavedChangesPopupOpen(true)
        return false
      }
    })
    return unblock
  }, [history, unsavedUserChanges])

  return (
    <UserListWrapper>
      <ListHeading>
        <Title>
          <BackButton
            data-test-id='backToModulesBtn'
            onClick={() => history.push(`/hub/${hubId}/modules`)}
          >
            <Pointer />
          </BackButton>
          <DashboardIcon />
          <span data-test-id='moduleUsersHeading'>
            {modulesNames[moduleName]}
          </span>
        </Title>
      </ListHeading>
      <Tabs
        activeTabIndex={Number(tabId)}
        onChangeTab={tabId =>
          history.push(`/hub/${hubId}/modules/${moduleName}/${tabId}`)
        }
        headerStyles={{
          padding: '0 24px',
          boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.1)',
          clipPath: 'polygon(0 0, 100% 0, 100% 300%, 0 300%)',
          borderBottom: 'none',
          flexShrink: 0,
        }}
        contentStyles={{
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
        }}
        tabs={[
          {
            title: <StyledTabTitle>Пользователи</StyledTabTitle>,
            dataTestId: 'moduleUsersTab',
            content: (
              <>
                <EditUserDrawer
                  noRolesEdit
                  userState={userDetails}
                  setUserState={setUserDetails}
                  unsavedUserChanges={unsavedUserChanges}
                  setUnsavedUserChanges={setUnsavedUserChanges}
                  isUnsavedChangesPopupOpen={isUnsavedChangesPopupOpen}
                  setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
                />
                <UserList
                  localStoragePrefix='module'
                  noExtendedEditOrDelete
                  fetchUserList={fetchUserList}
                  users={users}
                  size={size}
                  total={totalBySearch}
                  pending={pending}
                  setUserDetails={setUserDetails}
                  unsavedUserChanges={unsavedUserChanges}
                  setIsUnsavedChangesPopupOpen={setIsUnsavedChangesPopupOpen}
                />
              </>
            ),
          },
          {
            title: <StyledTabTitle>Атрибуты</StyledTabTitle>,
            dataTestId: 'moduleAttributesTab',
            content: (
              <ProjectOrModuleAttributeList
                list={moduleAttributesList}
                fetchList={fetchModuleAttributesList}
              />
            ),
          },
        ]}
      />
    </UserListWrapper>
  )
}
