import React, { useState } from 'react'

import {
  AttributeTabListItem,
  ButtonsContainer,
  CancelStyled,
  CheckMarkStyled,
  IconButtonStyled,
  InputWrapper,
  SideWrapperStyled,
  StyledArrowExpandMore,
  StyledInput,
} from './AttributeInput.styled'

type AttributeInputProps = {
  groupId?: number
  isAttributeValue?: boolean
  inputRef: React.RefObject<HTMLInputElement>
  onClose: () => void
  onSubmit: (name: string) => void
  initValue?: string
  dataTestIdPrefix: string
}

export const AttributeInput = ({
  inputRef,
  onClose,
  onSubmit,
  groupId,
  isAttributeValue,
  initValue = '',
  dataTestIdPrefix,
  ...other
}: AttributeInputProps) => {
  const [value, setValue] = useState(initValue)

  const closeAndReset = () => {
    onClose()
    setValue(initValue)
  }

  const handleSubmit = () => {
    onSubmit(value.trim())
    closeAndReset()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && value.trim().length) {
      handleSubmit()
    }
    if (e.key === 'Escape') {
      closeAndReset()
    }
  }

  return (
    <AttributeTabListItem {...other}>
      <SideWrapperStyled>
        {!isAttributeValue && <StyledArrowExpandMore />}
        <InputWrapper>
          <StyledInput
            data-test-id={`${dataTestIdPrefix}_Input`}
            value={value}
            ref={inputRef}
            onChange={e => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder={
              groupId || isAttributeValue
                ? 'Наименование значения атрибута'
                : 'Наименование группировки'
            }
          />
        </InputWrapper>
      </SideWrapperStyled>
      <ButtonsContainer>
        <IconButtonStyled
          data-test-id={`${dataTestIdPrefix}_InputCancelBtn`}
          onClick={closeAndReset}
          type='circle'
          size='small'
        >
          <CancelStyled />
        </IconButtonStyled>
        <IconButtonStyled
          data-test-id={`${dataTestIdPrefix}_InputSubmitBtn`}
          disabled={!value.trim().length}
          onClick={handleSubmit}
          type='circle'
          size='small'
        >
          <CheckMarkStyled />
        </IconButtonStyled>
      </ButtonsContainer>
    </AttributeTabListItem>
  )
}
