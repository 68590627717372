import { createSlice } from '@reduxjs/toolkit'

import {
  attachAttributeValueToProject,
  detachAttributeValueToProject,
  getAttribute,
  getAttributes,
  getProjectModuleAttributes,
} from './actions'
import {
  Attribute,
  AttributeGroupWithChildren,
  AttributeInstances,
  AttributeValue,
  AttributeWithChildren,
  ProjectModuleAttributeValue,
} from './types'

const initialAttributesState: {
  list: Attribute[]
  pending: boolean
  total: number
} = {
  list: [],
  total: 0,
  pending: false,
}

const getInitProjectModuleValuesMap = (
  payload: ProjectModuleAttributeValue[],
) =>
  payload.reduce(
    (acc, p) => ({
      ...acc,
      [`${p.urn}${projectModuleSplitter}`]: false,
      ...p.modules.reduce(
        (acc, m) => ({
          ...acc,
          [`${p.urn}${projectModuleSplitter}${m.id}`]: false,
          ...m.attributeValues.reduce(
            (acc, v) => ({
              ...acc,
              [`${p.urn}${projectModuleSplitter}${m.id}${projectModuleValueSplitter}${v.id}`]:
                false,
            }),
            {},
          ),
        }),
        {},
      ),
    }),
    {},
  )

export const projectModuleSplitter = '!@#$'
export const projectModuleValueSplitter = '!@#$!@#$'

const attributes = createSlice({
  name: 'attributes',
  initialState: initialAttributesState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAttributes.fulfilled, (_, { payload }) => ({
        list: payload,
        total: payload.length || 0,
        pending: false,
      }))
      .addCase(getAttributes.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getAttributes.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
  },
})

export const attributesReducer = attributes.reducer

const initialCurrentAttributeState: AttributeWithChildren & {
  childrenSortIndexesMap: { [x: string]: number }
  childrenFlatMap: { [x: number]: AttributeValue }
  groupMap: { [x: number]: AttributeGroupWithChildren }
  pending: boolean
  projectModuleValues: ProjectModuleAttributeValue[]
  projectModuleValuesMap: { [x: string]: boolean }
} = {
  id: 0,
  name: '',
  description: '',
  deleted: true,
  children: [],
  childrenFlatMap: {},
  childrenSortIndexesMap: {},
  groupMap: {},
  pending: true,
  projectModuleValues: [],
  projectModuleValuesMap: {},
}

const currentAttribute = createSlice({
  name: 'currentAttribute',
  initialState: initialCurrentAttributeState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAttribute.fulfilled, (state, { payload }) => ({
        ...state,
        ...payload,
        childrenFlatMap: payload.children.reduce(
          (acc, c) => ({
            ...acc,
            ...(c.type === AttributeInstances.VALUE
              ? { [c.id]: c }
              : c.children.reduce((acc, c) => ({ ...acc, [c.id]: c }), {})),
          }),
          {},
        ),
        childrenSortIndexesMap: payload.children
          .reduce(
            (acc: (AttributeGroupWithChildren | AttributeValue)[], c) => [
              ...acc,
              ...(c.type === AttributeInstances.GROUP ? c.children : [c]),
            ],
            [],
          )
          .reduce((acc, c, idx) => ({ ...acc, [c.id]: idx }), {}),
        groupMap: payload.children
          .filter(c => c.type === AttributeInstances.GROUP)
          .reduce(
            (acc, g) => ({
              ...acc,
              [g.id]: g,
            }),
            {},
          ),
        pending: false,
      }))
      .addCase(getAttribute.rejected, (state, _) => ({
        ...state,
        pending: false,
      }))
      .addCase(getAttribute.pending, (state, _) => ({
        ...state,
        pending: true,
      }))
      .addCase(getProjectModuleAttributes.fulfilled, (state, { payload }) => ({
        ...state,
        projectModuleValues: payload,
        projectModuleValuesMap: getInitProjectModuleValuesMap(payload),
      }))
      .addCase(
        attachAttributeValueToProject.fulfilled,
        (state, { payload }) => ({
          ...state,
          projectModuleValues: payload,
          projectModuleValuesMap: getInitProjectModuleValuesMap(payload),
        }),
      )
      .addCase(
        detachAttributeValueToProject.fulfilled,
        (state, { payload }) => ({
          ...state,
          projectModuleValues: payload,
          projectModuleValuesMap: getInitProjectModuleValuesMap(payload),
        }),
      )
  },
})

export const currentAttributeReducer = currentAttribute.reducer
